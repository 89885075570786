export enum DBTables {
  Addresses = "addresses",
  AdministrativeApproval = "administrative_approvals",
  ApiTokens = "api_tokens",
  BidsOffers = "bids_offers",
  BidsOfferCWEOrderbooks = "bids_offer_cwe_orderbooks",
  Billings = "billings",
  BillingGroups = "billing_groups",
  Charges = "charges",
  ClassCategories = "class_categories",
  Contracts = "contracts",
  Conversions = "conversions",
  ConversionAccountTransfers = "conversion_account_transfers",
  ConversionPools = "conversion_pools",
  CWEs = "cwes",
  CWEOrderbooks = "cwe_orderbooks",
  CWERules = "cwe_rules",
  CWERuleTypes = "cwe_rule_types",
  Declarations = "declarations",
  EventGroups = "event_groups",
  EventTypes = "event_types",
  EventTransactions = "event_transactions",
  Evidences = "evidences",
  ExchangeApprovals = "exchange_approvals",
  ExchangeContracts = "exchange_contracts",
  ExchangeRates = "exchange_rates",
  ExchangeTypes = "exchange_types",
  Extractions = "extractions",
  ExtractionHistories = "extraction_histories",
  ExtractionPoints = "extraction_points",
  ExtractionPointsRights = "extraction_points_rights",
  ExtractionRights = "extraction_rights",
  ExtractionRightTypes = "extraction_right_types",
  ForwardDraws = "forward_draws",
  Level0WRS = "level0_wrs",
  Liabilities = "liabilities",
  Level0WRSExchanges = "level0_wrs_exchanges",
  Messages = "messages",
  MessageRecipients = "message_recipients",
  MessageTemplates = "message_templates",
  Meters = "meters",
  MeterUploads = "meter_uploads",
  Notifications = "notifications",
  NotificationTypes = "notification_types",
  Payments = "payments",
  NotificationRelatedSubscribers = "notification_related_subscribers",
  Permissions = "permissions",
  PermissionGroups = "permission_groups",
  PermissionRoles = "permission_role",
  PermissionUsers = "permission_user",
  PermissionWorkflow = "permission_workflow",
  Reports = "reports",
  ReportComments = "report_comments",
  ReportHistories = "report_histories",
  RightFractions = "right_fractions",
  RightFractionHistories = "right_fraction_histories",
  Roles = "roles",
  Settings = "settings",
  Subscribers = "subscribers",
  SubscriberNotes = "subscriber_notes",
  Subscriptions = "subscriptions",
  SubscriptionPayments = "subscription_payments",
  SubscriptionTypes = "subscription_types",
  TransactionFees = "transaction_fees",
  Terminologies = "terminologies",
  Tokens = "tokens",
  Users = "users",
  UserWorkflow = "user_workflow",
  Wallets = "wallets",
  WalletAccounts = "wallet_accounts",
  WaterCharges = "water_charges",
  WaterClasses = "water_classes",
  AccountingPeriods = "accounting_periods",
  AccountingPeriodChecks = "accounting_period_checks",
  WaterSavings = "water_savings",
  WRSHierarchies = "wrs_hierarchies",
  Workflows = "workflows",
  WorkflowInstances = "workflow_instances",
  WorkflowGroups = "workflow_groups",
}
