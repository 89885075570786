import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import Heading from "@components/layout/Heading";
import ConfirmationDetail from "@components/shared/ConfirmationDetail";
import ConfirmModal from "@components/shared/ConfirmModal";
import BoundarySelected from "@components/shared/BoundarySelected";
import { toastError, toastSuccess } from "@utils/toast";
import { useCreateLevel0WRS } from "@hooks/mutation/useCreateLevel0WRS";
import { useLevel0WRSContext } from "@context/Level0WRSContext";
import { convertMLToLiter } from "@utils/convertUnits";
import { useEvidenceContext } from "@context/shared/EvidenceContext";
import { useUpdateLevel0wrs } from "@hooks/mutation/useUpdateLevel0WRS";
import { useUpdateWaterClass } from "@hooks/mutation/useUpdateWaterClass";
import { useWaterClassContext } from "@context/WaterClassContext";
import MapView from "@components/shared/MapView";

const Level0WRSLedger = () => {
  const { t } = useTranslation();
  const {
    stepHelpers,
    details,
    locationCoordinates,
    setWorkflowCompleted,
    setWorkflowInstance,
    handleChangeDetails,
    setNetworkErrors,
    currentStep,
    getDetails,
    getAccountingPeriodAndVolume,
    getDistributionLoss,
    getTradingLinks,
    getAdministrationInfo,
    navigateForCancel,
    updatingLevel0WRS,
  } = useLevel0WRSContext();
  const {
    getEvidencesInfo,
    archiveUnselectedEvidences,
    uploadEvidences,
    isSubmitting,
    isArchivingUnselectedEvidence,
  } = useEvidenceContext();

  const { doAdjustWaterClassVolumeDetails, adjustedWaterClassVolumeDetails } =
    useWaterClassContext();

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const { mutateAsync: createLevel0WRSMutation, isLoading } =
    useCreateLevel0WRS();

  const {
    mutateAsync: updateWaterClassMutation,
    isLoading: isUpdatingWaterClasses,
  } = useUpdateWaterClass();

  const {
    mutateAsync: updateLevel0wrsMutation,
    isLoading: isLoadingLevel0wrsUpdate,
  } = useUpdateLevel0wrs();

  useEffect(() => {
    setNetworkErrors([]);
  }, [currentStep, setNetworkErrors]);

  const confirmData = [
    getDetails(),
    {
      title: t("level0wrs.create.boundary"),
      body: [
        {
          key: "",
          value: (
            <>
              <BoundarySelected selected={details.geography !== ""} />
              {details.geography !== "" && (
                <>
                  {locationCoordinates.lat !== "" ? (
                    <div className="h-96 max-w-md mt-2">
                      <MapView
                        markers={[
                          {
                            lat: Number(locationCoordinates.lat),
                            lng: Number(locationCoordinates.lng),
                          },
                        ]}
                        zoomLevel={14}
                      />
                    </div>
                  ) : (
                    <div className="space-y-1.5">
                      <strong>{t("location.coordinate_file")}:</strong>{" "}
                      {details.geography}
                    </div>
                  )}
                </>
              )}
            </>
          ),
        },
      ],
    },
    getAccountingPeriodAndVolume(),
    getDistributionLoss(),
    getTradingLinks(),
    getAdministrationInfo(),
    getEvidencesInfo(),
  ];

  const handleOnConfirm = async () => {
    try {
      const { level1WRS, ...data } = details;
      const level0wrsData = {
        parentId: level1WRS.id,
        name: data.name,
        identifier: data.identifier,
        geography: data.geography,
        periodStart: data.periodStart!,
        periodEnd: data.periodEnd!,
        yield: data.yield ? convertMLToLiter(+data.yield) : 0,
        internalTradeAllowed: data.trade.isInternalTradeAllowed,
        internalLimit: data.trade.internalTradeLimit
          ? convertMLToLiter(+data.trade.internalTradeLimit)
          : 0,
        inboundTradeAllowed: data.trade.isInboundTradeAllowed,
        inboundLimit: data.trade.inboundTradeLimit
          ? convertMLToLiter(+data.trade.inboundTradeLimit)
          : 0,
        outboundTradeAllowed: data.trade.isOutboundTradeAllowed,
        outboundLimit: data.trade.outboundTradeLimit
          ? convertMLToLiter(+data.trade.outboundTradeLimit)
          : 0,
        sdApproval: data.administration.stockAndDomestic,
        fdApproval: data.administration.forwardDraw,
        stApproval: data.administration.seasonalTransfer,
        ptApproval: data.administration.permanentTrade,
        whApproval: data.administration.waterHarvesting,
        saApproval: data.administration.specialAnnouncement,
        inboundZoneExchanges: data.trade.inboundZoneExchanges,
        outboundZoneExchanges: data.trade.outboundZoneExchanges,
        distributionLoss: data.distributionLoss?.extractionRightName
          ? data.distributionLoss
          : undefined,
      };

      let res = { name: "", createdAt: "", id: "" };
      if (updatingLevel0WRS) {
        const { yield: yieldNextAccountingPeriod, ...rest } = level0wrsData;

        const [data, workflowInstance] = await updateLevel0wrsMutation({
          level0ResourceId: details.id,
          yieldNextAccountingPeriod:
            adjustedWaterClassVolumeDetails.length > 0
              ? yieldNextAccountingPeriod
              : yieldNextAccountingPeriod === +updatingLevel0WRS.yield
              ? undefined
              : yieldNextAccountingPeriod,
          ...rest,
        });
        res = data;
        setWorkflowInstance(workflowInstance);
      } else {
        const [data, workflowInstance] = await createLevel0WRSMutation(
          level0wrsData
        );
        res = data;
        setWorkflowInstance(workflowInstance);
      }

      handleChangeDetails("name", res.name);
      handleChangeDetails("createdAt", res.createdAt);
      handleChangeDetails("id", res.id);

      if (doAdjustWaterClassVolumeDetails) {
        const volumeDetails = adjustedWaterClassVolumeDetails.filter(
          (i) => i.adjustedVolume !== ""
        );
        for (const vd of volumeDetails) {
          await updateWaterClassMutation({
            waterClassId: vd.classId,
            volumeNextAccountingPeriod: convertMLToLiter(+vd.adjustedVolume),
          });
        }
        toastSuccess(
          t("level0wrs.update.toast.success_class", { name: res.name })
        );
      }

      await archiveUnselectedEvidences({
        referenceId: res.id,
        referenceTable: "level0_wrs",
        evidenceIds: details.activatedEvidenceIds,
      });

      await uploadEvidences({
        description: t("level0wrs.create.step_6.description", {
          level0wrsName: details.name,
        }),
        referenceId: details.id,
        referenceTable: "level0_wrs",
      });

      setWorkflowCompleted(true);

      if (updatingLevel0WRS) {
        toastSuccess(t("level0wrs.update.toast.success", { name: res.name }));
      } else {
        toastSuccess(t("level0wrs.create.toast.success", { name: res.name }));
      }
    } catch (error: any) {
      toastError(t("level0wrs.create.toast.failure"));
      const errors =
        error?.response?.data?.errors?.reduce(
          (accumulator: any, error: any) => {
            if (error.field) {
              accumulator.push(`${error.field}: ${error.message}`);
            }
            return accumulator;
          },
          []
        ) ?? [];
      setNetworkErrors(errors);
    }
    setShowConfirmModal(false);
  };

  return (
    <>
      <div className="flex flex-col gap-6 p-6 grow justify-between">
        <Heading light>{t("level0wrs.create.completed")}</Heading>

        <ConfirmationDetail data={confirmData} onEdit={stepHelpers.setStep} />
      </div>

      <footer className="flex gap-4 p-6 border-t border-gray-200">
        <button
          type="button"
          className="btn-outline-primary"
          onClick={stepHelpers.goToPrevStep}
        >
          {t("common.prev_step")}
        </button>

        <button
          type="button"
          className="btn-primary"
          onClick={() => setShowConfirmModal(true)}
        >
          {t("common.ledger")}
        </button>

        <button
          type="button"
          className="btn-outline-primary"
          onClick={navigateForCancel}
        >
          {t("common.cancel")}
        </button>
      </footer>

      <ConfirmModal
        open={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
        onConfirm={handleOnConfirm}
        isSubmitting={
          isLoading ||
          isLoadingLevel0wrsUpdate ||
          isSubmitting ||
          isArchivingUnselectedEvidence ||
          isUpdatingWaterClasses
        }
        confirmText={t("common.ledger") as string}
      >
        {t("level1wrs.create.modal.confirm_title")}
      </ConfirmModal>
    </>
  );
};

export default Level0WRSLedger;
