import WorkflowSteps from "@components/form/WorkflowSteps";
import WorkflowCreateAdminTransferCompleted from "@components/form/workflows/create_admin_transfer/WorkflowCreateAdminTransferCompleted";
import WorkflowCreateAdminTransferStep1 from "@components/form/workflows/create_admin_transfer/WorkflowCreateAdminTransferStep1";
import WorkflowCreateAdminTransferStep2 from "@components/form/workflows/create_admin_transfer/WorkflowCreateAdminTransferStep2";
import WorkflowCreateAdminTransferStep3 from "@components/form/workflows/create_admin_transfer/WorkflowCreateAdminTransferStep3";
import WorkflowCreateAdminTransferStep4 from "@components/form/workflows/create_admin_transfer/WorkflowCreateAdminTransferStep4";
import {
  AdminTransferProvider,
  useAdminTransferContext,
} from "@context/AdminTransferContext";
import { useAppContext } from "@context/AppContext";
import { EvidenceProvider } from "@context/shared/EvidenceContext";
import { useMessageTemplates } from "@hooks/query/useMessageTemplates";
import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const WorkflowCreateAdminApproval: FunctionComponent = () => {
  return (
    <AdminTransferProvider>
      <EvidenceProvider>
        <MainComponent />
      </EvidenceProvider>
    </AdminTransferProvider>
  );
};

const MainComponent: FunctionComponent = () => {
  const { t } = useTranslation();
  const [currentStep, setCurrentStep] = useState(0);
  const {
    loggedInInfo: { userDetails },
  } = useAppContext();
  const { details, setDetails, setMessage } = useAdminTransferContext();

  useMessageTemplates({
    enabled: !!userDetails,
    onSuccess: (data: any[]) => {
      const messageTemplate = data.find(
        (t) => t.identifier === "AdministrativeTransferRequest"
      );

      if (messageTemplate) {
        setMessage({
          messageTemplate,
          subject: t(messageTemplate.subjectTextTemplate),
          body: t(messageTemplate.bodyTextTemplate, {
            recipientName: t("common.administrators"),
            senderName: userDetails?.name,
            subscriberAccountNumber: userDetails?.subscriber?.accountNumber,
          }),
        });
      }
    },
  });

  useEffect(() => {
    if (userDetails)
      setDetails({
        ...details,
        subscriber: userDetails.subscriber,
        level1wrs: userDetails.subscriber?.level1WRS,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails]);

  return (
    <WorkflowSteps
      selectedStep={currentStep}
      onStepButtonClick={(step) => setCurrentStep(step)}
      disableNavigation={currentStep === 4}
      steps={[
        {
          panel: (
            <WorkflowCreateAdminTransferStep1
              onSubmit={() => setCurrentStep(1)}
            />
          ),
        },
        {
          panel: (
            <WorkflowCreateAdminTransferStep2
              onCancel={() => setCurrentStep(0)}
              onSubmit={() => setCurrentStep(2)}
            />
          ),
        },
        {
          panel: (
            <WorkflowCreateAdminTransferStep3
              onCancel={() => setCurrentStep(1)}
              onSubmit={() => setCurrentStep(3)}
            />
          ),
        },
        {
          panel: (
            <WorkflowCreateAdminTransferStep4
              onCancel={() => setCurrentStep(2)}
              onSubmit={() => setCurrentStep(4)}
            />
          ),
        },
        {
          panel: <WorkflowCreateAdminTransferCompleted />,
        },
      ]}
    />
  );
};

export default WorkflowCreateAdminApproval;
