import React from "react";
import Select from "react-select";
import { Link, useNavigate } from "react-router-dom";
import { isFunction } from "lodash";
import { useTranslation } from "react-i18next";
import Label from "@components/form/Label";
import SearchInput from "@components/form/SearchInput";
import Heading from "@components/layout/Heading";
import Table from "@components/layout/Table";
import Tag from "@components/shared/Tag";
import { useAllSubscribers } from "@hooks/query/useAllSubscribers";
import { useAppContext } from "@context/AppContext";
import { formatDate } from "@utils/formatDate";

type Subscriber = Record<string, any>;

type Props = {
  title?: string | null;
  onSelect?: (item: Subscriber) => void;
  selected?: Subscriber;
  excludedId?: string;
  includeIds?: string[];
  level1Resource?: Record<string, any>;
  showActions?: boolean;
  showView?: boolean;
  status?: "active" | "inactive";
};

const SubscriberTable: React.FunctionComponent<Props> = ({
  title,
  onSelect,
  selected = {},
  excludedId = "",
  includeIds = [],
  level1Resource,
  showActions = false,
  showView = false,
  status,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { checkPermissions } = useAppContext();
  const hasOnSelect = isFunction(onSelect);

  const { data: subscribers = [], isLoading } = useAllSubscribers({
    params: {
      level1ResourceId: level1Resource?.id,
      ...(status && { isActive: status === "active" }),
    },
    options: {
      select: (data: any) => {
        return data.map((subscriber: any) => {
          return {
            id: subscriber.id,
            accountNumber: subscriber.accountNumber,
            customerName: subscriber.name,
            status: subscriber.isActive ? "active" : "inactive",
            createdAt: subscriber.createdAt
              ? formatDate(new Date(subscriber.createdAt))
              : "",
            isActive: (
              <Tag status={subscriber.isActive ? "success" : "warning"}>
                {subscriber.isActive
                  ? t("common.active")
                  : t("common.inactive")}
              </Tag>
            ),
            ...(hasOnSelect && {
              radio: (
                <input
                  type="radio"
                  name="customer_id"
                  checked={selected?.id === subscriber.id}
                  onChange={() => {
                    onSelect(subscriber);
                  }}
                />
              ),
            }),
            ...(showActions && {
              actions: (
                <Select
                  placeholder={t("common.actions")}
                  options={[
                    ...(showView
                      ? [
                          {
                            label: t("common.view"),
                            value: `/polestar/subscribers/${subscriber?.id}`,
                          },
                        ]
                      : []),
                    ...(checkPermissions(["UpdateSubscribers"])
                      ? [
                          {
                            label: t("subscriber.edit.title"),
                            value: `/polestar/level1wrs/${subscriber.level1ResourceId}/subscribers/${subscriber.id}/edit?subscriberId=${subscriber.id}`,
                          },
                        ]
                      : []),
                  ]}
                  onChange={(e) => {
                    if (e?.value) {
                      navigate(e.value);
                    }
                  }}
                  menuPortalTarget={document.body}
                  closeMenuOnScroll={() => true}
                  isSearchable={false}
                />
              ),
            }),
          };
        });
      },
    },
  });

  const [filter, setFilter] = React.useState({
    customerName: "",
    accountNumber: "",
    status: "",
  });

  const handleFilterChange = (key: string, value: string) => {
    setFilter((prevState) => ({ ...prevState, [key]: value }));
  };

  const tableFields = [
    ...(hasOnSelect
      ? [
          {
            title: "",
            name: "radio",
          },
        ]
      : []),
    {
      title: t("subscriber.account_number"),
      name: "accountNumber",
    },
    {
      title: t("subscriber.name"),
      name: "customerName",
    },
    {
      title: t("subscriber.created_at"),
      name: "createdAt",
    },
    {
      title: t("common.status"),
      name: "isActive",
    },
    ...(showActions
      ? [
          {
            title: t("common.actions"),
            name: "actions",
          },
        ]
      : []),

    ...(showView
      ? [
          {
            title: "",
            name: "view",
          },
        ]
      : []),
  ];

  const tableData = subscribers.filter((item: any) => {
    const { customerName, accountNumber, status } = filter;

    if (excludedId.length && item?.id?.toString() === excludedId) {
      return false;
    }

    if (includeIds.length) {
      return includeIds.includes(item?.id);
    }

    if (status.length && item?.status !== status) {
      return false;
    }

    const matchesCustomerName =
      customerName.length === 0 ||
      item?.customerName?.toLowerCase()?.includes(customerName.toLowerCase());
    const matchesAccountNumber =
      accountNumber.length === 0 ||
      item?.accountNumber?.toString()?.includes(accountNumber);

    return matchesCustomerName || matchesAccountNumber;
  });

  const statusOptions = [
    { label: t("common.active"), value: "active" },
    { label: t("common.inactive"), value: "inactive" },
  ];

  return (
    <>
      <header className="relative z-20 mb-4">
        {title ? (
          <Heading light className="mb-4">
            {title}
          </Heading>
        ) : null}

        <div className="flex flex-col items-start 2xl:flex-row 2xl:items-end gap-4">
          <form className="flex gap-4">
            <div>
              <Label htmlFor="accountNumber">
                {t("subscriber.filter.account_name_or_id")}
              </Label>
              <SearchInput
                onChange={(e) => {
                  handleFilterChange("accountNumber", e.target.value);
                  handleFilterChange("customerName", e.target.value);
                }}
                name="accountNumber"
              />
            </div>

            {status ? null : (
              <div>
                <Label>{t("subscriber.filter.status")}</Label>
                <Select
                  options={statusOptions}
                  value={statusOptions.find(
                    ({ value }) => value === filter.status
                  )}
                  onChange={(e) => {
                    handleFilterChange("status", e?.value || "");
                  }}
                  isClearable
                />
              </div>
            )}
          </form>

          {checkPermissions(["CreateSubscribers"]) && level1Resource ? (
            <Link
              className="2xl:ml-auto btn-secondary text-sm rounded"
              to={`/polestar/level1wrs/${level1Resource.id}/subscribers/create?level1ResourceId=${level1Resource.id}`}
            >
              {t("user.create.new_customer")}
            </Link>
          ) : null}
        </div>
      </header>

      <Table
        fields={tableFields}
        data={tableData}
        tableHeaderClassName="relative z-10"
        stickyHeader
        loading={isLoading}
      />
    </>
  );
};

export default SubscriberTable;
