import React from "react";

const ArrowUp = () => {
  return (
    <svg
      width="12"
      height="14"
      viewBox="0 0 9 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.92136 5.39191L5.03191 0.909488C4.99675 0.868928 4.95328 0.836399 4.90445 0.814105C4.85563 0.791811 4.80258 0.780273 4.7489 0.780273C4.69522 0.780273 4.64217 0.791811 4.59334 0.814105C4.54451 0.836399 4.50105 0.868928 4.46589 0.909488L0.57761 5.39191C0.56586 5.40542 0.558242 5.42202 0.555662 5.43974C0.553082 5.45746 0.55565 5.47555 0.563059 5.49185C0.570468 5.50815 0.582407 5.52197 0.597453 5.53168C0.612498 5.54139 0.630017 5.54657 0.647922 5.5466H1.59714C1.65105 5.5466 1.70261 5.52316 1.73894 5.48214L4.30417 2.5255V9.12433C4.30417 9.17589 4.34636 9.21808 4.39792 9.21808H5.10105C5.15261 9.21808 5.1948 9.17589 5.1948 9.12433V2.5255L7.76003 5.48214C7.79519 5.52316 7.84675 5.5466 7.90183 5.5466H8.85105C8.93073 5.5466 8.97409 5.45285 8.92136 5.39191Z"
        fill="#389E0D"
      />
    </svg>
  );
};

export default ArrowUp;
