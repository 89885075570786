import React from "react";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import HandleGoBackOrClose from "@components/shared/HandleGoBackOrClose";
import Heading from "@components/layout/Heading";
import StepButton from "@components/shared/StepButton";
import StepInfo from "@components/shared/StepInfo";
import InfoPanel from "@components/form/InfoPanel";
import UploadEvidencesWorkflowForm from "@components/form/UploadEvidenceWorkflowForm";
import WorkflowPanels from "@components/form/WorkflowPanels";
import { StepProvider, useStepContext } from "@context/shared/StepContext";
import {
  MeterCreateOrEditProvider,
  useMeterCreateOrEditContext,
} from "@context/MeterCreateOrEditContext";
import {
  EvidenceProvider,
  useEvidenceContext,
} from "@context/shared/EvidenceContext";
import {
  MeterDeclarationProvider,
  useMeterDeclarationContext,
} from "@context/MeterDeclarationContext";
import { useCreateMeter } from "@hooks/mutation/useCreateMeter";

import MeterForm from "./MeterForm";
import MeterDeclarationForm from "./declaration/MeterDeclarationForm";
import ConfirmMeter from "./ConfirmMeter";
import ConfirmMeterDeclaration from "./declaration/ConfirmMeterDeclaration";
import { convertMLToLiter } from "@utils/convertUnits";
import {
  LinkMeterProvider,
  useLinkMeterContext,
} from "@context/LinkMeterContext";
import SelectSingleExtractionPointTable from "@components/table/SelectSingleExtractionPointTable";
import Heading1 from "@components/layout/Heading";
import ConfirmLinkMeter from "../extraction_point/link_meter/ConfirmLinkMeter";
import { useLinkMeterToExtractionPoint } from "@hooks/mutation/useLinkMeterToExtractionPoint";
import { useCreateMeterOnlyDeclaration } from "@hooks/mutation/useCreateMeterOnlyDeclaration";

type CreateMeterWorkflowProps = {
  level0Resource?: any;
  subscriber?: any;
};

const CreateMeterWorkflow: React.FunctionComponent<CreateMeterWorkflowProps> = (
  props
) => {
  return (
    <StepProvider maxStep={9}>
      <LinkMeterProvider>
        <MeterDeclarationProvider>
          <MeterCreateOrEditProvider>
            <EvidenceProvider>
              <Consumer {...props} />
            </EvidenceProvider>
          </MeterCreateOrEditProvider>
        </MeterDeclarationProvider>
      </LinkMeterProvider>
    </StepProvider>
  );
};

const Consumer: React.FunctionComponent<CreateMeterWorkflowProps> = ({
  level0Resource = {},
  subscriber = {},
}) => {
  const { t } = useTranslation();
  const handleGoBackOrClose = HandleGoBackOrClose();
  const [searchParams] = useSearchParams();
  const level1ResourceId = searchParams.get("level1ResourceId") ?? "";
  const { currentStep, stepHelpers } = useStepContext();
  const {
    getEvidencesInfo,
    uploadEvidences,
    reset: resetEvidences,
  } = useEvidenceContext();
  const {
    meterDetails,
    setMeterDetails,
    getMeterInfo,
    setIsComplete: setIsMeterComplete,
    isComplete: isMeterComplete,
    workflowInstance: meterWorkflowInstance,
    setWorkflowInstance: setMeterWorkflowInstance,
  } = useMeterCreateOrEditContext();
  const {
    declaration,
    setDeclaration,
    getDeclarationInfo,
    isComplete: isDeclarationComplete,
    setIsComplete: setIsDeclarationComplete,
    workflowInstance: declarationWorkflowInstance,
    setWorkflowInstance: setDeclarationWorkflowInstance,
  } = useMeterDeclarationContext();
  const {
    isComplete: isLinkMeterCompleted,
    setIsComplete: setIsLinkMeterComplete,
    setDetails: setLinkMeterDetails,
    details: linkMeterDetails,
    getExtractionPointDetails,
    workflowInstance: linkMeterWorkflowInstance,
    setWorkflowInstance: setLinkMeterWorkflowInstance,
  } = useLinkMeterContext();

  const [meterResponse, setMeterResponse] = React.useState<any>();

  const { mutateAsync: createMeterMutation } = useCreateMeter();
  const { mutateAsync: createMeterOnlyDeclarationMutation } =
    useCreateMeterOnlyDeclaration();
  const { mutateAsync: linkMeterMutation } = useLinkMeterToExtractionPoint();
  const declarationInfo = getDeclarationInfo({
    keysToIgnore: [
      t("declaration.read_at"),
      t("declaration.type_of_meter_reading"),
    ],
  });

  const isReplaceMeterWarning =
    linkMeterDetails?.extractionPoint?.id &&
    linkMeterDetails?.extractionPoint?.oldMeter?.id;

  const handleCancel = handleGoBackOrClose;

  const handleConfirmCreateMeter = async () => {
    const clickOver =
      meterDetails?.clickOver && +meterDetails.clickOver > 0
        ? convertMLToLiter(+meterDetails.clickOver)
        : undefined;
    const threshold =
      meterDetails?.threshold && +meterDetails.threshold > 0
        ? convertMLToLiter(+meterDetails.threshold)
        : undefined;

    const [res, workflowInstance] = await createMeterMutation({
      ...meterDetails,
      subscriberId: "",
      extractionPointId: "",
      serialNo: meterDetails.serialNo,
      unit: meterDetails.unit,
      verifiedAt: meterDetails.verifiedAt
        ? new Date(meterDetails.verifiedAt)
        : undefined,
      type: meterDetails.type,
      clickOver,
      threshold,
    });

    await uploadEvidences({
      description: t("meter.adding_meter.evidence_data.description", {
        serialNo: res.serialNo,
      }),
      referenceId: res.id,
      referenceTable: "meters",
    });

    setMeterResponse(res);
    setIsMeterComplete(true);
    setMeterWorkflowInstance(workflowInstance);
    stepHelpers.goToNextStep();
    resetEvidences();
  };

  const handleConfirmCreateInitialDeclaration = async () => {
    const [res, workflowInstance] = await createMeterOnlyDeclarationMutation({
      ...declaration,
      reading: declaration?.reading
        ? convertMLToLiter(+declaration?.reading)
        : 0,
    });

    await uploadEvidences({
      description: t("declaration.evidence_data.description", {
        serialNo: declaration?.serialNo,
        readAt: declaration?.readAt,
      }),
      referenceId: res.id,
      referenceTable: "declarations",
    });

    setDeclarationWorkflowInstance(workflowInstance);
    setIsDeclarationComplete(true);
    stepHelpers.goToNextStep();
    resetEvidences();
  };

  const handleConfirmLinkMeter = async () => {
    const [res, workflowInstance] = await linkMeterMutation({
      subscriberId: linkMeterDetails.subscriber.id,
      extractionPointId: linkMeterDetails.extractionPoint.id,
      meterId: linkMeterDetails.meter.id,
    });

    await uploadEvidences({
      description: t("extraction_point.link_meter.evidences.description", {
        extractionPointName: linkMeterDetails.extractionPoint.name,
        serialNo: linkMeterDetails.meter.serialNo,
      }),
      referenceId: res.id,
      referenceTable: "meters",
    });

    setLinkMeterWorkflowInstance(workflowInstance);
    setIsLinkMeterComplete(true);
  };

  const steps = [
    {
      label: (
        <StepButton
          index={0}
          currentStep={currentStep}
          isOnChain={isMeterComplete}
          info={<StepInfo data={getMeterInfo().body} />}
        >
          {t("meter.adding_meter.enter_details")}
        </StepButton>
      ),
      panel: (
        <div className="p-6 space-y-6">
          <Heading light>{t("meter.adding_meter.enter_details")}</Heading>
          <MeterForm
            defaultValues={{
              ...meterDetails,
            }}
            onSubmit={(data) => {
              setMeterDetails(data);
              stepHelpers.goToNextStep();
            }}
            onCancel={handleCancel}
            submitText={t("common.next_step") as string}
            level0ResourceId={level0Resource?.id}
            subscriberWalletId={subscriber?.walletId}
          />
        </div>
      ),
      infoPanel: (
        <InfoPanel>
          <div className="space-y-6">
            <p>{t("meter.info.details")}</p>
            <p>{t("meter.info.units")}</p>
            <p>{t("meter.info.clickover")}</p>
            <p>{t("meter.info.verified_date")}</p>
            <p>{t("meter.info.unlinked")}</p>
          </div>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={1}
          currentStep={currentStep}
          isOnChain={isMeterComplete}
          info={
            isMeterComplete ? null : <StepInfo data={getEvidencesInfo().body} />
          }
        >
          {t("common.enter_evidence")}
        </StepButton>
      ),
      panel: (
        <UploadEvidencesWorkflowForm
          onNext={stepHelpers.goToNextStep}
          onPrevious={stepHelpers.goToPrevStep}
          onCancel={handleCancel}
        />
      ),
      infoPanel: (
        <InfoPanel>
          <div className="space-y-6">
            <p>{t("meter.info.evidence")}</p>
            <p>{t("evidence.supported")}</p>
          </div>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={2}
          currentStep={currentStep}
          isOnChain={isMeterComplete}
        >
          {t("meter.adding_meter.confirm")}
        </StepButton>
      ),
      panel: (
        <ConfirmMeter
          workflowInstanceIds={[]}
          onConfirm={handleConfirmCreateMeter}
          onCancel={handleCancel}
          onPrevious={stepHelpers.goToPrevStep}
          onEdit={stepHelpers.setStep}
          data={[getMeterInfo(), getEvidencesInfo()]}
          isComplete={false}
        />
      ),
      infoPanel: (
        <InfoPanel
          successes={
            isMeterComplete
              ? [t("meter.info.success", { serialNo: meterDetails?.serialNo })]
              : [t("meter.info.confirm")]
          }
        >
          {isMeterComplete ? (
            t("user.info.end_workflow")
          ) : (
            <ul className="space-y-4">
              <li>{t("common.info.verify")}</li>
              <li>
                <strong>{t("meter.adding_meter.details")}</strong>{" "}
                {t("meter.info.verify.details")}
              </li>
              <li>
                <strong>{t("meter.adding_meter.units_and_click_over")}</strong>{" "}
                {t("meter.info.verify.units")}
              </li>
              <li>
                <strong>{t("common.location")}</strong>{" "}
                {t("meter.info.verify.location")}
              </li>
              <li>
                <strong>{t("evidence.supporting")}:</strong>{" "}
                {t("evidence.info.verify")}
              </li>
              <li>{t("common.info.changes")}</li>
            </ul>
          )}
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={3}
          currentStep={currentStep}
          info={<StepInfo data={declarationInfo.body} />}
          isOnChain={isDeclarationComplete}
        >
          {t("declaration.declare_initial_meter_reading")}
        </StepButton>
      ),
      panel: (
        <MeterDeclarationForm
          variant="meter_only_reading"
          onSubmit={(data) => {
            setDeclaration(data);
            stepHelpers.goToNextStep();
          }}
          onCancel={handleCancel}
          defaultValues={{
            ...declaration,
            serialNo: declaration?.serialNo || meterResponse?.serialNo,
            extractionPointName:
              declaration?.extractionPointName ||
              meterResponse?.extractionPoint?.name,
          }}
        />
      ),
      infoPanel: (
        <InfoPanel>
          <div className="space-y-6">
            <p>{t("declaration.info.details")}</p>
            <p>{t("declaration.info.is_estimated")}</p>
            <p>{t("declaration.info.initial_reading")}</p>
          </div>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={4}
          currentStep={currentStep}
          info={<StepInfo data={getEvidencesInfo().body} />}
          isOnChain={isDeclarationComplete}
        >
          {t("common.enter_evidence")}
        </StepButton>
      ),
      panel: (
        <UploadEvidencesWorkflowForm
          onNext={stepHelpers.goToNextStep}
          onPrevious={stepHelpers.goToPrevStep}
          onCancel={handleCancel}
        />
      ),
      infoPanel: (
        <InfoPanel>
          <div className="space-y-6">
            <p>{t("declaration.info.evidence")}</p>
            <p>{t("evidence.supported")}</p>
          </div>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={5}
          currentStep={currentStep}
          isOnChain={isDeclarationComplete}
        >
          {t("declaration.form.confirm_read")}
        </StepButton>
      ),
      panel: (
        <ConfirmMeterDeclaration
          workflowInstanceIds={[
            declarationWorkflowInstance?.id,
            meterWorkflowInstance?.id,
          ]}
          onCancel={handleCancel}
          onPrevious={stepHelpers.goToPrevStep}
          onEdit={(step) => stepHelpers.setStep(step + 3)}
          onSubmit={handleConfirmCreateInitialDeclaration}
          data={[declarationInfo, getEvidencesInfo()]}
          isComplete={false}
        />
      ),
      infoPanel: (
        <InfoPanel
          successes={
            isDeclarationComplete
              ? [
                  t("declaration.info.success", {
                    serialNo: declaration?.serialNo,
                    reading: declaration?.reading,
                  }),
                ]
              : [t("declaration.info.confirm")]
          }
        >
          {isDeclarationComplete ? (
            t("user.info.end_workflow")
          ) : (
            <ul className="space-y-4">
              <li>{t("common.info.verify")}</li>
              <li>
                <strong>{t("declaration.meter_read_details")}</strong>{" "}
                {t("declaration.info.verify.details")}
              </li>
              <li>
                <strong>{t("evidence.supporting")}:</strong>{" "}
                {t("evidence.info.verify")}
              </li>
              <li>{t("common.info.changes")}</li>
            </ul>
          )}
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={6}
          currentStep={currentStep}
          info={<StepInfo data={getExtractionPointDetails().body} />}
          isOnChain={isLinkMeterCompleted}
        >
          {t("meter.adding_meter.link_extraction_point")}
        </StepButton>
      ),
      panel: (
        <div className="flex flex-col gap-6 p-6 grow justify-between">
          <div className="space-y-4">
            <Heading1 light>
              {t("meter.adding_meter.select_extraction_point")}
            </Heading1>
          </div>
          <SelectSingleExtractionPointTable
            selected={linkMeterDetails.extractionPoint}
            level1ResourceId={level1ResourceId}
            definedByWalletId={subscriber?.walletId}
            onSelect={(selected: any) => {
              setLinkMeterDetails((prev: any) => ({
                ...prev,
                meter: {
                  id: meterResponse?.id,
                  serialNo: meterResponse?.serialNo,
                },
                extractionPoint: {
                  id: selected?.id,
                  name: selected?.name,
                  level0ResourceId: selected?.level0ResourceId,
                  isActive: selected?.isActive,
                  oldMeter: {
                    id: selected?.meter?.id ?? "",
                    serialNo: selected?.meter?.serialNo ?? "",
                  },
                },
                subscriber: {
                  id: selected?.subscriber?.id,
                  name: selected?.subscriber?.name,
                  walletId: selected?.subscriber?.walletId,
                  level1ResourceId: selected?.subscriber?.level1ResourceId,
                },
              }));
            }}
            onFetchSuccess={(points) => {
              const hasCurrentMeter = points.find(
                (p: any) =>
                  p.meter?.id === meterResponse.id &&
                  p.id === linkMeterDetails.extractionPoint?.id
              );
              if (hasCurrentMeter) {
                setIsLinkMeterComplete(true);
                stepHelpers.setStep(8);
              }
            }}
          />
          <footer className="flex gap-4 -mx-2 mt-2 p-6 pb-0 border-t border-gray-200">
            <button
              type="button"
              className="btn-primary"
              onClick={() => stepHelpers.goToNextStep()}
              disabled={
                linkMeterDetails.extractionPoint?.id === "" ||
                (linkMeterDetails.extractionPoint?.id !== "" &&
                  linkMeterDetails.extractionPoint?.oldMeter?.id !== "")
              }
            >
              {t("common.next_step")}
            </button>

            <button
              type="button"
              className="btn-outline-primary"
              onClick={handleCancel}
            >
              {t("common.cancel")}
            </button>
          </footer>
        </div>
      ),
      infoPanel: (
        <InfoPanel
          warnings={[
            ...(isReplaceMeterWarning
              ? [
                  t("meter.adding_meter.info.warn_replacement", {
                    extractionPointName: linkMeterDetails.extractionPoint?.name,
                    oldMeterName:
                      linkMeterDetails.extractionPoint?.oldMeter?.serialNo,
                  }),
                ]
              : []),
          ]}
          actions={
            isReplaceMeterWarning
              ? [
                  {
                    label: t("meter.adding_meter.info.replacement_action", {
                      extractionPointName:
                        linkMeterDetails.extractionPoint?.name,
                    }),
                    action: () => {
                      const subscriberId = linkMeterDetails.subscriber?.id;
                      window.open(
                        `/polestar/subscribers/${subscriberId}/level0_resources/${linkMeterDetails?.extractionPoint?.level0ResourceId}/meters/replace?subscriberId=${subscriberId}&extractionPointId=${linkMeterDetails.extractionPoint?.id}&newWindow=true&replacementMeterId=${meterResponse.id}`,
                        "_blank"
                      );
                    },
                  },
                ]
              : []
          }
        >
          <div className="space-y-6">
            <p>{t("meter.adding_meter.info.select_extraction_point")}</p>
          </div>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={7}
          currentStep={currentStep}
          info={<StepInfo data={getEvidencesInfo().body} />}
          isOnChain={isLinkMeterCompleted}
        >
          {t("common.enter_evidence")}
        </StepButton>
      ),
      panel: (
        <UploadEvidencesWorkflowForm
          onNext={stepHelpers.goToNextStep}
          onPrevious={stepHelpers.goToPrevStep}
          onCancel={handleCancel}
        />
      ),
      infoPanel: (
        <InfoPanel>
          <div className="space-y-6">
            <p>{t("extraction_point.link_meter.evidences.title")}</p>
            <p>{t("evidence.supported")}</p>
          </div>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={8}
          currentStep={currentStep}
          isOnChain={isLinkMeterCompleted}
        >
          {t("meter.adding_meter.confirm_extraction_point")}
        </StepButton>
      ),
      panel: (
        <ConfirmLinkMeter
          workflowInstanceIds={[
            declarationWorkflowInstance?.id,
            meterWorkflowInstance?.id,
            linkMeterWorkflowInstance?.id,
          ]}
          onCancel={handleCancel}
          onSubmit={handleConfirmLinkMeter}
        />
      ),
      infoPanel: (
        <InfoPanel
          successes={
            isLinkMeterCompleted
              ? [
                  t("declaration.info.success", {
                    serialNo: declaration?.serialNo,
                    reading: declaration?.reading,
                  }),
                ]
              : [t("declaration.info.confirm")]
          }
        >
          {isLinkMeterCompleted ? (
            t("user.info.end_workflow")
          ) : (
            <ul className="space-y-4">
              <li>{t("common.info.verify")}</li>
              <li>
                <strong>{t("declaration.meter_read_details")}</strong>{" "}
                {t("declaration.info.verify.details")}
              </li>
              <li>
                <strong>{t("evidence.supporting")}:</strong>{" "}
                {t("evidence.info.verify")}
              </li>
              <li>{t("common.info.changes")}</li>
            </ul>
          )}
        </InfoPanel>
      ),
    },
  ];

  return <WorkflowPanels selectedStep={currentStep} steps={steps} />;
};

export default CreateMeterWorkflow;
