import React from "react";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import HandleGoBackOrClose from "@components/shared/HandleGoBackOrClose";
import SelectSubscriber from "./SelectSubscriber";
import EnterDetails from "./EnterDetails";
import ConfirmUnlink from "./ConfirmUnlink";
import StepButton from "@components/shared/StepButton";
import StepInfo from "@components/shared/StepInfo";
import InfoPanel from "@components/form/InfoPanel";
import WorkflowPanels from "@components/form/WorkflowPanels";
import UploadEvidencesWorkflowForm from "@components/form/UploadEvidenceWorkflowForm";
import {
  EvidenceProvider,
  useEvidenceContext,
} from "@context/shared/EvidenceContext";
import { StepProvider, useStepContext } from "@context/shared/StepContext";
import { useSubscriber } from "@hooks/query/useSubscriber";
import { useUnlinkExtractionPoint } from "@hooks/mutation/useUnlinkExtractionPoint";
import { formatDate } from "@utils/formatDate";
import { DBTables } from "@utils/constants/dbTables";
import ENV from "@config/env";

const UnlinkExtractionPointWorkflow = () => {
  return (
    <StepProvider maxStep={4}>
      <EvidenceProvider>
        <Consumer />
      </EvidenceProvider>
    </StepProvider>
  );
};

type ExtractionRight = {
  id: string;
  name: string;
};

const initialDetails = {
  subscriber: {
    id: "",
    name: "",
    walletId: "",
  },
  level0Resource: {
    id: "",
    identifier: "",
  },
  extractionPoint: {
    id: "",
    name: "",
  },
  extractionRights: [] as ExtractionRight[],
  effectiveAt: new Date(),
};

const Consumer = () => {
  const { t } = useTranslation();
  const handleGoBackOrClose = HandleGoBackOrClose();
  const [searchParams] = useSearchParams();
  const subscriberId = searchParams.get("subscriberId") || "";

  const [isComplete, setIsComplete] = React.useState(false);
  const [workflowInstance, setWorkflowInstance] = React.useState(false);
  const [details, setDetails] = React.useState({ ...initialDetails });

  const { mutateAsync: unlinkExtractionPointMutation } =
    useUnlinkExtractionPoint();

  useSubscriber(subscriberId, {
    onSuccess: (res: any) => {
      setDetails((prev) => ({
        ...prev,
        subscriber: {
          id: res.id,
          name: res.name,
          walletId: res.walletId,
        },
      }));
    },
  });

  const { currentStep, stepHelpers } = useStepContext();
  const { getEvidencesInfo, uploadEvidences } = useEvidenceContext();

  const handleCancel = handleGoBackOrClose;

  const getSubscriberDetails = () => {
    return {
      title: t("subscriber.create.subscriber_details"),
      body: [
        {
          key: t("subscriber.create.subscriber_name"),
          value: details.subscriber.name,
        },
      ],
    };
  };

  const getDetails = () => {
    return {
      title: t("extraction_point.details"),
      body: [
        {
          key: t("extraction_point.name"),
          value: details.extractionPoint.name,
          show: true,
        },
        {
          key: t("common.extraction_rights"),
          value: details.extractionRights.map((i) => i.name).join(", "),
          show: ENV.CLIENT_ID !== "seqwater",
        },
        {
          key: t("common.timestamp"),
          value: formatDate(details.effectiveAt),
          show: true,
        },
      ].filter((i) => i.show),
    };
  };

  const handleSubmit = async () => {
    const [res, workflowInstance] = await unlinkExtractionPointMutation({
      extractionPointId: details.extractionPoint.id,
      extractionRightIds: details.extractionRights.map((i) => i.id),
      effectiveAt: details.effectiveAt,
    });

    await uploadEvidences({
      description: t("extraction_point.unlink.evidence_description", {
        extractionRightName: details.extractionPoint.name,
        extractionRightNames: details.extractionRights
          .map((i) => i.name)
          .join(", "),
      }),
      referenceId: res.id,
      referenceTable: DBTables.ExtractionPoints,
    });

    setWorkflowInstance(workflowInstance);
    setIsComplete(true);
  };

  const steps = [
    {
      label: (
        <StepButton
          index={0}
          currentStep={currentStep}
          isOnChain={isComplete}
          info={<StepInfo data={getSubscriberDetails().body} />}
        >
          {t("extraction_point.unlink.select_subscriber")}
        </StepButton>
      ),
      panel: (
        <SelectSubscriber
          onSelect={(value) => {
            setDetails({
              ...initialDetails,
              subscriber: {
                id: value.id,
                name: value.name,
                walletId: value.walletId,
              },
            });
          }}
          value={details.subscriber}
          onNext={stepHelpers.goToNextStep}
          onCancel={handleCancel}
          disabled={Boolean(subscriberId)}
        />
      ),
      infoPanel: (
        <InfoPanel>
          <div className="space-y-6">
            <p>{t("extraction_point.unlink.info.select_subscriber")}</p>
          </div>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={1}
          currentStep={currentStep}
          isOnChain={isComplete}
          info={<StepInfo data={getDetails().body} />}
        >
          {t("extraction_point.unlink.enter_details")}
        </StepButton>
      ),
      panel: (
        <EnterDetails
          onChange={setDetails}
          values={details}
          onNext={stepHelpers.goToNextStep}
          onPrev={stepHelpers.goToPrevStep}
          onCancel={handleCancel}
        />
      ),
      infoPanel: (
        <InfoPanel>
          <div className="space-y-6">
            <p>{t("extraction_point.unlink.info.enter_details")}</p>
          </div>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={2}
          currentStep={currentStep}
          info={<StepInfo data={getEvidencesInfo().body} />}
          isOnChain={isComplete}
        >
          {t("common.enter_evidence")}
        </StepButton>
      ),
      panel: (
        <UploadEvidencesWorkflowForm
          onNext={stepHelpers.goToNextStep}
          onPrevious={stepHelpers.goToPrevStep}
          onCancel={handleCancel}
        />
      ),
      infoPanel: (
        <InfoPanel>
          <div className="space-y-6">
            <p>{t("extraction_point.unlink.info.evidence")}</p>
            <p>{t("evidence.supported")}</p>
          </div>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={3}
          currentStep={currentStep}
          isOnChain={isComplete}
          info={
            <>
              <strong>{t("common.subscriber")}:</strong>
            </>
          }
        >
          {t("extraction_point.unlink.confirm")}
        </StepButton>
      ),
      panel: (
        <ConfirmUnlink
          data={[getSubscriberDetails(), getDetails(), getEvidencesInfo()]}
          onPrev={stepHelpers.goToPrevStep}
          onEdit={stepHelpers.setStep}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
          isComplete={isComplete}
          workflowInstance={workflowInstance}
          details={details}
        />
      ),
      infoPanel: (
        <InfoPanel
          successes={
            isComplete
              ? [
                  t("extraction_point.unlink.info.success", {
                    extractionPointName: details.extractionPoint.name,
                    extractionRightNames: details.extractionRights
                      .map((i) => i.name)
                      .join(", "),
                  }),
                ]
              : [t("extraction_point.unlink.info.confirm")]
          }
        >
          <div className="space-y-6">
            {isComplete ? (
              <p>{t("extraction_point.unlink.info.end_workflow")}</p>
            ) : (
              <ul className="space-y-4">
                <li>{t("common.info.verify")}</li>
                <li>
                  <strong>{t("extraction_point.details")}</strong>{" "}
                  {t(
                    "extraction_point.unlink.info.verify.extraction_point_details"
                  )}
                </li>
                <li>
                  <strong>{t("evidence.supporting")}:</strong>{" "}
                  {t("evidence.info.verify")}
                </li>
                <li>{t("common.info.changes")}</li>
              </ul>
            )}
          </div>
        </InfoPanel>
      ),
    },
  ];

  return <WorkflowPanels selectedStep={currentStep} steps={steps} />;
};

export default UnlinkExtractionPointWorkflow;
