import Label from "@components/form/Label";
import SearchInput from "@components/form/SearchInput";
import TextInput from "@components/form/TextInput";
import SubscriberNotes from "@components/form/subscriber/SubscriberNotes";
import ArrowRightCalendarIcon from "@components/icons/ArrowRightCalendarIcon";
import ExternalLinkIcon from "@components/icons/ExternalLinkIcon";
import Modal from "@components/layout/Modal";
import Table from "@components/layout/Table";
import { getSubscriberNotes } from "@services/subscriber";
import { useQuery } from "@tanstack/react-query";
import { formatDateInput } from "@utils/formatDate";
import { formatDateTime } from "@utils/formatDateTime";
import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { endOfDay, isValid, startOfDay, subYears } from "date-fns";

type SubscriberNotesTableProps = {
  level1Resource: any;
};

const SubscriberNotesTable: FunctionComponent<SubscriberNotesTableProps> = ({
  level1Resource,
}) => {
  const { t } = useTranslation();
  const [filter, setFilter] = useState<any>({
    subscriberName: "",
    subscriberNote: "",
    startDate: subYears(new Date(), 1),
    endDate: new Date(),
  });
  const [selectedNote, setSelectedNote] = useState<any>();

  const { data: subscriberNotes, refetch: getNotes } = useQuery(
    ["subscriberNotes", level1Resource?.id],
    () =>
      getSubscriberNotes({
        level1ResourceId: level1Resource?.id,
        fromDate: isValid(new Date(filter.startDate))
          ? startOfDay(filter.startDate)
          : undefined,
        toDate: isValid(new Date(filter.endDate))
          ? endOfDay(filter.endDate)
          : undefined,
      }),
    {
      keepPreviousData: true,
    }
  );

  const handleFilterChange = (field: string, value: any) => {
    setFilter({
      ...filter,
      [field]: value,
    });
  };

  const tableFields = [
    {
      title: t("subscriber.name"),
      name: "subscriberName",
    },
    {
      title: t("subscriber.notes"),
      name: "note",
    },
    {
      title: t("common.timestamp"),
      name: "timestamp",
    },
    {
      title: t("common.actions"),
      name: "actions",
    },
  ];

  const tableData: any[] = subscriberNotes
    ?.filter((note: any) => {
      return (
        !filter.subscriberName ||
        note.subscriber?.name?.toLowerCase().includes(filter.subscriberName)
      );
    })
    .filter((note: any) => {
      return (
        !filter.subscriberNote ||
        note.note?.toLowerCase().includes(filter.subscriberNote)
      );
    })
    .map((note: any) => ({
      ...note,
      subscriberName: note.subscriber?.name,
      timestamp: formatDateTime(new Date(note.createdAt)),
      actions: (
        <div className="flex gap-1">
          <button
            type="button"
            className="flex gap-3 items-center btn-primary text-sm rounded"
            onClick={() => setSelectedNote(note)}
          >
            {t("subscriber.view_notes")}
          </button>
          <Link
            to={`/polestar/subscribers/${note.subscriber?.id}`}
            target="_blank"
            rel="noreferrer"
            className="flex gap-3 items-center btn-default text-sm rounded"
          >
            <ExternalLinkIcon className="w-4 h-4" />
            {t("subscriber.view_subscriber")}
          </Link>
        </div>
      ),
    }));

  useEffect(() => {
    getNotes();
  }, [filter.startDate, filter.endDate, getNotes]);

  return (
    <>
      <header className="flex items-end gap-4">
        <div>
          <Label htmlFor="subscriberName">{t("subscriber.name")}</Label>
          <SearchInput
            id="subscriberName"
            value={filter?.subscriberName ?? ""}
            onChange={(e) =>
              handleFilterChange("subscriberName", e.target.value)
            }
          />
        </div>
        <div>
          <Label htmlFor="subscriberNote">{t("subscriber.notes")}</Label>
          <SearchInput
            id="subscriberNote"
            value={filter?.subscriberNote ?? ""}
            onChange={(e) =>
              handleFilterChange("subscriberNote", e.target.value)
            }
          />
        </div>
        <div>
          <Label htmlFor="startDate">{t("reporting.filter_dates")}</Label>
          <div className="flex items-center gap-1">
            <TextInput
              type="date"
              id="startDate"
              max={filter.endDate ? formatDateInput(filter.endDate) : ""}
              placeholder={t("common.date_range.from") as string}
              value={filter.startDate ? formatDateInput(filter.startDate) : ""}
              onChange={(e) => {
                handleFilterChange(
                  "startDate",
                  isValid(new Date(e.target.value))
                    ? new Date(`${e.target.value}T00:00:00`)
                    : ""
                );
              }}
            />
            <ArrowRightCalendarIcon className="w-10 h-10" />
            <TextInput
              type="date"
              min={filter.startDate ? formatDateInput(filter.startDate) : ""}
              placeholder={t("common.date_range.to") as string}
              value={filter.endDate ? formatDateInput(filter.endDate) : ""}
              onChange={(e) => {
                handleFilterChange(
                  "endDate",
                  isValid(new Date(e.target.value))
                    ? new Date(`${e.target.value}T23:59:59`)
                    : ""
                );
              }}
            />
          </div>
        </div>
      </header>
      <Table fields={tableFields} data={tableData} stickyHeader />
      <Modal open={selectedNote}>
        <SubscriberNotes subscriber={selectedNote?.subscriber} />
        <footer className="flex justify-end gap-4 px-4 py-3 border-t">
          <button
            type="button"
            className="btn-default"
            onClick={() => {
              setSelectedNote(undefined);
              getNotes();
            }}
          >
            {t("common.close")}
          </button>
        </footer>
      </Modal>
    </>
  );
};

export default SubscriberNotesTable;
