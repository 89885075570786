import { useTranslation } from "react-i18next";

import MeterTable from "@components/table/MeterTable";
import { useReplaceMeterContext } from "@context/ReplaceMeterContext";
import { useStepContext } from "@context/shared/StepContext";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/solid";

type SelectMeterReplacementProps = {
  onCancel: () => void;
  hasPredefinedReplacementMeter: boolean;
};

const SelectMeterReplacement: React.FunctionComponent<
  SelectMeterReplacementProps
> = ({ onCancel, hasPredefinedReplacementMeter }) => {
  const { t } = useTranslation();
  const { details, handleSelecteMeter } = useReplaceMeterContext();
  const { stepHelpers } = useStepContext();

  return (
    <>
      <MeterTable
        title={t("meter.replacement.choose_meter")}
        value={details.replacementMeter}
        onChange={handleSelecteMeter}
        disableSelection={hasPredefinedReplacementMeter}
      >
        {!hasPredefinedReplacementMeter && (
          <a
            href="/polestar/meters/create"
            target="_blank"
            rel="noreferrer"
            className="flex gap-3 items-center btn-default text-sm rounded"
          >
            <ArrowTopRightOnSquareIcon className="w-5 h-5" />
            {t("common.add_new")}
          </a>
        )}
      </MeterTable>

      <footer className="flex gap-4 p-6 border-t border-gray-200">
        <button
          type="button"
          className="btn-primary"
          onClick={stepHelpers.goToNextStep}
          disabled={details.replacementMeter.id === ""}
        >
          {t("common.next_step")}
        </button>
        <button
          type="button"
          className="btn-outline-primary"
          onClick={onCancel}
        >
          {t("common.cancel")}
        </button>
      </footer>
    </>
  );
};

export default SelectMeterReplacement;
