import React from "react";
import Select from "react-select";
import orderBy from "lodash/orderBy";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Table from "@components/layout/Table";
import Label from "@components/form/Label";
import SearchInput from "@components/form/SearchInput";
import MapView from "@components/shared/MapView";
import DeclarationTag from "@components/shared/DeclarationTag";
import ExtractionPointStatusTags from "@components/shared/ExtractionPointStatusTags";
import { useAppContext } from "@context/AppContext";
import { formatVolume } from "@utils/formatVolume";
import { formatDate } from "@utils/formatDate";
import { parseLatLng } from "@utils/parseLatLng";
import { useSubscriberIndexContext } from "@context/SubscriberIndexContext";

import ENV from "@config/env";

const ExtractionPointTab: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const {
    subscriber,
    zonePriorityBalance,
    filter,
    handleFilterChange,
    zoneExtractionRights,
  } = useSubscriberIndexContext();
  const { checkPermissions } = useAppContext();
  const navigate = useNavigate();

  subscriber?.extractionPoints?.map(
    (ep: any) =>
      (ep.declarations = subscriber.declarations.filter(
        (deceleration: any) => deceleration.extractionPointId === ep.id
      ))
  );

  const filteredExtractionPoints = subscriber?.extractionPoints
    ?.filter(
      (point: any) => point?.level0ResourceId === zonePriorityBalance?.zoneId
    )
    .filter(
      (point: any) =>
        filter.extractionPointName === undefined ||
        point.name
          .toLowerCase()
          .includes(filter.extractionPointName.toLowerCase())
    );
  const orderExtractionPoints = orderBy(
    filteredExtractionPoints,
    ["isActive", "name"],
    ["desc", "asc"]
  );

  const currentPath = window.location.pathname;

  const isLinkToActiveExtractionRight = (extractionPointId: string) => {
    const activeRights = zoneExtractionRights.filter((i: any) => i.isActive);

    return activeRights.some((i: any) =>
      i.extractionPoints.some((point: any) => point.id === extractionPointId)
    );
  };

  return (
    <>
      <header className="flex flex-col gap-3 md:flex-row md:items-center md:justify-between">
        <form className="basis-80">
          <Label htmlFor="offtake-id">
            {t("extraction_point.filter_name")}
          </Label>
          <SearchInput
            id="offtake-id"
            onChange={(e) =>
              handleFilterChange("extractionPointName", e.target.value)
            }
            value={filter.extractionPointName || ""}
            placeholder={t("common.search") as string}
          />
        </form>
        <div className="flex gap-2 items-center">
          {checkPermissions(["CreateExtractionPoint"]) && (
            <Link
              to={`/polestar/subscribers/${subscriber?.id}/level0_resources/${zonePriorityBalance?.zoneId}/extraction_points/create?subscriberId=${subscriber?.id}&level0ResourceId=${zonePriorityBalance?.zoneId}`}
              className="btn-secondary text-xs rounded"
            >
              {t("extraction_point.new_extraction_point")}
            </Link>
          )}
          {checkPermissions(["CreateMeters"]) && (
            <Link
              to={`/polestar/subscribers/${subscriber?.id}/level0_resources/${zonePriorityBalance?.zoneId}/meters/create?level1ResourceId=${subscriber?.level1ResourceId}`}
              className="btn-secondary text-xs rounded"
            >
              {t("meter.new_meter")}
            </Link>
          )}
        </div>
      </header>
      <div className="mt-2 overflow-auto">
        <ul className="space-y-4">
          {orderExtractionPoints?.length ? (
            orderExtractionPoints.map((point: any) => {
              const latLong = parseLatLng(point?.address?.location);
              const hasMeter = point?.meter !== null;
              const canEditMeter =
                hasMeter && checkPermissions(["UpdateMeters"]);
              const isLinked = isLinkToActiveExtractionRight(point.id);
              const canEditExtractionPoint =
                isLinked && checkPermissions(["UpdateExtractionPoint"]);
              const canDeclareMeterRead =
                checkPermissions(["CreateDeclarations"]) &&
                point.isActive &&
                isLinked;
              const canReplaceMeter =
                checkPermissions([
                  "UpdateExtractionPoint",
                  "UpdateMeters",
                  "CreateDeclarations",
                ]) &&
                hasMeter &&
                isLinked;
              const canAddMeter =
                checkPermissions(["CreateMeters"]) &&
                !hasMeter &&
                isLinked &&
                point.isActive;
              const canDecommissionMeter =
                checkPermissions([
                  "UpdateExtractionPoint",
                  "CreateDeclarations",
                  "DecommissionMeters",
                ]) &&
                hasMeter &&
                isLinked;
              const canReplaceMeterCapsule = canDeclareMeterRead && hasMeter;

              return (
                <li key={point.id}>
                  <div className="grid grid-cols-10 place-content-between">
                    <div className="col-span-6 border border-gray-300 rounded p-4">
                      <header className="flex flex-row gap-3 flex-wrap items-center text-gray-600 text-xs whitespace-nowrap">
                        <div>
                          {t("extraction_point.name")} #{point.name}
                        </div>
                        <div className="flex flex-row items-center gap-2">
                          {t("extraction_point.is_active")}:
                          <ExtractionPointStatusTags point={point} />
                        </div>
                        {hasMeter ? (
                          <div>
                            {t("meter.serial_no")}: {point.meter?.serialNo}
                          </div>
                        ) : null}
                        <div>
                          {t("extraction_point.source")}:{" "}
                          {point.source.toLowerCase()}
                        </div>
                        <div>
                          {t("extraction_point.lat")}:{" "}
                          {typeof latLong.lat !== "undefined"
                            ? latLong.lat.toFixed(5)
                            : "-"}
                        </div>
                        <div>
                          {t("extraction_point.long")}:{" "}
                          {typeof latLong.lng !== "undefined"
                            ? latLong.lng.toFixed(5)
                            : "-"}
                        </div>
                        {ENV.CLIENT_ID === "seqwater" && (
                          <>
                            <div>
                              {t("extraction_point.group")}:{" "}
                              {point.group ?? "-"}
                            </div>
                            <div>
                              {t("extraction_point.sequence")}:{" "}
                              {point.sequence ?? "-"}
                            </div>
                          </>
                        )}
                      </header>

                      <div className="flex justify-end gap-2 items-center mt-1 mb-3">
                        {checkPermissions(["LinkExtractionPoint"]) ? (
                          <Link
                            to={`${currentPath}/level0_resources/${point.level0ResourceId}/extraction_points/link?extractionPointId=${point.id}`}
                            className="btn-secondary text-xs py-2 px-3 rounded"
                          >
                            {t("extraction_point.link_offtake")}
                          </Link>
                        ) : null}
                        {checkPermissions(["UnlinkExtractionPoint"]) ? (
                          <Link
                            to={`/polestar/level1wrs/${subscriber?.level1ResourceId}/extraction_points/unlink?subscriberId=${subscriber?.id}&level0ResourceId=${zonePriorityBalance?.zoneId}&extractionPointId=${point.id}`}
                            className="btn-outline-primary text-xs py-2 px-3 rounded"
                          >
                            {t("extraction_point.unlink.title")}
                          </Link>
                        ) : null}
                        <Select
                          className="text-sm w-40"
                          placeholder={t("common.actions") as string}
                          options={[
                            {
                              label: t("extraction_point.edit"),
                              value: `/polestar/subscribers/${subscriber?.id}/level0_resources/${zonePriorityBalance?.zoneId}/extraction_points/${point.id}/edit?subscriberId=${subscriber?.id}`,
                              disabled: !canEditExtractionPoint,
                            },
                            {
                              label: t("meter.new_meter"),
                              onClick: () => {
                                navigate(
                                  `${currentPath}/level0_resources/${zonePriorityBalance?.zoneId}/meters/create?level1ResourceId=${subscriber?.level1ResourceId}&extractionPointId=${point.id}`
                                );
                              },
                              disabled: !canAddMeter,
                              value: `${currentPath}/level0_resources/${zonePriorityBalance?.zoneId}/meters/create?level1ResourceId=${subscriber?.level1ResourceId}&extractionPointId=${point.id}`,
                            },
                            {
                              label: t("meter.edit.title"),
                              value: `/polestar/meters/${point?.meter?.id}/edit`,
                              state: {
                                from: currentPath,
                                breadcrumb: [
                                  {
                                    label: t("common.subscribers"),
                                    href: "/polestar/subscribers",
                                  },
                                  {
                                    label: `${subscriber?.name}`,
                                    href: currentPath,
                                  },
                                ],
                              },
                              disabled: !canEditMeter,
                            },
                            {
                              label: hasMeter
                                ? t("declaration.declare_meter_reading")
                                : t("declaration.unmetered_usage.title"),
                              value: `/polestar/subscribers/${subscriber.id}/level0_resources/${zonePriorityBalance?.zoneId}/declarations/create?&subscriberId=${subscriber.id}&extractionPointId=${point?.id}`,
                              state: { from: currentPath },
                              disabled: !canDeclareMeterRead,
                            },
                            {
                              label: t("meter.replace"),
                              value: `/polestar/subscribers/${subscriber.id}/level0_resources/${zonePriorityBalance?.zoneId}/meters/replace?subscriberId=${subscriber.id}&extractionPointId=${point.id}`, // `/polestar/level1wrs/${subscriber?.level1ResourceId}/meters/replace?subscriberId=${subscriber.id}&extractionPointId=${point.id}`,
                              state: { from: currentPath },
                              disabled: !canReplaceMeter,
                            },
                            {
                              label: t("meter.deactivate"),
                              value: `/polestar/subscribers/${subscriber.id}/level0_resources/${zonePriorityBalance?.zoneId}/meters/decommission?subscriberId=${subscriber.id}&extractionPointId=${point.id}`,
                              state: { from: currentPath },
                              disabled: !canDecommissionMeter,
                            },
                            {
                              label: t(
                                "extraction_point.link_meter.action_label"
                              ),
                              value: `/polestar/subscribers/${subscriber.id}/level0_resources/${point.level0ResourceId}/extraction_points/link_meter?subscriberId=${subscriber.id}&extractionPointId=${point.id}`,
                              state: { from: currentPath },
                              disabled: !point.isActive || hasMeter,
                            },
                            {
                              label: t("meter.replace_capsule.title"),
                              value: `/polestar/level1wrs/${subscriber.level1ResourceId}/meters/${point.meter?.id}/replace_capsule?subscriberId=${subscriber.id}&extractionPointId=${point.id}`,
                              disabled: !canReplaceMeterCapsule,
                            },
                          ].filter((i) => !Boolean(i.disabled))}
                          onChange={(e: any) => {
                            if (e?.value) {
                              navigate(e.value, {
                                state: e.state,
                              });
                            }
                          }}
                          menuPortalTarget={document.body}
                          styles={{
                            menuPortal: (base) => ({
                              ...base,
                              fontSize: "14px",
                            }),
                          }}
                        />
                      </div>

                      <Table
                        containerClassName="rounded-none md:rounded-none text-xs"
                        fields={[
                          {
                            title: t("meter.serial_no"),
                            name: "meterId",
                          },
                          {
                            title: t("declaration.read_at"),
                            name: "readAt",
                          },
                          {
                            title: t("declaration.reading"),
                            name: "reading",
                          },
                          {
                            title: `${t("declaration.volume")} (${t(
                              "common.volume_unit"
                            )})`,
                            name: "volume",
                          },
                          {
                            title: t("common.tag"),
                            name: "tag",
                          },
                        ]}
                        data={point.declarations.map((declaration: any) => ({
                          ...declaration,
                          meterId:
                            declaration.meter?.serialNo ?? t("meter.unmetered"),
                          readAt: formatDate(new Date(declaration.readAt)),
                          reading: declaration.meter?.serialNo
                            ? formatVolume(declaration.reading, "")
                            : "-",
                          volume: formatVolume(
                            declaration.volume < 0 ||
                              declaration.requireManualIntervention
                              ? 0
                              : declaration.volume,
                            ""
                          ),
                          tag: declaration.tag ? (
                            <DeclarationTag value={declaration.tag} />
                          ) : null,
                        }))}
                        noRowsText={t("declaration.no_declaration")}
                        pageSize={5}
                      />
                    </div>
                    <div className="col-span-4 pl-4 h-80">
                      {latLong.isValid ? (
                        <MapView
                          zoomLevel={14}
                          markers={[
                            { lat: latLong.lat ?? 0, lng: latLong.lng ?? 0 },
                          ]}
                        />
                      ) : (
                        <div className="font-semibold text-primary-3 text-center text-sm pt-10">
                          {t("shared.map.invalid_data")}
                        </div>
                      )}
                    </div>
                  </div>
                </li>
              );
            })
          ) : (
            <li>
              <div className="py-6 whitespace-nowrap text-gray-400 text-center">
                {t("extraction_point.no_extraction_point_data")}
              </div>
            </li>
          )}
        </ul>
      </div>
    </>
  );
};

export default ExtractionPointTab;
