import { useTranslation } from "react-i18next";
import TabPanels from "../../../TabPanels";
import NotificationHistoryTab from "./NotificationHistoryTab";
import TransactionHistoryTab from "./TransactionHistoryTab";

type Level0WRSDetailsProps = {
  subscriber: any;
};

const SubscriberHistoryTabs: React.FunctionComponent<Level0WRSDetailsProps> = ({
  subscriber,
}: Level0WRSDetailsProps) => {
  const { t } = useTranslation();

  return (
    <TabPanels
      className="bg-white"
      tabs={[
        {
          title: t("subscriber.audit_trail"),
          panel: <TransactionHistoryTab walletId={subscriber?.walletId} />,
        },
        {
          title: t("notification.history"),
          panel: <NotificationHistoryTab walletId={subscriber?.walletId} />,
        },
      ]}
    />
  );
};

export default SubscriberHistoryTabs;
