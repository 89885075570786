import MagnifyingGlassIcon from "@components/icons/MagnifyingGlassIcon";
import TextInput from "@components/form/TextInput";

type Props = {
  id?: string;
  name?: string;
  placeholder?: string;
  value?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const SearchInput: React.FunctionComponent<Props> = ({
  id,
  name,
  placeholder,
  value,
  onChange,
}) => {
  return (
    <TextInput
      type="search"
      id={id}
      name={name}
      placeholder={placeholder}
      suffix={<MagnifyingGlassIcon className="w-4 h-4" />}
      value={value}
      onChange={onChange}
    />
  );
};

export default SearchInput;
