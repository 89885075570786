import ENV from "@config/env";
import WaterLedgerLogo from "@components/icons/WaterLedgerLogo";
import Modal from "@components/layout/Modal";
import Tag from "@components/shared/Tag";
import { formatDate } from "@utils/formatDate";
import { formatDateTime } from "@utils/formatDateTime";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { convertLiterToML } from "@utils/convertUnits";
import { READING_CALCULATION } from "@services/declarations";

type TrialRunDetailsModalProps = {
  onClose: () => void;
  onClickBackRead: () => void;
  onClickClickOver: () => void;
  data: {
    file: any;
    ledger: any;
  };
  status: string;
};

const TrialRunDetailsModal: React.FunctionComponent<
  TrialRunDetailsModalProps
> = ({ onClose, onClickBackRead, onClickClickOver, status, data }) => {
  const { t } = useTranslation();
  const { file, ledger } = data;

  const isSuccess = ledger.status === "success";
  const isMismatch = ledger.status === "mismatch";
  const isDuplicate = ledger.status === "duplicate";
  const isWarning = ledger.status === "warning";
  const isNoExtractionRight = ledger.status === "no_extraction_right";
  const isNoExtractionPoint = ledger.status === "extraction_point_not_found";
  const isBackRead = ledger.status === READING_CALCULATION.BACKWARD;
  const isClickOver = ledger.status === READING_CALCULATION.CLICK_OVER;
  const isMismatchGroupSequence =
    ledger.status === "allow_mismatch_group_sequence";

  const isWarnGroup =
    file.group?.trim()?.toLowerCase() !== ledger.group?.trim()?.toLowerCase();
  const isWarnSeq = !file.seq && !ledger.seq ? false : file.seq !== ledger.seq;
  const isErrSerialNo =
    !file.serialNo && !ledger.serialNo
      ? false
      : file.serialNo !== ledger.serialNo;

  const details = [
    {
      title: t("bulk_meter_upload.modal.file_details"),
      body: [
        ...(ENV.CLIENT_ID === "seqwater"
          ? [
              {
                key: t("extraction_point.group"),
                value: file.group,
                isWarn: isWarnGroup,
              },
              {
                key: t("extraction_point.sequence"),
                value: file.seq,
                isWarn: isWarnSeq,
              },
            ]
          : []),
        {
          key: t("bulk_meter_upload.table.account_number"),
          value: file.accountNumber,
          isError: file.accountNumber !== ledger.accountNumber,
        },
        {
          key: t("bulk_meter_upload.table.extraction_point_name"),
          value: file.extractionPointName,
          isError: file.extractionPointName !== ledger.extractionPointName,
        },
        {
          key: t("bulk_meter_upload.table.meter_serial_no"),
          value: file.serialNo,
          isError: isErrSerialNo,
        },
        {
          key: t("bulk_meter_upload.table.meter_read"),
          value: `${file.reading}`,
          isError: isDuplicate,
        },
        {
          key: t("bulk_meter_upload.table.date"),
          value: file.readAt ? formatDate(file.readAt) : "-",
          isError: isDuplicate,
        },
      ],
    },
    {
      title: t("bulk_meter_upload.modal.ledger_details"),
      body: [
        ...(ENV.CLIENT_ID === "seqwater"
          ? [
              {
                key: t("extraction_point.group"),
                value: ledger.group,
                isWarn: isWarnGroup,
              },
              {
                key: t("extraction_point.sequence"),
                value: ledger?.seq,
                isWarn: isWarnSeq,
              },
            ]
          : []),
        {
          key: t("bulk_meter_upload.table.account_number"),
          value: ledger.accountNumber,
          isError: file.accountNumber !== ledger.accountNumber,
        },
        {
          key: t("bulk_meter_upload.table.extraction_point_name"),
          value: ledger.extractionPointName,
          isError: file.extractionPointName !== ledger.extractionPointName,
        },
        {
          key: t("bulk_meter_upload.table.meter_serial_no"),
          value: ledger.serialNo,
          isError: isErrSerialNo,
        },
        {
          key: t("bulk_meter_upload.modal.last_meter_read"),
          value: convertLiterToML(ledger.lastReading),
          isError: isDuplicate,
        },
        {
          key: t("bulk_meter_upload.modal.ledger_date"),
          value: ledger.readAt ? formatDateTime(new Date(ledger.readAt)) : "-",
          isError: isDuplicate,
        },
      ],
    },
  ];

  return (
    <Modal open={true}>
      <header className="p-6 flex gap-2 border-b items-center">
        <WaterLedgerLogo />
        <h3 className="text-xl">{t("audit_trail.modal_title")}</h3>

        <div className="flex gap-2 ml-auto">
          <Tag
            status={
              isSuccess || isBackRead || isClickOver ? "success" : "warning"
            }
          >
            {status}
          </Tag>
        </div>
      </header>

      <div className="p-6 border-b space-y-4">
        {isSuccess ? <p>{t("bulk_meter_upload.modal.success")}</p> : null}
        {isMismatch ? (
          <>
            <p>{t("bulk_meter_upload.modal.mismatch")}:</p>
            <ul className="list-inside list-disc">
              {file.accountNumber !== ledger.accountNumber ||
              file.extractionPointName !== ledger.extractionPointName ? (
                <li>
                  {t("bulk_meter_upload.modal.extraction_point_not_match")}
                </li>
              ) : null}

              {!file.serialNo && !ledger.serialNo ? null : file.serialNo !==
                ledger.serialNo ? (
                <li>{t("bulk_meter_upload.modal.serial_no_not_match")}</li>
              ) : null}
            </ul>
          </>
        ) : null}
        {isMismatchGroupSequence ? (
          <>
            <p>{t("bulk_meter_upload.modal.mismatch")}:</p>
            <ul className="list-inside list-disc">
              <li>
                {t("bulk_meter_upload.info.mismatch.mismatch_group_seq_warn")}
              </li>
            </ul>
          </>
        ) : null}
        {isDuplicate ? <p>{t("bulk_meter_upload.modal.duplicate")}</p> : null}
        {isWarning || isBackRead || isClickOver ? (
          <>
            <p>{t("bulk_meter_upload.modal.warning")}</p>
            <p>{t("bulk_meter_upload.modal.tag")}</p>
          </>
        ) : null}
        {isNoExtractionRight ? (
          <p>{t("bulk_meter_upload.modal.no_extraction_right")}</p>
        ) : null}
        {isNoExtractionPoint ? (
          <p>{t("bulk_meter_upload.modal.no_extraction_point")}</p>
        ) : null}
      </div>

      <div className="flex p-6 border-b gap-4">
        {details.map((i) => {
          return (
            <div className="w-1/2" key={i.title}>
              <h4 className="font-semibold py-2 px-4 bg-gray-200 mb-4">
                {i.title}
              </h4>
              <ul className="text-gray-500 text-sm space-y-1">
                {i.body.map((j) => {
                  return (
                    <li
                      key={`${i.title}--${j.key}`}
                      className={classNames({
                        "text-red-600": j.isError,
                        "text-yellow-400": j.isWarn,
                      })}
                    >
                      <strong>{j.key}</strong> {j.value}
                    </li>
                  );
                })}
              </ul>
            </div>
          );
        })}
      </div>

      <footer className="px-6 py-4 flex justify-end gap-2">
        {(isWarning || isBackRead || isClickOver) && (
          <>
            {!isBackRead && (
              <button
                type="button"
                className="btn-default text-sm rounded"
                onClick={onClickBackRead}
              >
                {t("bulk_meter_upload.table.back_read")}
              </button>
            )}
            {!isClickOver && (
              <button
                type="button"
                className="btn-default text-sm rounded"
                onClick={onClickClickOver}
              >
                {t("bulk_meter_upload.table.click_over")}
              </button>
            )}
          </>
        )}
        <button
          type="button"
          className="btn-default text-sm rounded"
          onClick={onClose}
        >
          {t("common.close")}
        </button>
      </footer>
    </Modal>
  );
};

export default TrialRunDetailsModal;
