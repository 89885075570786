import { useTranslation } from "react-i18next";

import SelectPrimaryUser from "./SelectPrimaryUser";
import Heading from "@components/layout/Heading";
import StepButton from "@components/shared/StepButton";
import StepInfo from "@components/shared/StepInfo";
import WorkflowPanels from "@components/form/WorkflowPanels";
import ConfirmSubscriber from "@components/form/subscriber/ConfirmSubscriber";
import InfoPanel from "@components/form/InfoPanel";
import SubscriberForm from "@components/form/subscriber/SubscriberForm";
import UploadEvidencesWorkflowForm from "@components/form/UploadEvidenceWorkflowForm";
import {
  SubscriberCreateOrEditProvider,
  useSubscriberCreateOrEditContext,
} from "@context/SubscriberCreateOrEditContext";
import {
  EvidenceProvider,
  useEvidenceContext,
} from "@context/shared/EvidenceContext";
import { useUpdateSubscriber } from "@hooks/mutation/useUpdateSubscriber";
import { StepProvider, useStepContext } from "@context/shared/StepContext";

const UpdateSubscriberWorkflow = () => {
  return (
    <StepProvider maxStep={4}>
      <EvidenceProvider>
        <SubscriberCreateOrEditProvider>
          <Consumer />
        </SubscriberCreateOrEditProvider>
      </EvidenceProvider>
    </StepProvider>
  );
};

const Consumer = () => {
  const { t } = useTranslation();
  const { stepHelpers, currentStep } = useStepContext();
  const {
    subscriber,
    setSubscriber,
    getSubscriberInfo,
    getPrimaryUserInfo,
    isSubscriberComplete,
    setIsSubcriberComplete,
    workflowInstance,
    setWorkflowInstance,
    cancel,
    primaryUser,
    parseAddress,
  } = useSubscriberCreateOrEditContext();
  const { getEvidencesInfo, uploadEvidences } = useEvidenceContext();

  const { mutateAsync: updateSubscriberMutation } = useUpdateSubscriber();

  const steps = [
    {
      label: (
        <StepButton
          index={0}
          currentStep={currentStep}
          isOnChain={isSubscriberComplete}
          info={<StepInfo data={getSubscriberInfo().body} />}
        >
          {t("subscriber.create.enter_subscriber_details")}
        </StepButton>
      ),
      panel: (
        <div className="flex flex-col grow p-6">
          <Heading light className="mb-8">
            {t("subscriber.create.enter_subscriber_details")}
          </Heading>
          {subscriber.id ? (
            <SubscriberForm
              defaultValues={subscriber}
              onSubmit={(data) => {
                setSubscriber((prev) => ({
                  ...prev,
                  ...data,
                }));
                stepHelpers.goToNextStep();
              }}
              onCancel={cancel}
              submitText={t("common.next_step")}
              isUpdating
            />
          ) : null}
        </div>
      ),
      infoPanel: (
        <InfoPanel>
          <div className="space-y-6">
            <p>{t("subscriber.create.info1")}</p>
            <p>{t("subscriber.create.info2")}</p>
            <p>{t("subscriber.create.info3")}</p>
            <p>{t(`subscriber.edit.info1`)}</p>
          </div>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={1}
          currentStep={currentStep}
          isOnChain={isSubscriberComplete}
          info={<StepInfo data={getPrimaryUserInfo().body} />}
        >
          {t("subscriber.create.select_primary_user")}
        </StepButton>
      ),
      panel: <SelectPrimaryUser />,

      infoPanel: (
        <InfoPanel
          actions={[
            {
              label: t("user.create.title"),
              action: () => {
                window.open(
                  `/polestar/subscribers/${subscriber.id}/users/create`,
                  "_blank"
                );
              },
            },
          ]}
        >
          <div className="space-y-6">
            <p>{t("subscriber.create.info_select_primary_user")}</p>
          </div>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={2}
          currentStep={currentStep}
          isOnChain={isSubscriberComplete}
          info={<StepInfo data={getEvidencesInfo().body} />}
        >
          {t("common.enter_evidence")}
        </StepButton>
      ),
      panel: (
        <UploadEvidencesWorkflowForm
          referenceTableId={subscriber.id}
          referenceTable="subscribers"
          onNext={() => {
            stepHelpers.goToNextStep();
          }}
          onPrevious={stepHelpers.goToPrevStep}
          onCancel={cancel}
          customDropboxTitle={t("subscriber.evidence_data.title") as string}
          newUploadDescription={
            t("subscriber.evidence_data.description", {
              name: subscriber?.name,
            }) as string
          }
          selectableForArchiving={false}
        />
      ),
      infoPanel: (
        <InfoPanel>
          <div className="space-y-6">
            <p>{t("subscriber.create.info_select_files")}</p>
            <p>{t("evidence.supported")}</p>
          </div>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={3}
          currentStep={currentStep}
          isOnChain={isSubscriberComplete}
          forceSuccess={isSubscriberComplete}
        >
          {t("subscriber.create.ledger")}
        </StepButton>
      ),
      panel: (
        <ConfirmSubscriber
          isComplete={isSubscriberComplete}
          workflowInstance={workflowInstance}
          data={[getSubscriberInfo(), getPrimaryUserInfo(), getEvidencesInfo()]}
          onPrevious={stepHelpers.goToPrevStep}
          onEdit={stepHelpers.setStep}
          onCancel={cancel}
          onSubmit={async () => {
            const [res, workflowInstance] = await updateSubscriberMutation({
              subscriberId: subscriber.id,
              level1ResourceId: subscriber.level1Resource.id,
              name: subscriber.name,
              abn: subscriber.abn,
              accountNumber: subscriber.accountNumber,
              isActive: subscriber.isActive,
              address: parseAddress(subscriber.address),
              primaryUserId: primaryUser.id,
            });
            await uploadEvidences({
              description: t("subscriber.evidence_data.description", {
                name: res.name,
              }),
              referenceId: res.id,
              referenceTable: "subscribers",
            });
            setIsSubcriberComplete(true);
            setWorkflowInstance(workflowInstance);
          }}
        />
      ),

      infoPanel: (
        <InfoPanel
          successes={
            isSubscriberComplete
              ? [
                  t("subscriber.edit.info3", {
                    subscriberName: subscriber?.name,
                  }),
                ]
              : [t("subscriber.edit.info2")]
          }
        >
          {isSubscriberComplete ? (
            <p>{t("subscriber.create.info11")}</p>
          ) : (
            <ul className="space-y-4">
              <li>{t("common.info.verify")}</li>
              <li>
                <strong>{t("subscriber.create.subscriber_details")}</strong>:{" "}
                {t("subscriber.verify.details")}
              </li>
              <li>
                <strong>{t("user.create_primary_user.details")}</strong>:{" "}
                {t("subscriber.verify.primary_user")}
              </li>
              <li>
                <strong>{t("evidence.supporting")}:</strong>{" "}
                {t("evidence.info.verify")}
              </li>
              <li>{t("common.info.changes")}</li>
            </ul>
          )}
        </InfoPanel>
      ),
    },
  ];

  return <WorkflowPanels selectedStep={currentStep} steps={steps} />;
};

export default UpdateSubscriberWorkflow;
