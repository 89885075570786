import React from "react";

const ArrowRightIcon: React.FunctionComponent = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="20"
      fill="none"
      viewBox="0 0 19 20"
    >
      <path
        fill="#004A88"
        d="M12.413 15.863a.89.89 0 00.965-.17l5.344-5.047A.884.884 0 0019 10c0-.245-.1-.479-.278-.646l-5.344-5.047a.89.89 0 00-1.503.646V8.22H1.187C.531 8.219 0 8.749 0 9.406v1.188c0 .657.53 1.187 1.188 1.187h10.687v3.266c0 .356.211.675.538.816z"
      ></path>
    </svg>
  );
};

export default ArrowRightIcon;
