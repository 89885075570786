import { useState } from "react";
import Select from "react-select";
import { useNavigate, useParams, Link } from "react-router-dom";
import { startCase } from "lodash";
import { useTranslation } from "react-i18next";

import Table from "@components/layout/Table";
import Label from "@components/form/Label";
import SearchInput from "@components/form/SearchInput";
import Tag from "@components/shared/Tag";
import { useAppContext } from "@context/AppContext";
import { useAllLevel0Resources } from "@hooks/query/useAllLevel0Resources";
import { useAllMeters } from "@hooks/query/useAllMeters";
import { formatVolume } from "@utils/formatVolume";

type Level1WRSMeterListProps = {
  level1Resource: any;
};

type MeterStatus = "active" | "inactive" | "decommission";

type Filter = {
  meterSerialNo: string;
  level0wrsId: string;
  extractionPointName: string;
  status: MeterStatus;
};

const Level1WRSMeterList: React.FunctionComponent<Level1WRSMeterListProps> = ({
  level1Resource,
}) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const { checkPermissions } = useAppContext();
  const [filter, setFilter] = useState<Partial<Filter>>({});

  const { data: meters = [], isLoading } = useAllMeters({
    params: {
      level1ResourceId: level1Resource?.id,
    },
    options: {
      enabled: Boolean(level1Resource?.id),
      refetchOnWindowFocus: false,
    },
  });
  const { data: level0Resources } = useAllLevel0Resources({
    refetchOnWindowFocus: false,
  });

  const handleFilterChange = <TField extends keyof Filter>(
    field: TField,
    value?: Filter[TField]
  ) => {
    setFilter({
      ...filter,
      [field]: value,
    });
  };

  const getLevel0WRSOptions = (level0Resources: any[]) => {
    return level0Resources?.map((level0Resource: any) => ({
      label: level0Resource.identifier,
      value: level0Resource.id,
    }));
  };

  const activeOptions: { label: string; value: MeterStatus }[] = [
    { label: t("common.active"), value: "active" },
    { label: t("common.inactive"), value: "inactive" },
    { label: t("common.decommission"), value: "decommission" },
  ];

  const meterData = meters
    ?.filter(
      (row: any) =>
        !filter?.meterSerialNo?.length ||
        row.serialNo
          ?.toString()
          .toLowerCase()
          .includes(filter?.meterSerialNo.toString().toLowerCase())
    )
    ?.filter(
      (row: { extractionPoint: any }) =>
        !filter?.extractionPointName?.length ||
        row.extractionPoint?.name
          .toString()
          .toLowerCase()
          .includes(filter?.extractionPointName.toString().toLowerCase())
    )
    ?.filter((row: any) => {
      return (
        !filter?.level0wrsId?.length ||
        row.extractionPoint?.level0ResourceId === filter.level0wrsId
      );
    })
    ?.filter((row: any) => {
      if (!filter.status) {
        return true;
      }
      if (filter.status === "decommission") {
        return Boolean(row.deletedAt);
      }
      return filter.status === "active" ? row.isActive : !row.isActive;
    })
    ?.map((meter: any) => {
      const options = [
        {
          label: t("common.view"),
          value: `/polestar/subscribers/${meter?.subscriberId}?level0ResourceId=${meter?.extractionPoint?.level0ResourceId}&extractionPointId=${meter?.extractionPointId}`,
        },
        {
          label: t("meter.edit.title"),
          value: `/polestar/meters/${meter?.id}/edit`,
          disabled: !checkPermissions(["UpdateMeters"]) || meter.deletedAt,
        },
        {
          label: meter
            ? t("declaration.declare_meter_reading")
            : t("declaration.unmetered_usage.title"),
          value: `/polestar/level1wrs/${level1Resource?.id}/declarations/create?subscriberId=${meter?.subscriberId}&extractionPointId=${meter?.extractionPointId}`,
          state: { from: window.location.pathname + window.location.hash },
          disabled:
            !checkPermissions(["CreateDeclarations"]) || !meter?.isActive,
        },
        {
          label: t("meter.replace_capsule.title"),
          value: `/polestar/level1wrs/${level1Resource?.id}/meters/${meter.id}/replace_capsule?subscriberId=${meter?.subscriberId}&extractionPointId=${meter?.extractionPointId}`,
          disabled:
            !checkPermissions(["CreateDeclarations"]) || !meter?.isActive,
        },
        {
          label: t("meter.replace"),
          value: "",
          disabled: true,
        },
        {
          label: t("meter.deactivate"),
          value: `/polestar/subscribers/${meter?.subscriberId}/level0_resources/${meter?.extractionPoint?.level0ResourceId}/meters/decommission?subscriberId=${meter?.subscriberId}&extractionPointId=${meter?.extractionPointId}`,
          disabled:
            !checkPermissions([
              "UpdateExtractionPoint",
              "UpdateMeters",
              "CreateDeclarations",
            ]) || !meter?.isActive,
        },
        {
          label: t("meter.update_threshold"),
          value: `/polestar/meters/update?meterName=${meter?.serialNo}&meterId=${meter?.id}`,
          disabled:
            !meter || !checkPermissions(["ViewThreshold", "UpdateThreshold"]),
        },
      ].filter((item) => (item.disabled ? false : item));
      return {
        ...meter,
        meterName: (
          <Link
            to={`/polestar/subscribers/${meter.subscriberId}?level0ResourceId=${meter.extractionPoint?.level0ResourceId}&extractionPointId=${meter.extractionPointId}`}
            className="text-sm text-primary-2 underline"
          >
            {meter?.serialNo}
          </Link>
        ),
        extractionPointName: (
          <Link
            to={`/polestar/subscribers/${meter.subscriberId}?level0ResourceId=${meter.extractionPoint?.level0ResourceId}&extractionPointId=${meter.extractionPointId}`}
            className="text-sm text-primary-2 underline"
          >
            {meter.extractionPoint?.name}
          </Link>
        ),
        level0wrs: meter.extractionPoint?.level0WRS?.identifier,
        status: meter.deletedAt ? (
          <Tag status="error">{t("common.decommission")}</Tag>
        ) : (
          <Tag status={meter.isActive ? "success" : "error"}>
            {meter.isActive ? t("common.active") : t("common.inactive")}
          </Tag>
        ),
        source: startCase(meter.extractionPoint?.source),
        meterThreshold: meter?.threshold && formatVolume(+meter?.threshold, ""),
        action: (
          <Select
            placeholder={t("common.actions")}
            options={options}
            onChange={(e) => {
              if (e?.value) {
                navigate(e.value, { state: e?.state });
              }
            }}
            menuPortalTarget={document.body}
            closeMenuOnScroll={() => true}
            isSearchable={false}
          />
        ),
      };
    });

  const tableFields = [
    {
      title: t("meter.serial_no"),
      name: "meterName",
    },
    {
      title: t("extraction_point.name"),
      name: "extractionPointName",
    },
    {
      title: t("common.level0wrs"),
      name: "level0wrs",
    },
    {
      title: t("common.status"),
      name: "status",
    },
    ...(checkPermissions(["ViewThreshold"])
      ? [
          {
            title: t("meter.threshold"),
            name: "meterThreshold",
          },
        ]
      : []),
    {
      title: t("common.action"),
      name: "action",
    },
  ];

  return (
    <>
      <header className="relative z-20">
        <div>
          <form className="flex flex-col gap-4 items-start xl:flex-row xl:items-end xl:justify-between">
            <div className="flex-1 w-full">
              <Label htmlFor="meterSerialNo">
                {t("meter.filter_serial_no")}
              </Label>
              <SearchInput
                id="meterSerialNo"
                placeholder={t("meter.serial_no") as string}
                onChange={(e) =>
                  handleFilterChange("meterSerialNo", e.target.value)
                }
              />
            </div>
            <div className="flex-1 w-full">
              <Label htmlFor="level0wrs">{t("common.level0wrs")}</Label>
              <Select
                options={getLevel0WRSOptions(level0Resources)}
                value={getLevel0WRSOptions(level0Resources)?.find(
                  (s) => s.value === filter.level0wrsId
                )}
                onChange={(e) => {
                  handleFilterChange("level0wrsId", e?.value);
                }}
                inputId="level0wrs"
                openMenuOnFocus
                isClearable
              />
            </div>
            <div className="flex-1 w-full">
              <Label htmlFor="extractionPointName">
                {t("extraction_point.filter_name")}
              </Label>
              <SearchInput
                id="extractionPointName"
                placeholder={t("extraction_point.name") as string}
                onChange={(e) =>
                  handleFilterChange("extractionPointName", e.target.value)
                }
                value={filter.extractionPointName}
              />
            </div>
            <div className="flex-1 w-full">
              <Label htmlFor="status">
                {t("subscriber.filter_subscriber_status")}
              </Label>
              <Select
                options={activeOptions}
                value={activeOptions.find(
                  (activeOption) => activeOption.value === filter.status
                )}
                onChange={(e) => handleFilterChange("status", e?.value)}
                inputId="status"
                openMenuOnFocus
                isClearable
              />
            </div>

            <Link
              className="btn-secondary text-sm rounded"
              to={`/polestar/meters/create?level1ResourceId=${id}`}
              state={{ from: `/polestar/level1wrs/${id}#5` }}
            >
              {t("meter.new_meter")}
            </Link>
          </form>
        </div>
      </header>
      <Table
        containerClassName="rounded-none md:rounded-none text-sm"
        tableHeaderClassName="relative z-10"
        fields={tableFields}
        data={meterData}
        stickyHeader
        loading={isLoading}
      />
    </>
  );
};

export default Level1WRSMeterList;
