import React from "react";

const ArrowDown = () => {
  return (
    <svg
      width="12"
      height="14"
      viewBox="0 0 9 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.85199 4.45273H7.90277C7.84886 4.45273 7.7973 4.47617 7.76097 4.51719L5.19574 7.47383V0.875C5.19574 0.823437 5.15355 0.78125 5.10199 0.78125H4.39886C4.3473 0.78125 4.30511 0.823437 4.30511 0.875V7.47383L1.73988 4.51719C1.70472 4.47617 1.65316 4.45273 1.59808 4.45273H0.648864C0.569176 4.45273 0.525817 4.54766 0.578551 4.60742L4.468 9.08984C4.50316 9.1304 4.54663 9.16293 4.59546 9.18523C4.64429 9.20752 4.69734 9.21906 4.75101 9.21906C4.80469 9.21906 4.85774 9.20752 4.90657 9.18523C4.9554 9.16293 4.99886 9.1304 5.03402 9.08984L8.9223 4.60742C8.97504 4.54648 8.93168 4.45273 8.85199 4.45273Z"
        fill="#CF1322"
      />
    </svg>
  );
};

export default ArrowDown;
