import api from "./api";

type CreateDistributionLossPayload = {
  volume: number;
  readAt: string;
  meterId: string;
  extractionPointName: string;
};

export const createDistributionLoss = async (
  payload: CreateDistributionLossPayload
) => {
  const { data } = await api.post("/admin/distributionLosses", payload);

  return [data.distributionLoss, data.workflowInstance];
};

export const getDistributionLosses = async (params: {
  meterId?: string;
  periodStart?: Date;
  periodEnd?: Date;
  level1ResourceId?: string;
}) => {
  const { data } = await api.get("/admin/distributionLosses", {
    params,
  });

  return data.distributionLosses;
};
