import Heading1 from "@components/layout/Heading";
import ConfirmModal from "@components/shared/ConfirmModal";
import DropzoneBox from "@components/shared/DropzoneBox";
import { useAdminTransferContext } from "@context/AdminTransferContext";
import { useAppContext } from "@context/AppContext";
import { useCreateEvidence } from "@hooks/mutation/useCreateEvidence";
import { useCreateAdministrativeTransfer } from "@hooks/mutation/zenith/useCreateAdministrativeTransfer";
import { useCreateMessage } from "@hooks/mutation/zenith/useCreateMessage";
import { toastError, toastSuccess } from "@utils/toast";
import { FunctionComponent, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

type WorkflowCreateAdminApprovalStep4Props = {
  onSubmit: () => void;
  onCancel: () => void;
};

const WorkflowCreateAdminApprovalStep4: FunctionComponent<
  WorkflowCreateAdminApprovalStep4Props
> = ({ onSubmit, onCancel }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const {
    details,
    message,
    setSupportingEvidence,
    supportingEvidence,
    setWorkflowInstance,
  } = useAdminTransferContext();
  const {
    loggedInInfo: { userDetails },
  } = useAppContext();

  const accept = {
    "image/jpeg": [".jpeg", ".jpg"],
    "image/png": [".png"],
    "application/pdf": [".pdf"],
    "application/msword": [".doc"],
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [
      ".docx",
    ],
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
      ".xlsx",
    ],
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (files: any) => setSupportingEvidence(files?.[0]),
    accept: accept,
  });

  const {
    mutateAsync: createAdministrativeTransferMutation,
    isLoading: isCreateAdministrativeTransferLoading,
  } = useCreateAdministrativeTransfer();
  const {
    mutateAsync: createMessageMutation,
    isLoading: isCreateMessageLoading,
  } = useCreateMessage();
  const {
    mutateAsync: createEvidenceMutation,
    isLoading: isCreateEvidenceLoading,
  } = useCreateEvidence();

  const handleSubmit = (e: any) => {
    e.preventDefault();

    setShowConfirmModal(true);
  };

  const handleConfirm = async () => {
    try {
      const [, workflowInstance] = await createAdministrativeTransferMutation({
        subscriberId: details?.subscriber?.id,
        extractionPointId: details?.extractionPoint?.id,
        extractionRightId: details?.extractionRight?.id,
      });

      const response = await createMessageMutation({
        notificationTypeId: message.messageTemplate?.notificationTypeId,
        subject: message.subject,
        body: message.body,
      });

      if (supportingEvidence) {
        await uploadFile(response, supportingEvidence);
      }
      toastSuccess(t("approval.admin_transfer.create.toast.success"));

      setWorkflowInstance(workflowInstance);

      onSubmit();
    } catch (error) {
      toastError(t("approval.admin_transfer.create.toast.fail"));
    }
    setShowConfirmModal(false);
  };

  const uploadFile = async (message: any, file: File) => {
    await createEvidenceMutation({
      title: t("notification.evidence_data.title"),
      referenceId: message?.id,
      referenceTable: "messages",
      description: t("notificatiojn.evidence_saved", {
        senderName: userDetails?.name,
      }),
      isPublic: false,
      isEncrypted: true,
      attachment: file,
    });
  };

  return (
    <form className="flex flex-col grow" onSubmit={handleSubmit}>
      <div className="flex flex-col grow gap-4">
        <div className="w-full">
          <Heading1 className="text-xl leading-6" light>
            {t("approval.admin_transfer.upload_file")}
          </Heading1>
        </div>
        <div className="w-full">
          <p className="text-primary-2">
            {t("approval.admin_transfer.upload_heading")}
          </p>
          <DropzoneBox
            getRootProps={getRootProps}
            getInputProps={getInputProps}
            files={supportingEvidence ? [supportingEvidence] : []}
            multiple={false}
            onDelete={() => {
              setSupportingEvidence(null);
            }}
            subtitle={t("approval.admin_transfer.upload_subtitle") as string}
            acceptedFileTypes={accept}
          />
        </div>
      </div>
      <footer className="p-4 flex gap-2">
        <button
          type="button"
          className="btn-outline-primary text-sm font-semibold"
          onClick={onCancel}
        >
          {t("common.prev_step")}
        </button>
        <button type="submit" className="btn-primary text-sm font-semibold">
          {t("common.next_step")}
        </button>
        <button
          type="button"
          className="btn-default text-sm font-semibold"
          onClick={() => navigate("/zenith/workflows")}
        >
          {t("common.cancel")}
        </button>
      </footer>
      <ConfirmModal
        open={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
        onConfirm={handleConfirm}
        isSubmitting={
          isCreateAdministrativeTransferLoading ||
          isCreateEvidenceLoading ||
          isCreateMessageLoading
        }
      >
        {t("approval.admin_transfer.modal.request_title")}
      </ConfirmModal>
    </form>
  );
};

export default WorkflowCreateAdminApprovalStep4;
