import React from "react";
import { Controller, useForm } from "react-hook-form";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

import Heading from "@components/layout/Heading";
import Label from "@components/form/Label";
import Legend from "@components/form/Legend";
import TextInput from "@components/form/TextInput";
import DecimalInput from "@components/form/DecimalInput";
import EditButton from "@components/shared/EditButton";
import { formatVolume } from "@utils/formatVolume";
import { convertMLToLiter } from "@utils/convertUnits";
import ENV from "@config/env";

const schema = z.object({
  serialNo: z.string(),
  readAt: z.string().optional(),
  type: z.union([z.literal("estimated"), z.literal("actual")]).optional(),
  reading: z.string(),
});

export type MeterDeclarationValues = z.infer<typeof schema>;

const initialFormValues: MeterDeclarationValues = {
  serialNo: "",
  readAt: "",
  type: "estimated",
  reading: "0.000",
};

type Variant = "meter_only_reading" | "initial" | "reading" | "final";

const titleText: Record<Variant, string> = {
  meter_only_reading: t("declaration.declare_initial_meter_reading"),
  initial: t("declaration.declare_initial_meter_reading"),
  final: t("meter.decommission.declare_final_read"),
  reading: t("declaration.enter_read"),
};

const enterReadingText: Record<Variant, string> = {
  meter_only_reading: t("declaration.enter_initial_read"),
  initial: t("declaration.enter_initial_read"),
  final: t("declaration.enter_final_read"),
  reading: t("declaration.enter_read"),
};

type Props = {
  onSubmit: (data: any) => void;
  onCancel: () => void;
  variant?: Variant;
  defaultValues?: Partial<MeterDeclarationValues>;
  lastReading?: number;
};

const MeterDeclarationForm: React.FunctionComponent<Props> = ({
  variant = "reading",
  onSubmit,
  onCancel,
  defaultValues,
  lastReading,
}) => {
  const { t } = useTranslation();
  const [threshold] = React.useState(() => +ENV.DEFAULT_METER_THRESHOLD);
  const [, setShowEditThreshold] = React.useState(false);

  const isInitialReading = variant === "initial";
  const isMeterOnlyReading = variant === "meter_only_reading";

  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(
      schema.superRefine(({ readAt, type }, ctx) => {
        if (isMeterOnlyReading) return;
        if (!readAt) {
          ctx.addIssue({
            message: t("declaration.read_at_required") as string,
            code: z.ZodIssueCode.custom,
            path: ["readAt"],
          });
        }
        if (!type) {
          ctx.addIssue({
            message: t("declaration.type_required") as string,
            code: z.ZodIssueCode.custom,
            path: ["type"],
          });
        }
      })
    ),
    defaultValues: Object.assign({}, initialFormValues, defaultValues),
  });

  const getCurrentUsage = (newReading: number, lastReading = 0) => {
    if (isInitialReading || isMeterOnlyReading || newReading < lastReading) {
      return 0;
    }
    return newReading - lastReading;
  };

  const currentUsage = getCurrentUsage(
    convertMLToLiter(+watch("reading")),
    lastReading
  );

  return (
    <form
      className="flex flex-col grow p-6 space-y-8"
      onSubmit={handleSubmit((data) => {
        onSubmit({
          ...data,
          isEstimated: data?.type === "estimated",
        });
      })}
    >
      <Heading light>{titleText[variant]}</Heading>

      <fieldset className="space-y-4">
        <Legend>{t("declaration.meter_read_details")}</Legend>
        <div className="max-w-sm">
          <Label htmlFor="serialNo">{t("declaration.form.meter_id")}</Label>
          <TextInput
            {...register("serialNo")}
            id="serialNo"
            errorMessage={errors?.serialNo?.message as string}
            disabled
          />
        </div>
        {!isMeterOnlyReading && (
          <div className="max-w-sm">
            <Label htmlFor="readAt">
              {t("declaration.form.timestamp_read")}
            </Label>
            <TextInput
              {...register("readAt")}
              type="datetime-local"
              id="readAt"
              errorMessage={errors?.readAt?.message as string}
            />
          </div>
        )}
      </fieldset>

      <fieldset className="space-y-4">
        <Legend>{enterReadingText[variant]}</Legend>
        {!isMeterOnlyReading && (
          <div>
            <p className="mb-2">{t("declaration.type_of_meter_reading")}</p>
            <div className="flex gap-6">
              <Label className="flex items-center gap-2 cursor-pointer">
                <input type="radio" value="estimated" {...register("type")} />
                {t("declaration.estimated")}
              </Label>
              <Label className="flex items-center gap-2 cursor-pointer">
                <input type="radio" value="actual" {...register("type")} />
                {t("declaration.actual")}
              </Label>
            </div>
          </div>
        )}

        <div className="flex gap-6 items-start justify-start text-sm">
          <div>
            <p>{t("declaration.form.last_read")}</p>
            <p className="mt-1 font-bold text-2xl">
              {lastReading ? formatVolume(lastReading) : "-"}
            </p>
          </div>

          <div>
            <Label>{enterReadingText[variant]}</Label>
            <Controller
              name="reading"
              control={control}
              render={({ field: { onChange, value, name }, fieldState }) => (
                <DecimalInput
                  name={name}
                  value={value}
                  onChange={onChange}
                  errorMessage={fieldState.error?.message}
                />
              )}
            />
          </div>
          <div>
            <p>{t("declaration.form.usage_calculation")}</p>
            <p className="mt-1 font-bold text-2xl">
              {formatVolume(currentUsage)}
            </p>
          </div>
        </div>
      </fieldset>

      {false ? (
        <fieldset className="space-y-4 text-sm">
          <Legend>{t("declaration.confirm_threshold")}</Legend>
          <div className="space-y-2">
            <p>{t("declaration.form.current_threshold")}</p>
            <p className="text-lg">{formatVolume(threshold)}</p>
            <EditButton onClick={() => setShowEditThreshold(true)} />
          </div>
        </fieldset>
      ) : null}

      <div className="grow" />

      <footer className="flex gap-4 p-6 pb-0 -mx-6 border-t border-gray-200">
        <button type="submit" className="btn-primary">
          {t("common.next_step")}
        </button>
        <button
          type="button"
          className="btn-outline-primary"
          onClick={onCancel}
        >
          {t("common.cancel")}
        </button>
      </footer>
    </form>
  );
};

export default MeterDeclarationForm;
