import React from "react";
import { useTranslation } from "react-i18next";

import Table from "@components/layout/Table";
import Label from "@components/form/Label";
import SearchInput from "@components/form/SearchInput";
import Modal from "@components/layout/Modal";
import TextInput from "@components/form/TextInput";
import Loading from "@components/shared/Loading";
import ArrowRightCalendarIcon from "@components/icons/ArrowRightCalendarIcon";
import PreviewEvidenceImage from "@components/shared/PreviewEvidenceImage";
import { useAllEvidences } from "@hooks/query/useAllEvidences";
import { formatDateInput } from "@utils/formatDate";
import { formatDateTime } from "@utils/formatDateTime";
import { isImage } from "@utils/isImage";

import ENV from "@config/env";

type Props = {
  level1Resource?: any;
};

const EvidenceTable: React.FunctionComponent<Props> = () => {
  const { t } = useTranslation();
  const [filter, setFilter] = React.useState<any>({});
  const [selectData, setSelectData] = React.useState<any>();

  const handleFilterChange = (field: string, value: any) => {
    setFilter({
      ...filter,
      [field]: value,
    });
  };

  const isPdf = selectData?.fileName?.toLowerCase().endsWith(".pdf");

  const {
    data = [],
    isLoading,
    refetch,
  } = useAllEvidences({
    params: filter,
  });

  React.useEffect(() => {
    refetch();
  }, [
    filter.level1ResourceId,
    filter.fileName,
    filter.startDate,
    filter.endDate,
    refetch,
  ]);

  if (isLoading) {
    return (
      <div className="py-20">
        <Loading />
      </div>
    );
  }

  const tableFields = [
    {
      title: t("evidence.uploaded_at"),
      name: "createdAt",
    },
    {
      title: t("evidence.file.name"),
      name: "fileName",
    },
    {
      title: t("common.description"),
      name: "description",
    },
    {
      title: t("common.actions"),
      name: "actions",
    },
  ];

  const tableData = data.map((item: any) => {
    return {
      ...item,
      createdAt: formatDateTime(new Date(item.createdAt)),
      actions: (
        <button className="btn-primary" onClick={() => setSelectData(item)}>
          {t("common.view")}
        </button>
      ),
    };
  });

  return (
    <>
      <header className="flex items-end gap-4">
        <div>
          <Label htmlFor="fileName">{t("evidence.file.name")}</Label>
          <SearchInput
            id="fileName"
            value={filter?.fileName ?? ""}
            onChange={(e) => handleFilterChange("fileName", e.target.value)}
          />
        </div>
        <div>
          <Label htmlFor="startDate">{t("reporting.filter_dates")}</Label>
          <div className="flex items-center gap-1">
            <TextInput
              type="date"
              id="startDate"
              max={filter.endDate ? formatDateInput(filter.endDate) : ""}
              placeholder={t("common.date_range.from") as string}
              value={filter.startDate ? formatDateInput(filter.startDate) : ""}
              onChange={(e) => {
                handleFilterChange(
                  "startDate",
                  e.target?.value ? new Date(`${e.target.value}T00:00:00`) : ""
                );
              }}
            />
            <ArrowRightCalendarIcon className="w-10 h-10" />
            <TextInput
              type="date"
              min={filter.startDate ? formatDateInput(filter.startDate) : ""}
              placeholder={t("common.date_range.to") as string}
              value={filter.endDate ? formatDateInput(filter.endDate) : ""}
              onChange={(e) => {
                handleFilterChange(
                  "endDate",
                  e.target?.value ? new Date(`${e.target.value}T23:59:59`) : ""
                );
              }}
            />
          </div>
        </div>
      </header>

      <Table fields={tableFields} data={tableData} stickyHeader />

      <Modal open={selectData}>
        <header className="border-b p-4">
          <h3 className="text-lg font-bold leading-6">
            {t("evidence.viewer")}
          </h3>
        </header>

        {selectData?.fileName && isImage(selectData.fileName) ? (
          <div className="p-4 pb-0">
            <PreviewEvidenceImage evidence={selectData} />
          </div>
        ) : null}

        <div className="px-4 py-6">
          <div className="border rounded-md p-4 space-y-2">
            <h3 className="text-lg font-bold leading-6">
              {t("evidence.information")}
            </h3>

            <ul className="space-y-2 text-sm text-gray-600">
              <li>
                <strong>{t("evidence.title")}:</strong> {selectData?.title}
              </li>
              <li>
                <strong>{t("common.description")}:</strong>{" "}
                {selectData?.description}
              </li>
              <li>
                <strong>{t("evidence.file.name")}:</strong>{" "}
                {selectData?.fileName}
              </li>
              <li>
                <strong>{t("evidence.file.hash")}:</strong>{" "}
                {selectData?.contentIdentifier}
              </li>
              <li>
                <strong>{t("evidence.file.loaded")}:</strong>
                {selectData?.createdAt
                  ? formatDateTime(new Date(selectData?.createdAt))
                  : ""}
              </li>
              <li>
                <strong>{t("evidence.loaded_by")}:</strong>{" "}
                {selectData?.user?.name}
              </li>
            </ul>
          </div>
        </div>

        <footer className="flex justify-end gap-4 px-4 py-3 border-t">
          <a
            className="btn-outline-primary rounded text-sm"
            href={`${ENV.API_HOST}/evidences/${selectData?.id}/download`}
            download
          >
            {t("common.download")}
          </a>
          {isPdf && (
            <a
              className="btn-outline-primary rounded text-sm"
              href={`${ENV.API_HOST}/evidences/${selectData?.id}/preview`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("common.preview")}
            </a>
          )}
          <button
            type="button"
            className="btn-primary rounded text-sm"
            onClick={() => setSelectData(null)}
          >
            {t("common.close")}
          </button>
        </footer>
      </Modal>
    </>
  );
};

export default EvidenceTable;
