import React from "react";
import { useQuery } from "@tanstack/react-query";
import { orderBy } from "lodash";
import { useParams, useSearchParams } from "react-router-dom";

import { useSubscriber } from "@hooks/query/useSubscriber";
import { useAllEventTransactions } from "@hooks/query/useAllEventTransactions";
import { getCustomerNotifications } from "@services/notification";

type ContextValue = {
  currentMenu: number;
  setCurrentMenu: React.Dispatch<React.SetStateAction<number>>;
  currentSubMenu: number;
  setCurrentSubMenu: React.Dispatch<React.SetStateAction<number>>;
  currentSubMenuTab: number;
  setCurrentSubMenuTab: React.Dispatch<React.SetStateAction<number>>;
  subscriber: any;
  refetchSubscriber: () => void;
  subscriberNotifications: any;
  zonePriorityBalance: any;
  setZonePriorityBalance: React.Dispatch<React.SetStateAction<any>>;
  zoneExtractionRights: any;
  eventTransactionTableData: any;
  otherUsages: any;
  totalUsage: number;
  rightTotalNominalVolume: number;
  transferVolume: number;
  prevYearFDCarriedOver: number;
  allocatedVolumeObjects: any;
  totalAvailableWater: number;
  rightNames: any;
  filter: any;
  handleFilterChange: (field: string, value: any) => void;
};

const SubscriberIndexContext = React.createContext<ContextValue | undefined>(
  undefined
);

const SubscriberIndexProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [currentMenu, setCurrentMenu] = React.useState(0);
  const [currentSubMenu, setCurrentSubMenu] = React.useState(0);
  const [currentSubMenuTab, setCurrentSubMenuTab] = React.useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const [subscriber, setSubscriber] = React.useState<any>();
  const [zonePriorityBalance, setZonePriorityBalance] = React.useState<any>();
  const [filter, setFilter] = React.useState<{
    extractionPointName?: string;
    extractioNRightName?: string;
  }>({});

  const handleFilterChange = (field: string, value: any) => {
    setFilter({
      ...filter,
      [field]: value,
    });
  };

  React.useEffect(() => {
    const level0ResourceId = searchParams.get("level0ResourceId");
    const extractionPointId = searchParams.get("extractionPointId");
    const extractionRightId = searchParams.get("extractionRightId");

    if (!level0ResourceId) {
      return;
    }

    const zoneIndex = subscriber?.zonePriorityBalances.findIndex(
      ({ zoneId }: any) => level0ResourceId === zoneId
    );

    if (zoneIndex === -1) {
      setCurrentMenu(0);
      setZonePriorityBalance(subscriber?.zonePriorityBalances[currentSubMenu]);
      searchParams.delete("level0ResourceId");
      searchParams.delete("extractionPointId");
      searchParams.delete("extractionRightId");
      setSearchParams(searchParams);
      setCurrentSubMenu(0);
      return;
    }

    setCurrentMenu(1);
    setCurrentSubMenu(zoneIndex);
    setZonePriorityBalance(subscriber?.zonePriorityBalances[zoneIndex]);

    if (extractionRightId) {
      setCurrentSubMenuTab(1);

      const paramExtractionRight = subscriber?.extractionRights?.find(
        ({ id }: any) => id === extractionRightId
      );

      if (paramExtractionRight) {
        handleFilterChange("extractionRightName", paramExtractionRight.name);
      }
      searchParams.delete("extractionRightName");
      setSearchParams(searchParams);
    }

    if (extractionPointId) {
      setCurrentSubMenuTab(2);

      const paramExtractionPoint = subscriber?.extractionPoints?.find(
        ({ id }: any) => id === extractionPointId
      );

      if (paramExtractionPoint) {
        handleFilterChange("extractionPointName", paramExtractionPoint.name);
      }
      searchParams.delete("extractionPointName");
      setSearchParams(searchParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, setSearchParams, subscriber?.zonePriorityBalances]);

  const { id = "" } = useParams();
  const { refetch: refetchSubscriber } = useSubscriber(id, {
    onSuccess: (data: any) => {
      setSubscriber(data);
    },
  });

  const { data: subscriberNotifications } = useQuery(
    ["subscriberNotifications", subscriber?.id],
    () => getCustomerNotifications(subscriber?.walletId)
  );

  const { data = {} } = useAllEventTransactions({
    params: {
      walletId: subscriber?.walletId,
      includeParentId: true,
      limit: -1,
    },
    enabled: Boolean(subscriber?.walletId),
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
  const eventTransactions = data.eventTransactions ?? [];

  const rightNames = zonePriorityBalance?.zoneBalances
    .map((i: any) => i.rightNames)
    .flat();

  const priorities = zonePriorityBalance?.zoneBalances?.map(
    (i: any) => i.priority
  );

  const filteredTransactions = eventTransactions?.filter(
    (log: any) =>
      (Boolean(log.transactionAmount) &&
        log.impactLevel0ResourceId === zonePriorityBalance?.zoneId) ||
      priorities?.includes(log.reference) ||
      rightNames?.includes(log.reference)
  );

  const eventTransactionTableData = orderBy(
    filteredTransactions,
    ["effectiveAt"],
    ["desc"]
  );

  const zoneExtractionRights =
    subscriber?.extractionRights?.filter((er: any) =>
      rightNames?.includes(er.name)
    ) ?? [];

  const otherUsages = zonePriorityBalance?.zoneBalances.reduce(
    (
      // keep this comment: not required now,
      // if the spUsage has two classes
      // it should add up without dividing into priorities
      accumulator: Array<{ priority: string; usage: number; spUsage: number }>,
      value: any
    ) => {
      accumulator.push({
        priority: value.priority,
        usage: +value.usage,
        spUsage: +value.spUsage,
      });
      return accumulator;
    },
    []
  );

  const totalUsage = zonePriorityBalance?.zoneBalances.reduce(
    (accumulator: any, value: any) => {
      if (
        value.priority === "Water Harvesting" ||
        value.priority === "Special Announcement"
      ) {
        return accumulator;
      }

      return accumulator + value.usage;
    },
    0
  );

  const rightTotalNominalVolume = subscriber?.extractionRights
    .filter(
      (er: any) =>
        rightNames?.includes(er.name) &&
        !er.inactivedAt &&
        er.type?.identifier !== "FD"
    )
    .reduce((accumulator: number, value: any) => {
      return accumulator + +value.volume;
    }, 0);

  const prevYearFDCarriedOver = subscriber?.extractionRights
    .filter((i: any) => rightNames?.includes(i.name))
    .reduce((accumulator: number, value: any) => {
      return accumulator + +value.forwardDrawWaterVolumeOfLastAccountingPeriod;
    }, 0);

  const allocatedVolumeObjects = zonePriorityBalance?.zoneBalances.reduce(
    (
      accumulator: {
        announcedAllocation: number;
        remainingBalance: number;
        forwardDraw: number;
        usage: number;
      },
      value: any
    ) => {
      const rightAllocation = +value.rightAllocation;

      if (value.priority === "Forward Draw") {
        accumulator.forwardDraw += rightAllocation;
      } else {
        accumulator.announcedAllocation += rightAllocation;
        accumulator.remainingBalance += +value.balance;
        accumulator.usage += +value.usage;
      }
      return accumulator;
    },
    {
      announcedAllocation: 0,
      remainingBalance: 0,
      forwardDraw: 0,
      usage: 0,
    }
  );

  const transferVolume =
    zonePriorityBalance?.buyingVolume - zonePriorityBalance?.sellingVolume;

  const totalAvailableWater =
    allocatedVolumeObjects?.remainingBalance +
    allocatedVolumeObjects?.usage +
    allocatedVolumeObjects?.forwardDraw;

  const value: ContextValue = {
    currentMenu,
    setCurrentMenu,
    currentSubMenu,
    setCurrentSubMenu,
    currentSubMenuTab,
    setCurrentSubMenuTab,
    subscriber,
    refetchSubscriber,
    subscriberNotifications,
    zonePriorityBalance,
    setZonePriorityBalance,
    zoneExtractionRights,
    eventTransactionTableData,
    otherUsages,
    totalUsage,
    rightTotalNominalVolume,
    transferVolume,
    prevYearFDCarriedOver,
    allocatedVolumeObjects,
    totalAvailableWater,
    rightNames,
    filter,
    handleFilterChange,
  };

  return (
    <SubscriberIndexContext.Provider value={value}>
      {children}
    </SubscriberIndexContext.Provider>
  );
};

const useSubscriberIndexContext = () => {
  const context = React.useContext(SubscriberIndexContext);
  if (context === undefined) {
    throw new Error(
      "useSubscriberIndexContext must be used within a SubscriberIndexProvider"
    );
  }
  return context;
};

export { SubscriberIndexProvider, useSubscriberIndexContext };
