import { ReactNode } from "react";
import classNames from "classnames";
import { ArrowRightIcon } from "@heroicons/react/24/solid";
import { t as translate } from "i18next";
import { useTranslation } from "react-i18next";

import Heading from "@components/layout/Heading";
import SubHeading from "@components/layout/SubHeading";
import CheckCircleIcon from "@components/icons/CheckCircleIcon";
import InformationCircleIcon from "@components/icons/InformationCircleIcon";
import XCircleIcon from "@components/icons/XCircleIcon";
import { useAppContext } from "@context/AppContext";

type InfoPanelProps = {
  heading?: string;
  children?: ReactNode;
  errors?: ReactNode[];
  warnings?: ReactNode[];
  successes?: ReactNode[];
  className?: string;
  actions?: Array<{
    action: () => void;
    label: ReactNode;
  }>;
};

const InfoPanel: React.FunctionComponent<InfoPanelProps> = ({
  heading = translate("common.information"),
  children,
  successes,
  warnings,
  errors,
  className,
  actions,
}) => {
  const { t } = useTranslation();
  const { setIsExpandInfoPanel } = useAppContext();

  return (
    <div
      className={classNames(
        "flex flex-col bg-white border p-4 h-auto w-full",
        className
      )}
    >
      <div
        className={classNames(
          "flex flex-col space-y-2 overflow-auto",
          actions && actions?.length > 0 && "basis-3/5"
        )}
      >
        <Heading
          className="flex gap-2 items-center text-xl leading-6 cursor-pointer hover:opacity-75 transition-opacity"
          light={true}
          onClick={() => {
            setIsExpandInfoPanel(false);
          }}
        >
          <ArrowRightIcon className="w-5 h-5" />
          <span>{heading}</span>
        </Heading>

        <div className="text-sm text-gray-600 space-y-2">
          {errors && errors?.length > 0 && (
            <ul className="flex flex-col space-y-2">
              {errors?.map((error, i) => (
                <li key={`warning_key_${i}`} className="pl-4 flex gap-4">
                  <span>
                    <XCircleIcon className="text-red-600 w-5 h-5" />
                  </span>
                  <div>{error}</div>
                </li>
              ))}
            </ul>
          )}
          {warnings && warnings?.length > 0 && (
            <ul className="flex flex-col space-y-2">
              {warnings?.map((warning, i) => (
                <li key={`warning_key_${i}`} className="pl-4 flex gap-4">
                  <span>
                    <InformationCircleIcon className="text-yellow-600 w-5 h-5" />
                  </span>
                  <div>{warning}</div>
                </li>
              ))}
            </ul>
          )}
          {successes && successes?.length > 0 && (
            <ul className="flex flex-col space-y-2">
              {successes?.map((success, i) => (
                <li key={`success_key_${i}`} className="pl-4 flex gap-4">
                  <span>
                    <CheckCircleIcon className="text-green-600 w-5 h-5" />
                  </span>
                  <div>{success}</div>
                </li>
              ))}
            </ul>
          )}
        </div>
        <div className="p-4 text-sm text-gray-600">{children}</div>
      </div>
      {actions && actions?.length > 0 && (
        <div className="basis-2/5">
          <SubHeading>{t("common.actions")}</SubHeading>
          <ul className="flex flex-col">
            {actions?.map(({ label, action }, i) => (
              <li key={`success_key_${i}`} className="pl-2 pt-4">
                <button
                  type="button"
                  onClick={() => action()}
                  className="flex justify-between items-center w-full px-4 py-2 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                >
                  <span className="mr-2 text-left">{label}</span>
                  <div className="w-2 h-2">
                    <ArrowRightIcon />
                  </div>
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default InfoPanel;
