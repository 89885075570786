import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Heading from "@components/layout/Heading";
import StepButton from "@components/shared/StepButton";
import StepInfo from "@components/shared/StepInfo";
import WorkflowPanels from "@components/form/WorkflowPanels";
import InfoPanel from "@components/form/InfoPanel";
import CreatePrimaryUser from "@components/form/subscriber/create/CreatePrimaryUser";
import ConfirmSubscriber from "@components/form/subscriber/ConfirmSubscriber";
import ConfirmPrimaryUser from "@components/form/subscriber/create/ConfirmPrimaryUser";
import UploadEvidencesWorkflowForm from "@components/form/UploadEvidenceWorkflowForm";
import SubscriberForm from "@components/form/subscriber/SubscriberForm";
import { StepProvider } from "@context/shared/StepContext";
import {
  SubscriberCreateOrEditProvider,
  useSubscriberCreateOrEditContext,
} from "@context/SubscriberCreateOrEditContext";
import {
  EvidenceProvider,
  useEvidenceContext,
} from "@context/shared/EvidenceContext";
import { useStepContext } from "@context/shared/StepContext";
import { useCreateSubscriber } from "@hooks/mutation/useCreateSubscriber";

const CreateSubscriberWorkflow = () => {
  return (
    <StepProvider maxStep={6}>
      <EvidenceProvider>
        <SubscriberCreateOrEditProvider>
          <Consumer />
        </SubscriberCreateOrEditProvider>
      </EvidenceProvider>
    </StepProvider>
  );
};

const Consumer = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { stepHelpers, currentStep } = useStepContext();
  const {
    subscriber,
    setSubscriber,
    getSubscriberInfo,
    user,
    getUserInfo,
    isSubscriberComplete,
    setIsSubcriberComplete,
    isUserComplete,
    workflowInstance,
    setWorkflowInstance,
    cancel,
    parseAddress,
  } = useSubscriberCreateOrEditContext();
  const {
    getEvidencesInfo,
    uploadEvidences,
    reset: resetEvidences,
  } = useEvidenceContext();

  const { mutateAsync: createSubscriberMutation } = useCreateSubscriber();

  const steps = [
    {
      label: (
        <StepButton
          index={0}
          currentStep={currentStep}
          isOnChain={isSubscriberComplete}
          info={<StepInfo data={getSubscriberInfo().body} />}
        >
          {t("subscriber.create.enter_subscriber_details")}
        </StepButton>
      ),
      panel: (
        <div className="flex flex-col grow p-6">
          <Heading light className="mb-8">
            {t("subscriber.create.enter_subscriber_details")}
          </Heading>

          <SubscriberForm
            defaultValues={subscriber}
            onSubmit={(data) => {
              setSubscriber((prev) => ({
                ...prev,
                ...data,
              }));
              stepHelpers.goToNextStep();
            }}
            onCancel={cancel}
          />
        </div>
      ),
      infoPanel: (
        <InfoPanel>
          <div className="space-y-6">
            <p>{t("subscriber.create.info1")}</p>
            <p>{t("subscriber.create.info2")}</p>
            <p>{t("subscriber.create.info4")}</p>
            <p>{t("subscriber.create.info3")}</p>
          </div>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={1}
          currentStep={currentStep}
          isOnChain={isSubscriberComplete}
          info={<StepInfo data={getEvidencesInfo().body} />}
        >
          {t("common.enter_evidence")}
        </StepButton>
      ),
      panel: (
        <UploadEvidencesWorkflowForm
          onNext={stepHelpers.goToNextStep}
          onPrevious={stepHelpers.goToPrevStep}
          onCancel={() => cancel}
        />
      ),
      infoPanel: (
        <InfoPanel>
          <div className="space-y-6">
            <p>{t("subscriber.create.info_select_files")}</p>
            <p>{t("evidence.supported")}</p>
          </div>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={2}
          currentStep={currentStep}
          isOnChain={isSubscriberComplete}
          forceSuccess={isSubscriberComplete}
        >
          {t("subscriber.create.ledger")}
        </StepButton>
      ),
      panel: (
        <ConfirmSubscriber
          isComplete={isSubscriberComplete}
          workflowInstance={workflowInstance}
          data={[getSubscriberInfo(), getEvidencesInfo()]}
          onEdit={stepHelpers.setStep}
          onPrevious={stepHelpers.goToPrevStep}
          onCancel={cancel}
          onSubmit={async () => {
            const [res, workflowInstance] = await createSubscriberMutation({
              level1ResourceId: subscriber.level1Resource.id,
              name: subscriber.name,
              abn: subscriber.abn,
              accountNumber: subscriber.accountNumber,
              isActive: subscriber.isActive,
              address: parseAddress(subscriber.address),
            });
            setSubscriber((prev) => ({
              ...prev,
              id: res.id,
              walletId: res.walletId,
            }));
            setWorkflowInstance(workflowInstance);

            await uploadEvidences({
              description: t("subscriber.evidence_data", { name: res.name }),
              referenceId: res.id,
              referenceTable: "subscribers",
            });
            setIsSubcriberComplete(true);
            setSubscriber((prev) => ({
              ...prev,
              walletId: res.walletId,
            }));
            stepHelpers.goToNextStep();
            resetEvidences();

            searchParams.append("subscriberId", res.id);
            setSearchParams(searchParams);
          }}
        />
      ),
      infoPanel: (
        <InfoPanel
          successes={
            isSubscriberComplete
              ? [
                  t(`subscriber.create.info10`, {
                    subscriberName: subscriber?.name,
                    username: user?.name,
                  }),
                ]
              : [t("subscriber.create.info9")]
          }
        >
          {isSubscriberComplete ? (
            <p>{t("subscriber.create.info11")}</p>
          ) : (
            <ul className="space-y-4">
              <li>{t("common.info.verify")}</li>
              <li>
                <strong>{t("subscriber.create.subscriber_details")}</strong>:{" "}
                {t("subscriber.verify.details")}
              </li>
              <li>
                <strong>{t("evidence.supporting")}:</strong>{" "}
                {t("evidence.info.verify")}
              </li>
              <li>{t("common.info.changes")}</li>
            </ul>
          )}
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={3}
          currentStep={currentStep}
          isOnChain={isUserComplete}
          info={<StepInfo data={getUserInfo().body} />}
        >
          {t("user.create_primary_user.title")}
        </StepButton>
      ),
      panel: <CreatePrimaryUser />,
      infoPanel: (
        <InfoPanel successes={[t("user.create_primary_user.info1")]}>
          <div className="space-y-6">
            <p>{t("user.create_primary_user.info2")}</p>
            <p>{t("user.create_primary_user.info3")}</p>
          </div>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={4}
          currentStep={currentStep}
          isOnChain={isUserComplete}
          info={<StepInfo data={getEvidencesInfo().body} />}
        >
          {t("common.enter_evidence")}
        </StepButton>
      ),
      panel: (
        <UploadEvidencesWorkflowForm
          onNext={stepHelpers.goToNextStep}
          onPrevious={stepHelpers.goToPrevStep}
          onCancel={() => cancel}
        />
      ),
      infoPanel: (
        <InfoPanel>
          <div className="space-y-6">
            <p>{t("user.info.select_files")}</p>
            <p>{t("evidence.supported")}</p>
          </div>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={5}
          currentStep={currentStep}
          isOnChain={isUserComplete}
          forceSuccess={isUserComplete}
        >
          {t("user.ledger")}
        </StepButton>
      ),
      panel: <ConfirmPrimaryUser />,
      infoPanel: (
        <InfoPanel
          successes={
            isUserComplete
              ? [t("user.info.success", { name: user?.name })]
              : [t("user.info.confirm")]
          }
          actions={[
            ...(isUserComplete
              ? [
                  {
                    label: t("subscriber.add_subscriber"),
                    action: () => {
                      window.location.reload();
                    },
                  },
                ]
              : []),
          ]}
        >
          {isUserComplete ? (
            t("user.info.end_workflow")
          ) : (
            <ul className="space-y-4">
              <li>{t("common.info.verify")}</li>
              <li>
                <strong>{t("user.details")}:</strong>{" "}
                {t("user.info.verify.details")}
              </li>
              <li>
                <strong>{t("evidence.supporting")}:</strong>{" "}
                {t("evidence.info.verify")}
              </li>
              <li>{t("common.info.changes")}</li>
            </ul>
          )}
        </InfoPanel>
      ),
    },
  ];

  return <WorkflowPanels selectedStep={currentStep} steps={steps} />;
};

export default CreateSubscriberWorkflow;
