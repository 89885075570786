import { t as translate } from "i18next";
import { useTranslation } from "react-i18next";
import ENV from "@config/env";
import Heading from "@components/layout/Heading";
import DropzoneBox from "@components/shared/DropzoneBox";
import { useEvidenceContext } from "@context/shared/EvidenceContext";
import Legend from "./Legend";
import React from "react";
import Table from "@components/layout/Table";
import Modal from "@components/layout/Modal";
import { useAppContext } from "@context/AppContext";
import { formatDateTime } from "@utils/formatDateTime";
import Tag from "@components/shared/Tag";
import TrashIcon from "@components/icons/TrashIcon";
import { isImage } from "@utils/isImage";
import PreviewEvidenceImage from "@components/shared/PreviewEvidenceImage";
import { useAllEvidences } from "@hooks/query/useAllEvidences";

type Props = {
  referenceTableId?: string;
  referenceTable?: string;
  onNext: (activatedEvidenceIds: string[]) => void;
  onPrevious: () => void;
  onCancel: () => void;
  workflowIsUpdating?: boolean;
  activatedEvidences?: string[];
  customDropboxTitle?: string;
  newUploadDescription?: string;
  selectableForArchiving?: boolean;
  showHeadAndFooter?: boolean;
};

const UploadEvidencesWorkflowForm: React.FunctionComponent<Props> = ({
  referenceTableId = "",
  referenceTable = "",
  onNext,
  onPrevious,
  onCancel,
  workflowIsUpdating = false,
  activatedEvidences = [],
  customDropboxTitle = translate("evidence.upload_optional") as string,
  newUploadDescription = "",
  selectableForArchiving = true,
  showHeadAndFooter = true,
}) => {
  const { t } = useTranslation();
  const [activatedEvidenceIds, setActivatedEvidenceIds] = React.useState<
    string[]
  >(activatedEvidences || []);
  const { evidences, getRootProps, getInputProps, handleDeleteFile } =
    useEvidenceContext();
  const [selectData, setSelectData] = React.useState<any>();
  const [file, setFile] = React.useState<File>();
  const [imageUrl, setImageUrl] = React.useState("");
  const { loggedInInfo } = useAppContext();
  const {
    data: previousEvidences = [],
    isLoading,
    isFetching,
  } = useAllEvidences({
    params: { referenceTable, referenceId: referenceTableId },
    keepPreviousData: true,
    enabled: Boolean(referenceTableId) && Boolean(referenceTable),
  });

  const handleSelect = (ids: string[]) => {
    const selectedEvidences = previousEvidences?.filter((i: any) =>
      ids.some((id) => i.id === id)
    );

    setActivatedEvidenceIds(selectedEvidences.map((v: any) => v.id));
  };

  const tableFields = [
    {
      title: t("common.status"),
      name: "status",
    },
    {
      title: t("evidence.file.name"),
      name: "fileName",
    },
    {
      title: t("common.description"),
      name: "description",
    },
    {
      title: t("evidence.created_at"),
      name: "createdAt",
    },
    {
      title: t("common.actions"),
      name: "actions",
    },
    {
      title: "",
      name: "delete",
    },
  ];

  const newEvidencesData = evidences?.map((item: any, index: number) => {
    const evidenceMock = {
      id: index,
      description: newUploadDescription,
      fileName: item.name,
      title: item.name,
      status: <Tag status="info">{t("common.new")}</Tag>,
      contentIdentifier: t("common.na"),
      user: { name: loggedInInfo?.userDetails?.name },
      isPreview: true,
      createdAt: <Tag>{t("evidence.not_ledgered_yet")}</Tag>,
      delete: (
        <div
          className="flex items-center justify-center cursor-pointer"
          onClick={() => handleDeleteFile(index)}
        >
          <TrashIcon className="w-8 h-8" />
        </div>
      ),
    };
    return {
      ...evidenceMock,
      actions: (
        <>
          <button
            className="btn-primary"
            onClick={(event) => {
              handlePreview(evidences[index], evidenceMock, event);
            }}
          >
            {t("common.view")}
          </button>
        </>
      ),
    };
  });

  const previousEvidencesData = previousEvidences?.map((item: any) => {
    return {
      ...item,
      createdAt: formatDateTime(new Date(item.createdAt)),
      status: <Tag status="info">{t("common.on_chain")}</Tag>,
      actions: (
        <button className="btn-primary" onClick={() => setSelectData(item)}>
          {t("common.view")}
        </button>
      ),
    };
  });

  const disabledKeys = newEvidencesData?.map((i: any) => i.id);

  const handlePreview = (
    file: File,
    evidenceMock: any,
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    if (file.type === "application/pdf" || isImage(file.name)) {
      setFile(file);
      setImageUrl(URL.createObjectURL(file));
    }
    setSelectData(evidenceMock);
  };

  return (
    <>
      <div className="flex flex-col p-6 grow justify-between">
        <div className="flex flex-col grow gap-6">
          {showHeadAndFooter && (
            <div className="space-y-4">
              <Heading light>{t("evidence.supporting")}</Heading>
            </div>
          )}
          {previousEvidencesData.length ? (
            <>
              <Legend>
                {workflowIsUpdating
                  ? t("evidence.currently_ledgered")
                  : t("evidence.currently_uploaded")}
              </Legend>

              <Table
                fields={tableFields}
                data={[
                  ...(previousEvidencesData.length
                    ? previousEvidencesData
                    : []),
                ]}
                selectionKey={selectableForArchiving ? "id" : undefined}
                selectedKeys={[
                  ...(activatedEvidenceIds.length ? activatedEvidenceIds : []),
                  ...(disabledKeys.length ? disabledKeys : []),
                ]}
                disabledKeys={disabledKeys.length ? disabledKeys : undefined}
                onSelectionChange={handleSelect}
                loading={isLoading && isFetching}
                pageSize={4}
              />
            </>
          ) : null}

          <form className="mb-2">
            {showHeadAndFooter && (
              <Legend>{t("evidence.upload_new_file")}</Legend>
            )}
            <div>
              <DropzoneBox
                getRootProps={getRootProps}
                getInputProps={getInputProps}
                onDelete={handleDeleteFile}
                title={customDropboxTitle}
              />
              <div className="grow" />
            </div>
          </form>

          <Table
            fields={tableFields}
            data={[...(newEvidencesData.length ? newEvidencesData : [])]}
            selectionKey={selectableForArchiving ? "id" : undefined}
            selectedKeys={[
              ...(activatedEvidenceIds.length ? activatedEvidenceIds : []),
              ...(disabledKeys.length ? disabledKeys : []),
            ]}
            disabledKeys={disabledKeys.length ? disabledKeys : undefined}
            onSelectionChange={handleSelect}
            loading={isLoading && isFetching}
            pageSize={4}
          />
        </div>
        {showHeadAndFooter && (
          <footer className="flex gap-4 -mx-6 mt-6 p-6 pb-0 border-t border-gray-200">
            {
              <button
                type="button"
                className="btn-outline-primary"
                onClick={onPrevious}
              >
                {t("common.prev_step")}
              </button>
            }

            {
              <button
                type="button"
                className="btn-primary"
                onClick={() => onNext(activatedEvidenceIds)}
              >
                {t("common.next_step")}
              </button>
            }

            {
              <button
                type="button"
                className="btn-outline-primary"
                onClick={onCancel}
              >
                {t("common.cancel")}
              </button>
            }
          </footer>
        )}
      </div>
      <Modal open={selectData}>
        <header className="border-b p-4">
          <h3 className="text-lg font-bold leading-6">
            {t("evidence.viewer")}
          </h3>
        </header>

        {selectData?.fileName &&
        !selectData.isPreview &&
        isImage(selectData.fileName) ? (
          <div className="p-4 pb-0">
            <PreviewEvidenceImage evidence={selectData} />
          </div>
        ) : null}

        {file && isImage(file.name) ? (
          <div className="p-4 pb-0 relative group">
            <div
              onClick={() => window.open(imageUrl, "_blank")}
              className="cursor-pointer hover:opacity-75 overflow-hidden"
            >
              <img
                src={imageUrl}
                alt={file.name}
                className="transition duration-300 ease-in-out"
              />
              <span className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-white text-base p-2 bg-black bg-opacity-75 rounded opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out">
                {t("common.preview")}
              </span>
            </div>
          </div>
        ) : null}

        <div className="px-4 py-6">
          <div className="border rounded-md p-4 space-y-2">
            <h3 className="text-lg font-bold leading-6">
              {t("evidence.information")}
            </h3>

            <ul className="space-y-2 text-sm text-gray-600">
              <li>
                <strong>{t("evidence.title")}:</strong> {selectData?.title}
              </li>
              <li>
                <strong>{t("common.description")}:</strong>{" "}
                {selectData?.description}
              </li>
              <li>
                <strong>{t("evidence.file.name")}:</strong>{" "}
                {selectData?.fileName}
              </li>
              <li>
                <strong>{t("evidence.file.hash")}:</strong>{" "}
                {selectData?.contentIdentifier}
              </li>
              <li>
                <strong>{t("evidence.file.loaded")}:</strong>{" "}
                {selectData?.createdAt
                  ? formatDateTime(new Date(selectData?.createdAt))
                  : selectData?.isPreview
                  ? t("evidence.not_ledgered")
                  : ""}
              </li>
              <li>
                <strong>{t("evidence.loaded_by")}:</strong>{" "}
                {selectData?.user?.name}
              </li>
            </ul>
          </div>
        </div>

        <footer className="flex justify-end gap-4 px-4 py-3 border-t">
          {!selectData?.isPreview && (
            <a
              className="btn-outline-primary rounded text-sm"
              href={`${ENV.API_HOST}/evidences/${selectData?.id}/download`}
              download
            >
              {t("common.download")}
            </a>
          )}

          {file && (
            <button
              className="btn-outline-primary rounded text-sm"
              onClick={() => {
                window.open(imageUrl, "_blank");
              }}
            >
              {t("common.preview")}
            </button>
          )}

          <button
            type="button"
            className="btn-primary rounded text-sm"
            onClick={() => {
              setSelectData(null);
              setFile(undefined);
              setImageUrl("");
            }}
          >
            {t("common.close")}
          </button>
        </footer>
      </Modal>
    </>
  );
};

export default UploadEvidencesWorkflowForm;
