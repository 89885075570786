export function getWaterSource(
  name: string
): "surface" | "pipeline" | "ground" {
  if (name.includes("SW")) {
    return "surface";
  } else if (name.includes("MVP") || name === "HP") {
    return "pipeline";
  }
  return "ground";
}
