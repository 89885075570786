import React from "react";
import { useTranslation } from "react-i18next";

import Heading1 from "@components/layout/Heading";
import { getWaterSource } from "@utils/getWaterSource";
import { formatVolume } from "@utils/formatVolume";
import { formatDate } from "@utils/formatDate";
import Table from "@components/layout/Table";
import { getAllAdministrativeApprovals } from "@services/administrativeApprovals";
import { useQuery } from "@tanstack/react-query";
import { startCase } from "lodash";
import { getIsExtractionRightLinkableWithPoint } from "@utils/getIsExtractionRightLinkableWithPoint";
import ExtractionRightLinkableWithPointTag from "@components/shared/ExtractionRightLinkableWithPointTag";

type ExtractionPointLinkToRightStep2Props = {
  level0WRSId: string;
  subscriberId: string;
  onSave: (data: { extractionRight: any }) => void;
  previousStep: () => void;
  extractionRights: any[];
  setSelectedExtractionRight: (data: any) => void;
  selectedExtractionRight: any;
  extractionPoint: any;
};

const ExtractionPointLinkToRightStep2: React.FunctionComponent<
  ExtractionPointLinkToRightStep2Props
> = ({
  onSave,
  previousStep,
  setSelectedExtractionRight,
  selectedExtractionRight,
  extractionPoint,
  extractionRights,
}: ExtractionPointLinkToRightStep2Props) => {
  const { t } = useTranslation();
  const { data: administrativeApprovals } = useQuery(
    ["exhangeApprovals"],
    () => getAllAdministrativeApprovals(),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: true,
    }
  );

  const handleSave = (e: any) => {
    e.preventDefault();

    onSave({
      extractionRight: selectedExtractionRight,
    });
  };

  return (
    <>
      <section className="p-6 flex flex-col gap-4 grow">
        <Heading1 light>
          {t("extraction_point.link.choose_entitlement")}
        </Heading1>
        <Table
          pageSize={50}
          containerClassName="rounded-none md:rounded-none text-sm"
          noRowsText={
            t("extraction_point.link.no_data", {
              source: extractionPoint?.source,
            }) as string
          }
          fields={[
            {
              title: "",
              name: "radioButton",
            },
            {
              title: "",
              name: "linked",
            },
            {
              title: t("subscriber.account_number"),
              name: "accountNumber",
            },
            {
              title: t("subscriber.name"),
              name: "accountName",
            },
            {
              title: t("extraction_right.purpose"),
              name: "purpose",
            },
            {
              title: t("extraction_right.name"),
              name: "name",
            },
            {
              title: t("extraction_point.source"),
              name: "source",
            },
            {
              title: t("common.water_class"),
              name: "priority",
            },
            {
              title: `${t("extraction_right.volume")} (${t(
                "common.volume_unit"
              )})`,
              name: "volume",
            },
            {
              title: t("common.level0wrs"),
              name: "zone",
            },
            {
              title: t("extraction_right.start_at"),
              name: "startAt",
            },
            {
              title: t("extraction_right.end_at"),
              name: "endAt",
            },
            {
              title: t("extraction_right.billing_group"),
              name: "billingGroup",
            },
            {
              title: t("extraction_right.freq"),
              name: "frequency",
            },
          ]}
          data={extractionRights
            ?.filter((er: any) => {
              return (
                getWaterSource(er.waterClass?.name) === extractionPoint?.source
              );
            })
            ?.map((er: any) => ({
              ...er,
              billingGroup: er.billingGroup?.name,
              radioButton: (
                <input
                  type="radio"
                  className="h-4 w-6 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-6"
                  checked={
                    selectedExtractionRight?.id === er.id ||
                    getIsExtractionRightLinkableWithPoint(
                      er,
                      extractionPoint.id,
                      administrativeApprovals
                    ).isLinkable
                  }
                  onChange={() => {
                    setSelectedExtractionRight(er);
                  }}
                  disabled={
                    getIsExtractionRightLinkableWithPoint(
                      er,
                      extractionPoint.id,
                      administrativeApprovals
                    ).isLinkable
                  }
                />
              ),
              source: startCase(getWaterSource(er.waterClass?.name)),
              linked: (
                <ExtractionRightLinkableWithPointTag
                  extractionRight={er}
                  extractionPointId={extractionPoint.id}
                  administrativeApprovals={administrativeApprovals}
                />
              ),
              accountNumber: er.subscriber?.accountNumber,
              accountName: er.subscriber?.name,
              priority: er?.waterClass.name,
              volume: formatVolume(er?.volume, ""),
              zone: er?.level0Resource?.identifier,
              startAt: er?.startAt ? formatDate(new Date(er?.startAt)) : "-",
              endAt: er?.startAt ? formatDate(new Date(er?.startAt)) : "-",
              frequency: er?.subscriber?.billingFrequency,
            }))
            .sort((a: any, b: any) => +a.accountNumber - +b.accountNumber)}
          stickyHeader
        ></Table>
      </section>
      <footer className="border-t border-gray-200 py-4 px-6 flex gap-3">
        <button
          className="btn-primary"
          disabled={!selectedExtractionRight}
          onClick={handleSave}
        >
          {t("common.continue")}
        </button>
        <button className="btn-outline-primary" onClick={previousStep}>
          {t("common.cancel")}
        </button>
      </footer>
    </>
  );
};

export default ExtractionPointLinkToRightStep2;
