import api from "./api";

export type GetAllSubscribersParams = {
  isActive: boolean;
  level1ResourceId: string;
  hasBalance: boolean;
  accountNumber: string;
};

export const getAllSubscribers = async (
  params?: Partial<GetAllSubscribersParams>
): Promise<any> => {
  const { data } = await api.get("/admin/subscribers", { params });

  return data.subscribers;
};

export const getSubscriber = async (id: string): Promise<any> => {
  const { data } = await api.get(`/admin/subscribers/${id}`);
  return data.subscriber;
};

export const getSystemSubscriber = async (): Promise<any> => {
  const { data } = await api.get("/admin/subscribers/0");

  return data.subscriber;
};

export type Address = {
  id: string;
  street: string;
  town: string;
  region: string;
  postcode: string;
  country: string;
  location: string;
};

type SubscriberPayload = {
  level1ResourceId: string;
  name: string;
  abn?: string;
  accountNumber: string;
  isActive: boolean;
  address?: Partial<Address>;
};

export const createSubscriber = async (payload: SubscriberPayload) => {
  const { data } = await api.post("/admin/subscribers", payload);

  return [data.subscriber, data.workflowInstance];
};

export const updateSubscriber = async ({
  subscriberId,
  ...payload
}: {
  subscriberId: string;
  primaryUserId?: string;
} & Partial<SubscriberPayload>) => {
  const { data } = await api.put(`/admin/subscribers/${subscriberId}`, payload);

  return [data.subscriber, data.workflowInstance];
};

export const getSubscriberNotes = async ({
  subscriberId,
  level1ResourceId,
  fromDate,
  toDate,
}: {
  subscriberId?: string;
  level1ResourceId?: string;
  fromDate?: Date;
  toDate?: Date;
}): Promise<any> => {
  const { data } = await api.get("/admin/subscriberNotes", {
    params: {
      subscriberId,
      level1ResourceId,
      fromDate,
      toDate,
    },
  });

  return data.subscriberNotes;
};

export const addSubscriberNote = async (noteData: {
  subscriberId: string;
  note: string;
  attachment?: File;
}): Promise<any> => {
  let newNote;

  if (noteData.attachment) {
    const formData = new FormData();

    formData.append("subscriberId", noteData.subscriberId);
    formData.append("note", noteData.note);
    formData.append("attachment", noteData.attachment);

    newNote = formData;
  } else {
    newNote = noteData;
  }

  const { data } = await api.post("/admin/subscriberNotes", newNote);

  return data.subscribeNote;
};

export const generateSubscriberAccountNumber = async (): Promise<any> => {
  const { data } = await api.get("/admin/generateSubscriberAccountNumber");

  return data.accountNumber;
};

export const checkAccountNumber = async (subscriberData: {
  accountNumber: string;
  id?: string;
}): Promise<any> => {
  const { data } = await api.post(
    "/admin/subscribers/checkAccountNumber",
    subscriberData
  );

  return data.exists;
};

export const getSubscriberLevel0ResourceBalances = async (id: string) => {
  const { data } = await api.get(
    `/admin/subscribers/${id}/level0ResourceBalances`
  );

  return data.level0ResouceBalances;
};
