import React from "react";
import { useTranslation } from "react-i18next";
import HandleGoBackOrClose from "@components/shared/HandleGoBackOrClose";
import WorkflowPanels from "@components/form/WorkflowPanels";
import InfoPanel from "@components/form/InfoPanel";
import DeclarationForm from "@components/form/declaration/DeclarationForm";
import UploadEvidencesWorkflowForm from "@components/form/UploadEvidenceWorkflowForm";
import ConfirmDeclaration from "@components/form/declaration/ConfirmDeclaration";
import StepButton from "@components/shared/StepButton";
import StepInfo from "@components/shared/StepInfo";

import { StepProvider, useStepContext } from "@context/shared/StepContext";
import {
  DeclarationProvider,
  READING_TYPES,
  useDeclarationContext,
} from "@context/DeclarationContext";
import {
  EvidenceProvider,
  useEvidenceContext,
} from "@context/shared/EvidenceContext";
import { useCreateDeclaration } from "@hooks/mutation/useCreateDeclaration";
import { convertMLToLiter } from "@utils/convertUnits";
import { useCreateInitialDeclaration } from "@hooks/mutation/useCreateInitialDeclaration";

const ReplaceMeterCapsuleWorkflow = () => {
  return (
    <StepProvider maxStep={6}>
      <DeclarationProvider>
        <EvidenceProvider>
          <Consumer />
        </EvidenceProvider>
      </DeclarationProvider>
    </StepProvider>
  );
};

const Consumer: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const handleGoBackOrClose = HandleGoBackOrClose();
  const { currentStep, stepHelpers } = useStepContext();
  const {
    getEvidencesInfo,
    uploadEvidences,
    reset: resetEvidences,
  } = useEvidenceContext();
  const {
    declaration,
    getDeclarationInfo,
    isComplete: isInitialDeclarationComplete,
    setIsComplete: setIsInitialDeclarationComplete,
    workflowInstance: initialDeclarationWorkflow,
    setWorkflowInstance: setInitialDeclarationWorkflow,
    resetReading,
  } = useDeclarationContext();
  const { mutateAsync: createDeclarationMutation } = useCreateDeclaration();
  const { mutateAsync: createInitialDeclarationMutation } =
    useCreateInitialDeclaration();

  const [isFinalDeclarationComplete, setIsFinalDeclarationComplete] =
    React.useState(false);
  const [finalDeclarationInfo, setFinalDeclarationInfo] = React.useState<any>();
  const [finalDeclarationEvidencesInfo, setFinalDeclarationEvidencesInfo] =
    React.useState<any>();
  const [finalDeclarationWorkflow, setFinalDeclarationWorkflow] =
    React.useState<any>();
  const [minReadAt, setMinReadAt] = React.useState("");

  const handleCancel = handleGoBackOrClose;

  const handleCreateFinalDeclaration = async () => {
    const [res, workflowInstance] = await createDeclarationMutation({
      reading: convertMLToLiter(+declaration.reading),
      readAt: new Date(declaration.readAt),
      isOpening: false,
      meterId: declaration.meter.serialNo,
      extractionPointName: declaration.extractionPoint.name,
      isEstimated: declaration.type === READING_TYPES.ESTIMATED,
      calculation: declaration.calculation,
    });
    await uploadEvidences({
      description: t("declaration.evidence_data.description", {
        serialNo: declaration.meter.serialNo,
        readAt: declaration.readAt,
        extractionPointName: declaration.extractionPoint.name,
      }),
      referenceId: res.id,
      referenceTable: "declarations",
    });

    setMinReadAt(declaration.readAt);
    setFinalDeclarationInfo(getDeclarationInfo().body);
    setFinalDeclarationEvidencesInfo(getEvidencesInfo().body);
    setIsFinalDeclarationComplete(true);
    setFinalDeclarationWorkflow(workflowInstance);
    resetEvidences();
    resetReading();
    stepHelpers.goToNextStep();
  };

  const handleCreateInitialDeclaration = async () => {
    const [res, workflowInstance] = await createInitialDeclarationMutation({
      subscriberId: declaration.subscriber.id,
      extractionPointName: declaration.extractionPoint.name,
      serialNo: declaration.meter.serialNo,
      reading: convertMLToLiter(+declaration.reading),
      readAt: new Date(declaration.readAt),
      isEstimated: declaration.type === READING_TYPES.ESTIMATED,
    });
    await uploadEvidences({
      description: t("declaration.evidence_data.description", {
        serialNo: declaration.meter.serialNo,
        readAt: declaration.readAt,
        extractionPointName: declaration.extractionPoint.name,
      }),
      referenceId: res.id,
      referenceTable: "declarations",
    });

    setIsInitialDeclarationComplete(true);
    setInitialDeclarationWorkflow(workflowInstance);
  };

  const steps = [
    {
      label: (
        <StepButton
          index={0}
          currentStep={currentStep}
          info={
            <StepInfo
              data={finalDeclarationInfo ?? getDeclarationInfo("final").body}
            />
          }
          isOnChain={isFinalDeclarationComplete}
        >
          {t("meter.decommission.declare_final_read")}
        </StepButton>
      ),
      panel: <DeclarationForm variant="final" onCancel={handleCancel} />,
      infoPanel: (
        <InfoPanel
          actions={
            declaration.meter.id
              ? [
                  {
                    label: t("meter.edit.title"),
                    action: () => {
                      window.open(
                        `/polestar/meters/${declaration.meter.id}/edit`
                      );
                    },
                  },
                ]
              : []
          }
        >
          <div className="space-y-6">
            <p>{t("declaration.info.details")}</p>
            <p>{t("declaration.info.timestamp")}</p>
            <p>{t("declaration.info.is_estimated")}</p>
            <p>{t("declaration.info.enter_reading")}</p>
            <p>{t("declaration.info.usage")}</p>
          </div>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={1}
          currentStep={currentStep}
          info={
            <StepInfo
              data={finalDeclarationEvidencesInfo ?? getEvidencesInfo().body}
            />
          }
          isOnChain={isFinalDeclarationComplete}
        >
          {t("common.enter_evidence")}
        </StepButton>
      ),
      panel: (
        <UploadEvidencesWorkflowForm
          onNext={stepHelpers.goToNextStep}
          onPrevious={stepHelpers.goToPrevStep}
          onCancel={handleCancel}
        />
      ),
      infoPanel: (
        <InfoPanel>
          <div className="space-y-6">
            <p>{t("declaration.info.evidence")}</p>
            <p>{t("evidence.supported")}</p>
          </div>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={2}
          currentStep={currentStep}
          isOnChain={isFinalDeclarationComplete}
        >
          {t("declaration.form.confirm_final_read")}
        </StepButton>
      ),
      panel: (
        <ConfirmDeclaration
          title={t("declaration.form.confirm_final_read")}
          onCancel={handleCancel}
          onSubmit={handleCreateFinalDeclaration}
          workflowInstanceIds={[]}
          variant="final"
        />
      ),
      infoPanel: (
        <InfoPanel successes={[t("declaration.info.confirm")]}>
          <ul className="space-y-4">
            <li>{t("common.info.verify")}</li>
            <li>
              <strong>{t("declaration.meter_read_details")}</strong>{" "}
              {t("declaration.info.verify.details")}
            </li>
            <li>
              <strong>{t("evidence.supporting")}:</strong>{" "}
              {t("evidence.info.verify")}
            </li>
            <li>{t("common.info.changes")}</li>
          </ul>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={3}
          currentStep={currentStep}
          info={<StepInfo data={getDeclarationInfo("initial").body} />}
          isOnChain={isInitialDeclarationComplete}
        >
          {t("declaration.declare_initial_meter_reading")}
        </StepButton>
      ),
      panel: (
        <DeclarationForm
          variant="initial"
          minReadAt={minReadAt}
          onCancel={handleCancel}
        />
      ),
      infoPanel: (
        <InfoPanel
          actions={
            declaration.meter.id
              ? [
                  {
                    label: t("meter.edit.title"),
                    action: () => {
                      window.open(
                        `/polestar/meters/${declaration.meter.id}/edit`
                      );
                    },
                  },
                ]
              : []
          }
        >
          <div className="space-y-6">
            <p>{t("declaration.info.initial_reading")}</p>
          </div>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={4}
          currentStep={currentStep}
          info={<StepInfo data={getEvidencesInfo().body} />}
          isOnChain={isInitialDeclarationComplete}
        >
          {t("common.enter_evidence")}
        </StepButton>
      ),
      panel: (
        <UploadEvidencesWorkflowForm
          onNext={stepHelpers.goToNextStep}
          onPrevious={stepHelpers.goToPrevStep}
          onCancel={handleCancel}
        />
      ),
      infoPanel: (
        <InfoPanel>
          <div className="space-y-6">
            <p>{t("declaration.info.evidence")}</p>
            <p>{t("evidence.supported")}</p>
          </div>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={5}
          currentStep={currentStep}
          isOnChain={isInitialDeclarationComplete}
        >
          {t("declaration.form.confirm_initial_read")}
        </StepButton>
      ),
      panel: (
        <ConfirmDeclaration
          title={t("declaration.form.confirm_initial_read")}
          onCancel={handleCancel}
          onSubmit={handleCreateInitialDeclaration}
          workflowInstanceIds={[
            finalDeclarationWorkflow?.id,
            initialDeclarationWorkflow?.id,
          ]}
          variant="initial"
        />
      ),
      infoPanel: (
        <InfoPanel successes={[t("declaration.info.confirm")]}>
          <ul className="space-y-4">
            <li>{t("common.info.verify")}</li>
            <li>
              <strong>{t("declaration.meter_read_details")}</strong>{" "}
              {t("declaration.info.verify.details")}
            </li>
            <li>
              <strong>{t("evidence.supporting")}:</strong>{" "}
              {t("evidence.info.verify")}
            </li>
            <li>{t("common.info.changes")}</li>
          </ul>
        </InfoPanel>
      ),
    },
  ];

  return <WorkflowPanels selectedStep={currentStep} steps={steps} />;
};

export default ReplaceMeterCapsuleWorkflow;
