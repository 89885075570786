import { useTranslation } from "react-i18next";

import Card from "@components/layout/Card";
import Tag from "@components/shared/Tag";
import { useSubscriberIndexContext } from "@context/SubscriberIndexContext";
import { formatVolume } from "@utils/formatVolume";

const SubscriberLevel0WRSBalanceWidget: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const {
    subscriber,
    setCurrentMenu,
    setCurrentSubMenu,
    setZonePriorityBalance,
  } = useSubscriberIndexContext();

  return (
    <Card
      className="h-full"
      header={<p className="flex gap-3">{t("subscriber.level0wrs_volumes")}</p>}
    >
      {subscriber?.zonePriorityBalances <= 0 ? (
        <div className="justify-center flex">
          <div className="font-light text-primary-2 text-center text-xl h-40">
            {t("level0wrs.no_data")}
          </div>
        </div>
      ) : (
        <div className="flex flex-row gap-4 overflow-scroll scrollbar-hide">
          {subscriber?.zonePriorityBalances?.map(
            (zonePriorityBalance: any, index: number) => {
              const filteredZonePriorityBalance =
                zonePriorityBalance?.zoneBalances.filter(
                  (zb: any) => zb.priority !== "Quasi"
                );
              return (
                <div
                  key={zonePriorityBalance?.zoneId}
                  className="max-w-sm rounded-lg border relative"
                >
                  <div className="px-2 py-4">
                    <div className="px-2 text-xs text-gray-500">
                      {t("common.level0wrs")}
                    </div>
                    <div className="px-2 text-xl mb-2">
                      {zonePriorityBalance?.zoneIdentifier}
                    </div>
                  </div>
                  <div className="px-2 pt-2 pb-12 flex flex-row">
                    <div>
                      <div className="px-2 text-xs text-gray-500">
                        {t("common.status")}
                      </div>
                      {filteredZonePriorityBalance.length > 0 ? (
                        <Tag status="success" className="m-2">
                          {t("common.active")}
                        </Tag>
                      ) : (
                        <Tag status="error" className="m-2 w-max">
                          {t("common.inactive")}
                        </Tag>
                      )}
                    </div>
                    {filteredZonePriorityBalance?.length <= 0 ? (
                      <div className="flex w-40"></div>
                    ) : null}
                    {filteredZonePriorityBalance?.map((zoneBalance: any) => {
                      return (
                        <div key={zoneBalance.priority}>
                          <div className="px-2 text-xs text-gray-500">
                            {t("subscriber.volume_remain")}{" "}
                            {zoneBalance.priority}
                          </div>
                          <div className="px-2 w-full my-2 text-primary-3">
                            {formatVolume(zoneBalance.balance) ||
                              `0.000 ${t("common.volume_unit")}`}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="bottom-4 left-2 absolute">
                    <div className="flex gap-1 px-2 items-center w-full">
                      <button
                        type="button"
                        className="btn-primary text-xs rounded-sm"
                        onClick={() => {
                          setCurrentSubMenu(index);
                          setCurrentMenu(1);
                          setZonePriorityBalance(zonePriorityBalance);
                        }}
                      >
                        {t("common.view")}
                      </button>
                    </div>
                  </div>
                </div>
              );
            }
          )}
        </div>
      )}
    </Card>
  );
};

export default SubscriberLevel0WRSBalanceWidget;
