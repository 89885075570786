import React from "react";
import { read, utils } from "xlsx";
import { z } from "zod";
import { parse } from "date-fns";
import { snakeCase, uniqBy } from "lodash";
import { useTranslation } from "react-i18next";

import Heading from "@components/layout/Heading";
import Alert from "@components/shared/Alert";
import TrashIcon from "@components/icons/TrashIcon";
import Tag from "@components/shared/Tag";
import DropzoneBox from "@components/shared/DropzoneBox";
import { useBulkMeterUploadContext } from "@context/BulkMeterUploadContext";
import { useStepContext } from "@context/shared/StepContext";
import { useEvidenceContext } from "@context/shared/EvidenceContext";
import ENV from "@config/env";

const scheme = z.array(
  z.object({
    ...(ENV.CLIENT_ID === "seqwater" && {
      group: z.string().optional(),
      seq: z.coerce.number().optional(),
    }),
    accountNumber: z.string().min(1),
    extractionPointName: z.string().min(1),
    serialNo: z.string().min(1).optional(),
    reading: z.coerce.number(),
    readAt: z.date(),
    comments: z.string().optional(),
  })
);

const convertToJSON = (
  arrayBuffer: ArrayBuffer,
  options = {}
): Record<string, any>[] => {
  const workbook = read(arrayBuffer);
  const worksheet = workbook.Sheets[workbook.SheetNames[0]];
  return utils.sheet_to_json(worksheet, {
    range: 1, // specify header row - [ref](https://github.com/SheetJS/sheetjs/issues/482)
    raw: false,
    ...options,
  });
};

const readFileAsArrayBuffer = (file: File): Promise<any> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = reject;
    reader.readAsArrayBuffer(file);
  });
};

const transformToTrialRunPayload = (arrayBuffer: ArrayBuffer) => {
  const data: any[] = convertToJSON(arrayBuffer, {
    header: [
      ...(ENV.CLIENT_ID === "seqwater" ? ["group", "seq"] : []),
      "accountNumber",
      "extractionPointName",
      "serialNo",
      "reading",
      "readAt",
      "comments",
    ],
  });
  const [, ...rawData] = data;
  const result = rawData.map((i) => ({
    ...i,
    readAt: i.readAt ? parse(i.readAt, "dd/MM/yyyy", new Date()) : null,
  }));

  return result;
};

const UploadFile = () => {
  const { t } = useTranslation();
  const { stepHelpers } = useStepContext();
  const { setMeterReadings, isValidFile, setIsValidFile, handleCancel, reset } =
    useBulkMeterUploadContext();
  const [errors, setErrors] = React.useState<string[]>([]);
  const isMounted = React.useRef(false);

  const {
    evidences: files,
    getRootProps,
    getInputProps,
    handleDeleteFile,
  } = useEvidenceContext();

  const validateFile = React.useCallback(
    async (file: File) => {
      setErrors([]);
      reset();
      let arrayBuffer: any;
      try {
        arrayBuffer = await readFileAsArrayBuffer(file);
      } catch (error) {
        setErrors([t("bulk_meter_upload.error_file_reader") as string]);
        handleDeleteFile(0);
        return;
      }

      const data = transformToTrialRunPayload(arrayBuffer);
      const result = scheme.safeParse(data);

      if (!result.success) {
        setIsValidFile(false);

        const errors = uniqBy(result.error.issues, "path[1]");
        const columnNames = errors.map((i: any) => snakeCase(i.path[1]));
        setErrors(
          columnNames.map((colName) =>
            t(`bulk_meter_upload.validation.${colName}`)
          )
        );

        return;
      }

      setMeterReadings(result.data);
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  React.useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      return;
    }

    if (!files.length) {
      setErrors([]);
      return;
    }

    validateFile(files[0]);
  }, [files, validateFile]);

  const submitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    stepHelpers.goToNextStep();
  };

  return (
    <form onSubmit={submitHandler} className="flex flex-col grow gap-6 p-6">
      <Heading light>{t("bulk_meter_upload.select_file")}</Heading>

      {errors.length ? (
        <Alert type="error">
          <h4 className="text-lg mb-3">
            {t("bulk_meter_upload.validation.invalid")}
          </h4>
          <ul className="space-y-3 pl-4 list-disc">
            {errors.map((i) => (
              <li key={i}>{i}</li>
            ))}
          </ul>
        </Alert>
      ) : null}

      <div className="max-w-lg">
        <DropzoneBox
          getRootProps={getRootProps}
          getInputProps={getInputProps}
          subtitle={t("bulk_meter_upload.supported_file")}
          multiple={false}
        />

        {files?.length ? (
          <table className="w-full text-sm border-collapse border border-gray-100 rounded mt-6">
            <thead>
              <tr>
                <th className="bg-gray-50 p-2 text-left font-normal">
                  {t("bulk_meter_upload.file_to_upload")}
                </th>
                <th
                  className="bg-gray-50 p-2 text-left font-normal"
                  colSpan={2}
                >
                  {t("bulk_meter_upload.format_check")}
                </th>
              </tr>
            </thead>

            <tbody>
              {files.map((file, index) => {
                return (
                  <tr key={`${file.name}--${index}`}>
                    <td className="p-2 border-t border-gray-100 flex items-center gap-1">
                      {file.name}
                    </td>
                    <td>
                      <Tag status={isValidFile ? "success" : "error"}>
                        {isValidFile ? t("common.valid") : t("common.invalid")}
                      </Tag>
                    </td>
                    <td className="p-2 border-t border-gray-100">
                      <button
                        className="p-2 btn-outline-primary flex items-center gap-1 text-xs rounded-sm px-3 py-1 ml-auto"
                        onClick={() => {
                          handleDeleteFile(index);
                          reset();
                        }}
                      >
                        <TrashIcon className="w-3 h-3" />
                        {t("common.delete")}
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : null}
      </div>

      <div className="grow" />

      <footer className="flex gap-4 border-t border-gray-300 p-6 pb-0 -mx-6">
        <button
          type="submit"
          className="btn-primary"
          disabled={!files?.length || !isValidFile}
        >
          {t("common.next_step")}
        </button>
        <button
          type="button"
          className="btn-outline-primary"
          onClick={handleCancel}
        >
          {t("common.cancel")}
        </button>
      </footer>
    </form>
  );
};

export default UploadFile;
