import { useState } from "react";
import Select from "react-select";
import { Link } from "react-router-dom";
import { sortBy, startCase } from "lodash";
import { useTranslation } from "react-i18next";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";

import Heading from "@components/layout/Heading";
import Table from "@components/layout/Table";
import Tag from "@components/shared/Tag";
import Label from "@components/form/Label";
import SearchInput from "@components/form/SearchInput";
import SelectLevel0Resource from "@components/form/SelectLevel0Resource";
import { useAppContext } from "@context/AppContext";
import { useExtractionPointContext } from "@context/ExtractionPointContext";
import { useAllMeters } from "@hooks/query/useAllMeters";
import { convertLiterToML } from "@utils/convertUnits";
import { formatVolume } from "@utils/formatVolume";

const ExtractionPointLinkToMeter = () => {
  const { t } = useTranslation();
  const { stepHelpers, handleChangeDetails, navigateForCancel, details } =
    useExtractionPointContext();
  const { checkPermissions } = useAppContext();
  const { data: meters = [] } = useAllMeters({
    params: {
      subscriberId: details.subscriber?.id,
      isDelete: false,
    },
  });
  const [filter, setFilter] = useState<{
    meterSerialNo?: string;
    level0wrsId?: string;
    status?: boolean;
  }>({});

  const handleFilterChange = (field: string, value: any) => {
    setFilter({
      ...filter,
      [field]: value,
    });
  };

  const activeOptions = [
    { label: t("common.active"), value: true },
    { label: t("common.inactive"), value: false },
  ];

  const tableData = meters
    ?.filter(
      (row: any) =>
        !filter?.meterSerialNo?.length ||
        row.serialNo
          ?.toString()
          .toLowerCase()
          .includes(filter?.meterSerialNo.toString().toLowerCase())
    )
    ?.filter((row: any) => {
      return (
        !filter?.level0wrsId?.length ||
        row.extractionPoint?.level0ResourceId === filter.level0wrsId
      );
    })
    ?.filter(
      (row: any) =>
        filter.status === undefined || row.isActive === filter.status
    )
    .map((item: any) => {
      return {
        ...item,
        extractionPointName: item.extractionPoint?.name || "",
        level0wrs: item.extractionPoint?.level0WRS?.identifier || "",
        clickOver: item?.threshold && formatVolume(+item?.clickOver),
        source: startCase(item.extractionPoint?.source || ""),
        radio: (
          <input
            type="radio"
            name="meter"
            onChange={() => {
              handleChangeDetails("meter", {
                id: item.id,
                serialNo: item.serialNo,
                extractionPointName: item.extractionPoint?.name ?? "",
              });
            }}
            checked={item.id === details.meter.id}
          />
        ),
        status: (
          <Tag status={item.isActive ? "success" : "error"}>
            {item.isActive ? t("common.active") : t("common.inactive")}
          </Tag>
        ),
        threshold: `${convertLiterToML(+item.threshold)} ${t(
          "common.volume_unit"
        )}`,
        view: item.extractionPoint ? (
          <Link
            to={`/polestar/subscribers/${item.extractionPoint.subscriber?.id}?level0ResourceId=${item.extractionPoint?.level0WRS?.id}&extractionPointId=${item.extractionPointId}`}
            target="_blank"
            rel="noopener noreferrer"
            className="btn-default inline-flex itemr-center gap-2 text-sm rounded"
          >
            <ArrowTopRightOnSquareIcon className="w-5 h-5" />
            {t("extraction_point.view")}
          </Link>
        ) : null,
      };
    });

  const sortedTableData = sortBy(tableData, ({ id }) =>
    id === details.meter.id ? 0 : 1
  );

  const tableFields = [
    {
      title: "",
      name: "radio",
    },
    {
      title: t("meter.serial_no"),
      name: "serialNo",
    },
    {
      title: t("extraction_point.name"),
      name: "extractionPointName",
    },
    {
      title: t("common.level0wrs"),
      name: "level0wrs",
    },
    {
      title: t("common.status"),
      name: "status",
    },
    {
      title: t("meter.click_over"),
      name: "clickOver",
    },
    ...(checkPermissions(["viewThreshold"])
      ? [
          {
            title: `${t("meter.threshold")}`,
            name: "threshold",
          },
        ]
      : []),
    {
      title: "",
      name: "view",
    },
  ];

  return (
    <>
      <div className="p-6 flex flex-col gap-6 grow">
        <header className="flex justify-between">
          <Heading light>{t("extraction_point.create.link_to_meter")}</Heading>
        </header>

        <form className="flex justify-between gap-4">
          <div className="flex-1">
            <Label htmlFor="meterSerialNo">{t("meter.filter_serial_no")}</Label>
            <SearchInput
              id="meterSerialNo"
              onChange={(e) =>
                handleFilterChange("meterSerialNo", e.target.value)
              }
            />
          </div>
          <div className="flex-1">
            <Label>{t("common.level0wrs")}</Label>
            <SelectLevel0Resource
              level1ResourceId={details.level1wrs.id}
              value={filter.level0wrsId}
              onChange={(e: any) => {
                handleFilterChange("level0wrsId", e?.value);
              }}
              isClearable
            />
          </div>
          <div className="flex-1">
            <Label htmlFor="status">
              {t("subscriber.filter_subscriber_status")}
            </Label>
            <Select
              options={activeOptions}
              value={activeOptions.find(
                (activeOption) => activeOption.value === filter.status
              )}
              onChange={(e) => handleFilterChange("status", e?.value)}
              isClearable
            />
          </div>
        </form>

        <Table fields={tableFields} data={sortedTableData} stickyHeader />
      </div>

      <footer className="flex gap-4 p-6 border-t border-gray-200">
        <button
          type="button"
          className="btn-outline-primary"
          onClick={stepHelpers.goToPrevStep}
        >
          {t("common.prev_step")}
        </button>
        <button
          type="button"
          className="btn-primary"
          onClick={stepHelpers.goToNextStep}
        >
          {t("common.next_step")}
        </button>
        <button
          type="button"
          className="btn-outline-primary"
          onClick={() => navigateForCancel()}
        >
          {t("common.cancel")}
        </button>
      </footer>
    </>
  );
};

export default ExtractionPointLinkToMeter;
