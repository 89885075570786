import { ReactNode, useEffect, useState } from "react";
import classNames from "classnames";

import ExpandInfoPanelButton from "@components/shared/ExpandInfoPanelButton";
import { useAppContext } from "@context/AppContext";
import { useHashValue } from "hooks/useHashValue";

type TabPanelsProps = {
  selectedTab?: number;
  tabs: Array<{
    title: ReactNode;
    panel: ReactNode;
    tabInfoPanel?: ReactNode;
  }>;
  className?: string;
  keepSelectionOnRefresh?: boolean;
};

const TabPanels: React.FunctionComponent<TabPanelsProps> = ({
  selectedTab,
  tabs,
  className,
  keepSelectionOnRefresh,
}) => {
  const { hash, replaceHash } = useHashValue();
  const [tabNo, setTabNo] = useState(selectedTab || 0);
  const [loadedTab, setLoadedTab] = useState<number[]>([tabNo]);
  const { isExpandInfoPanel } = useAppContext();
  const infoPanel = tabs[tabNo]?.tabInfoPanel;

  const handleSelectTab = (i: number) => {
    setTabNo(i);
    if (loadedTab.indexOf(i) === -1) setLoadedTab([...loadedTab, i]);
  };

  useEffect(() => {
    if (keepSelectionOnRefresh) {
      const loadTabNo = +hash.tab || 0;
      setTabNo(loadTabNo);
      setLoadedTab([...loadedTab, loadTabNo]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keepSelectionOnRefresh]);

  return (
    <div className="flex grow gap-4 h-full relative">
      <div
        className={classNames(
          "flex flex-col grow bg-white border",
          className,
          tabs[tabNo]?.tabInfoPanel ? "w-3/4" : "w-full"
        )}
      >
        <div className="border-b border-gray-200">
          <nav
            className="-mb-px flex space-x-8 px-6 overflow-x-auto"
            aria-label="Tabs"
          >
            {tabs.map((t: any, i) => (
              <button
                key={i}
                className={classNames(
                  "whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm",
                  {
                    "border-primary-1 text-primary-1": tabNo === i,
                    "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300":
                      tabNo !== i,
                  }
                )}
                onClick={() => {
                  handleSelectTab(i);
                  if (keepSelectionOnRefresh) replaceHash({ tab: i });
                }}
              >
                {t.title}
              </button>
            ))}
          </nav>
        </div>
        <div className="flex grow p-6 relative">
          {tabs.map((t, i) => {
            if (loadedTab.indexOf(i) === -1) return null;

            return tabNo === i ? (
              <div className="flex flex-col grow gap-4 max-w-full" key={i}>
                {t.panel}
              </div>
            ) : null;
          })}
        </div>
        {infoPanel && !isExpandInfoPanel && <ExpandInfoPanelButton />}
      </div>

      {infoPanel && isExpandInfoPanel && (
        <div className="flex w-1/4">{infoPanel}</div>
      )}
    </div>
  );
};

export default TabPanels;
