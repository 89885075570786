import { useMutation } from "@tanstack/react-query";

import { createWaterClass } from "@services/waterClass";

export const useCreateWaterClass = (options: any = {}) => {
  return useMutation<any, any, any>({
    mutationKey: ["createWaterClass"],
    mutationFn: createWaterClass,
    ...options,
  });
};
