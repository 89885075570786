import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Heading from "@components/layout/Heading";
import ConfirmModal from "@components/shared/ConfirmModal";
import WorkflowComplete from "@components/shared/WorkflowComplete";
import ConfirmationDetail from "@components/shared/ConfirmationDetail";
import { useUserContext } from "@context/UserContext";
import { toastError, toastSuccess } from "@utils/toast";
import { useEvidenceContext } from "@context/shared/EvidenceContext";
import { isValidationError } from "@utils/formError";

const ConfirmUser = () => {
  const { t } = useTranslation();
  const {
    stepHelpers,
    user,
    workflowInstance,
    setWorkflowInstance,
    getUserInfo,
    cancel,
    setIsComplete,
    isComplete,
    onSubmit,
  } = useUserContext();
  const { userId = "" } = useParams();
  const { getEvidencesInfo, uploadEvidences } = useEvidenceContext();
  const [showConfirmModal, setShowConfirmModal] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setShowConfirmModal(true);
  };

  const handleConfirm = async () => {
    let res: any;
    setLoading(true);
    try {
      const [data, workflowInstance] = await onSubmit({
        id: userId,
        ...user,
      });
      res = data;
      await uploadEvidences({
        description: t("user.evidence_data.description", { name: res.name }),
        referenceId: res.id,
        referenceTable: "users",
      });

      setWorkflowInstance(workflowInstance);
      setIsComplete(true);
      toastSuccess(t("user.toast.success"));
    } catch (error: any) {
      const toastMessage = t("user.toast.failed");
      if (isValidationError(error)) {
        const { errors = [] } = error?.response?.data;
        const messages = errors.map((i: any) => i.message);

        toastError(
          <>
            <p>{toastMessage}</p>
            {messages.length ? (
              <ul className="list-disc pl-4">
                {messages.map((text: any) => {
                  return <li key={text}>{text}</li>;
                })}
              </ul>
            ) : null}
          </>
        );
      } else {
        toastError(toastMessage);
      }
    }

    setShowConfirmModal(false);
    setLoading(false);
  };

  if (isComplete) {
    return <WorkflowComplete references={[workflowInstance?.id]} />;
  }

  return (
    <>
      <div className="flex flex-col gap-4 grow">
        <div className="p-6 space-y-4">
          <Heading light>{t("user.confirm_user")}</Heading>
          <ConfirmationDetail
            data={[getUserInfo(), getEvidencesInfo()]}
            onEdit={stepHelpers.setStep}
          />
        </div>

        <div className="grow" />

        <footer className="flex gap-4 p-6 border-t border-gray-200">
          <button
            type="button"
            className="btn-outline-primary"
            onClick={stepHelpers.goToPrevStep}
          >
            {t("common.prev_step")}
          </button>
          <button type="button" className="btn-primary" onClick={handleSubmit}>
            {t("common.ledger")}
          </button>
          <button
            type="button"
            className="btn-outline-primary"
            onClick={cancel}
          >
            {t("common.cancel")}
          </button>
        </footer>
      </div>

      <ConfirmModal
        open={showConfirmModal}
        onClose={() => {
          setShowConfirmModal(false);
        }}
        onConfirm={handleConfirm}
        isSubmitting={loading}
      >
        {t("user.modal.confirm")}
      </ConfirmModal>
    </>
  );
};

export default ConfirmUser;
