import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import Layout from "@components/layout/Layout";
import Table from "@components/layout/Table";
import Label from "@components/form/Label";
import SearchInput from "@components/form/SearchInput";
import SelectLevel1Resource from "@components/form/SelectLevel1Resource";
import Tag from "@components/shared/Tag";
import { useAllSubscribers } from "@hooks/query/useAllSubscribers";
import { useAppContext } from "@context/AppContext";

const SubscriberList: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { checkPermissions } = useAppContext();
  const { data: subscribers = [], isLoading } = useAllSubscribers();
  const [searchedValue, setSearchedValue] = useState("");
  const [selectedScheme, setSelectedScheme] = useState("");
  const [selectedStatus, setSelectedStatus] = useState<boolean>();

  const activeOptions = [
    { label: t("common.active"), value: true },
    { label: t("common.inactive"), value: false },
  ];

  const tableData = subscribers
    ?.filter(
      (row: { accountNumber: string; name: string }) =>
        !searchedValue.length ||
        row.name
          .toString()
          .toLowerCase()
          .includes(searchedValue.toString().toLowerCase()) ||
        row.accountNumber.toString().includes(searchedValue.toString())
    )
    ?.filter(
      (row: { isActive: boolean }) =>
        selectedStatus === undefined || row.isActive === selectedStatus
    )
    ?.filter(
      (row: { level1WRS: { name: string } }) =>
        !selectedScheme.length || row.level1WRS.name === selectedScheme
    )
    .map((subscriber: any) => ({
      ...subscriber,
      scheme: subscriber.level1WRS.name,
      isActive: (
        <Tag status={subscriber.isActive ? "success" : "error"}>
          {subscriber.isActive ? t("common.active") : t("common.inactive")}
        </Tag>
      ),
      actions: (
        <Select
          placeholder={t("common.actions")}
          options={[
            {
              label: t("common.view"),
              value: `/polestar/subscribers/${subscriber.id}`,
            },
            ...(checkPermissions(["UpdateSubscribers"])
              ? [
                  {
                    label: t("subscriber.edit.title"),
                    value: `/polestar/subscribers/${subscriber.id}/edit?subscriberId=${subscriber.id}`,
                  },
                ]
              : []),
            ...(checkPermissions(["CreateUsers"])
              ? [
                  {
                    label: t("user.create.title"),
                    value: `/polestar/subscribers/${subscriber.id}/users/create?subscriberId=${subscriber.id}`,
                  },
                ]
              : []),
          ]}
          onChange={(e) => {
            if (e?.value) {
              navigate(e.value);
            }
          }}
          menuPortalTarget={document.body}
          closeMenuOnScroll={() => true}
          isSearchable={false}
        />
      ),
    }));

  return (
    <Layout
      permissions={["ViewSubscribers"]}
      breadcrumb={[
        {
          label: t("dashboard.dashboard"),
          href: "/polestar",
        },
        {
          label: t("common.subscribers"),
          href: "/polestar/subscribers",
        },
      ]}
      title={t("common.subscribers")}
    >
      <div className="flex justify-between items-end relative z-20 mb-4">
        <form className="grid grid-cols-1 gap-2 md:grid-cols-3 md:gap-3 2xl:w-2/3">
          <div>
            <Label>{t("subscriber.filter.level1wrs")}</Label>
            <SelectLevel1Resource
              onChange={(e) => setSelectedScheme(e.label)}
              isClearable
            />
          </div>
          <div>
            <Label htmlFor="customer">{t("subscriber.filter_name_id")}</Label>
            <SearchInput
              id="customer"
              onChange={(e) => setSearchedValue(e.target.value)}
            />
          </div>
          <div>
            <Label>{t("subscriber.filter.status")}</Label>
            <Select
              options={activeOptions}
              value={activeOptions.find(
                (activeOption) => activeOption.value === selectedStatus
              )}
              onChange={(e) => {
                setSelectedStatus(e?.value);
              }}
              isClearable
            />
          </div>
        </form>
        {checkPermissions(["CreateSubscribers"]) ? (
          <Link
            to={`/polestar/subscribers/create`}
            className="btn-secondary text-sm rounded"
          >
            {t("user.create.new_customer")}
          </Link>
        ) : null}
      </div>
      <Table
        containerClassName="rounded-none md:rounded-none"
        tableHeaderClassName="relative z-10"
        fields={[
          {
            title: t("common.level1wrs"),
            name: "scheme",
          },
          {
            title: t("subscriber.account_number"),
            name: "accountNumber",
          },
          {
            title: t("subscriber.name"),
            name: "name",
          },
          {
            title: t("common.status"),
            name: "isActive",
          },
          {
            title: t("common.actions"),
            name: "actions",
          },
        ]}
        data={tableData}
        stickyHeader
        loading={isLoading}
      />
    </Layout>
  );
};

export default SubscriberList;
