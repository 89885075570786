import React from "react";
import ENV from "@config/env";
import classNames from "classnames";
import {
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
  getFilteredRowModel,
  getSortedRowModel,
  RowSelectionState,
  PaginationState,
} from "@tanstack/react-table";
import { pick, values } from "lodash";
import { useTranslation } from "react-i18next";
import { PaperClipIcon } from "@heroicons/react/20/solid";

import DebouncedInput from "@components/form/DebouncedInput";

import DataTable from "./DataTable";
import Pagination from "./Pagination";
import {
  ArrowLeftIcon,
  Cog6ToothIcon,
  InboxArrowDownIcon,
  PlusIcon,
  UserIcon,
  ArrowUturnLeftIcon,
} from "@heroicons/react/24/solid";
import Heading from "@components/layout/Heading";
import { Link, useSearchParams } from "react-router-dom";
import { useCacheRows } from "@hooks/useCacheRows";
import { useAppContext } from "@context/AppContext";

type NotificationViewDataTableRendererProps = {
  name: string;
  data: any[];
  columns: any[];
  pagination: PaginationState;
  onPaginationChange: any;
  pageCount: number;
  isLoading: boolean;
  isFetching: boolean;
  stickyHeader?: boolean;
  fetchAll?: any;
  columnsVisibility?: boolean;
  onClickMarkUnread: (id: string) => void;
};

const NotificationViewDataTableRenderer: React.FunctionComponent<
  NotificationViewDataTableRendererProps
> = ({
  data,
  columns,
  pageCount,
  pagination,
  onPaginationChange,
  isLoading,
  isFetching,
  stickyHeader = false,
  onClickMarkUnread,
}) => {
  const { t } = useTranslation();
  const [globalFilter, setGlobalFilter] = React.useState("");
  const [rowSelection, setRowSelection] = React.useState<RowSelectionState>({});
  const containerRef = React.useRef<HTMLDivElement>(null);
  const { checkPermissions, loggedInInfo } = useAppContext();

  const table = useReactTable({
    data,
    columns,
    state: {
      globalFilter,
      pagination,
      rowSelection,
    },
    pageCount,
    onPaginationChange,
    manualPagination: true,
    enableRowSelection: true,
    enableMultiRowSelection: false,
    getRowId: (originalRow) => originalRow.id,
    onGlobalFilterChange: setGlobalFilter,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  const [cacheRows] = useCacheRows(table, isFetching);
  const selectionRows = React.useMemo(
    () => values(getSelectionRowsModel(rowSelection, cacheRows)),
    [rowSelection, cacheRows]
  );
  const totalSelectionRows = selectionRows.length;

  const [searchParams] = useSearchParams();
  const searchNotificationId = searchParams.get("id");

  React.useEffect(() => {
    if (searchNotificationId) {
      setRowSelection({ [searchNotificationId]: true });
    }
  }, [searchNotificationId]);

  React.useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTo(0, 0);
    }
  }, [pagination.pageIndex]);

  return (
    <>
      <div className="flex items-center">
        <DebouncedInput
          type="search"
          value={globalFilter ?? ""}
          onChange={(value) => {
            setGlobalFilter(String(value));
          }}
          placeholder={t("shared.table.search_all") as string}
          className="flex-1"
        />

        <Pagination
          className="flex-1"
          table={table}
          isFetching={isFetching}
          totalSelectionRows={totalSelectionRows}
          showNumberOfSelectedRows={false}
        />

        {checkPermissions(["CreateNotifications"]) && (
          <Link to="/zenith/notifications/new" className="btn-secondary gap-2">
            <PlusIcon className="h-5 w-5" />
            {t("common.compose")}
          </Link>
        )}
      </div>

      <div className="flex flex-col flex-1 bg-white">
        <div
          ref={containerRef}
          className={classNames({
            "h-0 grow relative overflow-auto scroll-smooth grid grid-cols-3 gap-2":
              stickyHeader,
          })}
        >
          <div className="col-span-3 md:col-span-1  h-auto w-auto overflow-scroll border-b-2 md:border-border-2 md:border-b-0 border-r-0 md:border-r-2 border-r-gray-200 border-b-gray-200">
            <DataTable
              table={table}
              pagination={pagination}
              rowSelection={rowSelection}
              isLoading={isLoading}
              isFetching={isFetching}
              stickyHeader
              hideHeader
            />
          </div>
          {selectionRows.length ? (
            <div className="col-span-3 md:col-span-2 p-8 space-y-6 overflow-scroll">
              <div className="flex gap-6 items-center pb-3 border-b-2 border-b-gray-200">
                <button onClick={() => setRowSelection({})}>
                  <ArrowLeftIcon className="w-8 h-8" />
                </button>

                {selectionRows[0].relatedMessage?.sender?.id ===
                loggedInInfo?.userDetails?.id ? (
                  <>
                    <UserIcon className="w-12 h-12 text-gray-300 border border-gray-300 rounded-full bg-transparent" />
                    {t("common.to")}:
                    {
                      <div className="text-primary-3 text-lg font-bold">
                        {selectionRows[0].relatedMessage?.recipients.length > 1
                          ? `${selectionRows[0].relatedMessage?.recipients.length.toLocaleString()} ${t(
                              "notification.recipients"
                            )}`
                          : selectionRows[0].relatedMessage?.recipients
                              ?.map((r: any) => r.recipientWallet?.name)
                              .join(", ")}
                      </div>
                    }
                  </>
                ) : selectionRows[0].relatedMessage?.sender ? (
                  <>
                    <UserIcon className="w-12 h-12 text-gray-300 border border-gray-300 rounded-full bg-transparent" />
                    {t("common.from")}:
                    {
                      <div className="text-primary-3 text-lg font-bold">
                        {selectionRows[0].relatedMessage?.sender?.wallet?.name}
                        <span className="text-gray-500 text-xs pl-2">
                          {`<${selectionRows[0].relatedMessage?.sender?.email}>`}
                        </span>
                      </div>
                    }
                  </>
                ) : (
                  <>
                    <Cog6ToothIcon className="w-12 h-12 text-gray-300 border border-gray-300 rounded-full bg-transparent" />
                    {t("common.from")}:
                    <div className="text-primary-3 text-lg font-bold">
                      {t("audit_trail.system")}
                    </div>
                  </>
                )}

                {selectionRows[0].relatedMessage?.sender?.id !==
                  loggedInInfo?.userDetails?.id && (
                  <div className="ml-auto flex gap-2">
                    <Link
                      to={`/zenith/notifications/new?notificationId=${selectionRows[0].id}`}
                      className="text-sm btn-default flex gap-2"
                    >
                      <ArrowUturnLeftIcon className="w-5 h-5" />
                      {t("common.reply")}
                    </Link>
                    <button
                      className="text-sm text-primary-2 underline"
                      onClick={() => {
                        setRowSelection({});
                        onClickMarkUnread(selectionRows[0].id);
                      }}
                    >
                      {t("notification.mark_unread")}
                    </button>
                  </div>
                )}
              </div>
              <div className="space-y-4">
                <Heading>{selectionRows[0].relatedMessage?.subject}</Heading>

                {selectionRows[0].relatedMessage?.evidence?.id && (
                  <div className="border my-2 p-4 w-fit flex gap-4 rounded-sm justify-between items-center">
                    <PaperClipIcon className="w-5 h-5 text-gray-400" />
                    {selectionRows[0].relatedMessage?.evidence?.fileName}
                    <a
                      href={`${ENV.API_HOST}/evidences/${selectionRows[0].relatedMessage?.evidence?.id}/download`}
                      className="font-medium text-indigo-600 hover:text-indigo-500"
                      download
                    >
                      {t("common.download")}
                    </a>
                  </div>
                )}

                <div className="whitespace-pre-wrap">
                  {selectionRows[0].relatedMessage?.body}
                </div>
              </div>
            </div>
          ) : (
            <div className="col-span-3 md:col-span-2 grid place-content-center justify-items-center">
              <div className="grid justify-items-center">
                <InboxArrowDownIcon className="text-gray-400 h-40 w-40" />
                <p className="text-primary-2 text-lg">
                  {t("notification.select_to_view")}
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const getSelectionRowsModel = (rowSelection: RowSelectionState, rows: any) => {
  const selectionIds = Object.entries(rowSelection).map(([id]) => id);

  return pick(rows, selectionIds);
};

export default NotificationViewDataTableRenderer;
