import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import HandleGoBackOrClose from "@components/shared/HandleGoBackOrClose";
import Layout from "@components/layout/Layout";
import StepButton from "@components/shared/StepButton";
import StepInfo from "@components/shared/StepInfo";
import WorkflowComplete from "@components/shared/WorkflowComplete";
import WorkflowPanels from "@components/form/WorkflowPanels";
import InfoPanel from "@components/form/InfoPanel";
import UploadEvidencesForm from "@components/form/UploadEvidencesForm";
import ForwardDrawSelectLevel1WRS from "@components/form/admin_approvals/create_forward_draw/ForwardDrawSelectLevel1Resource";
import ForwardDrawSelectSubscribers from "@components/form/admin_approvals/create_forward_draw/ForwardDrawSelectSubscribers";
import ForwardDrawSelectExtractionRights from "@components/form/admin_approvals/create_forward_draw/ForwardDrawSelectExtractionRights";
import ForwardDrawEffectiveDate from "@components/form/admin_approvals/create_forward_draw/ForwardDrawEffectiveDate";
import ForwardDrawLedger from "@components/form/admin_approvals/create_forward_draw/ForwardDrawLedger";
import { StepProvider, useStepContext } from "@context/shared/StepContext";
import {
  EvidenceProvider,
  useEvidenceContext,
} from "@context/shared/EvidenceContext";
import { useAllSubscribers } from "@hooks/query/useAllSubscribers";
import { useAllExtractionRights } from "@hooks/query/useAllExtractionRights";
import { useAllLevel1Resources } from "@hooks/query/useAllLevel1Resources";
import { useAllAccountingPeriods } from "@hooks/query/useAllAccountingPeriods";
import { useManualApproveForwardDraw } from "@hooks/mutation/useManualApproveForwardDraw";
import { formatDate } from "@utils/formatDate";
import { extractionRightTypes } from "@services/extractionRight";
import { DBTables } from "@utils/constants/dbTables";

const CreateForwardDraw = () => {
  return (
    <StepProvider maxStep={6}>
      <EvidenceProvider>
        <Consumer />
      </EvidenceProvider>
    </StepProvider>
  );
};

const Consumer = () => {
  const { t } = useTranslation();
  const handleGoBackOrClose = HandleGoBackOrClose();
  const [searchParams] = useSearchParams();
  const level1ResourceId = searchParams.get("level1ResourceId") ?? "";
  const subscriberId = searchParams.get("subscriberId") ?? "";
  const { currentStep, stepHelpers } = useStepContext();
  const { getEvidencesInfo, uploadEvidences } = useEvidenceContext();
  const [selectedLevel1Resource, setSelectedLevel1Resource] = useState<any>();
  const [selectedSubscribers, setSelectedSubscribers] = useState<
    Record<string, any>[]
  >([]);
  const [selectedExtractionRights, setSelectedExtractionRights] = useState<
    Record<string, any>[]
  >([]);
  const [effectiveDate, setEffectiveDate] = useState<Date>(new Date());
  const [isWorkflowComplete, setIsWorkflowComplete] = useState(false);
  const [workflowInstance, setWorkflowInstance] = useState<any>();

  useAllLevel1Resources({
    enabled: Boolean(level1ResourceId) && !selectedLevel1Resource,
    onSuccess: (data: any[]) => {
      const level1Resource = data.find((l) => l.id === level1ResourceId);
      if (level1Resource) {
        setSelectedLevel1Resource(level1Resource);
        stepHelpers.setStep(1);
      }
    },
  });

  const { data: currentAccountingPeriod } = useAllAccountingPeriods({
    options: {
      enabled: Boolean(selectedLevel1Resource?.id),
      select: (data: any) => data?.[0],
      onSuccess: (accountingPeriod: any) => {
        if (accountingPeriod) {
          setEffectiveDate(new Date(accountingPeriod.periodStart));
        }
      },
    },
    params: { level1ResourceId: selectedLevel1Resource?.id, isActive: true },
  });

  const { data: subscribers = [] } = useAllSubscribers({
    params: { level1ResourceId: selectedLevel1Resource?.id },
    options: {
      enabled: Boolean(selectedLevel1Resource?.id),
      onSuccess: (data: any[]) => {
        const subscriber = data.find((s) => s.id === subscriberId);
        if (selectedSubscribers.length === 0 && subscriber) {
          setSelectedLevel1Resource(subscriber.level1WRS);
          setSelectedSubscribers([subscriber]);
          stepHelpers.setStep(2);
        }
      },
    },
  });

  const { data: extractionRights = [] } = useAllExtractionRights({
    params: {
      subscriberIds: selectedSubscribers.map((s) => s?.id),
      types: [extractionRightTypes.WA, extractionRightTypes.WSA],
      isActive: true,
    },
    options: {
      enabled: selectedSubscribers.length > 0,
    },
  });

  const { mutateAsync: manualApproveForwardDrawMutation } =
    useManualApproveForwardDraw();

  const handleConfirm = async () => {
    const [res, workflowInstance] = await manualApproveForwardDrawMutation({
      extractionRightIds: selectedExtractionRights.map((er: any) => er.id),
      effectiveDate: effectiveDate ?? new Date(),
    });
    const referenceId = res[0].id;
    await uploadEvidences(referenceId);
    await uploadEvidences({
      description: t("approval.forward_draws.evidence_description", {
        extractionRightNames: selectedExtractionRights
          .map((i: any) => i.name)
          .join(", "),
      }),
      referenceId,
      referenceTable: DBTables.AdministrativeApproval,
    });
    setIsWorkflowComplete(true);
    setWorkflowInstance(workflowInstance);
  };

  const handleCancel = handleGoBackOrClose;

  const info = {
    level1Resource: {
      title: t("approval.forward_draws.approve.selected_level1wrs"),
      body: [
        {
          key: t("common.level1wrs"),
          value: `${selectedLevel1Resource?.name} (${selectedLevel1Resource?.identifier})`,
        },
      ],
    },
    subscribers: {
      title: t("approval.forward_draws.approve.selected_subscribers"),
      body: [
        {
          key: t("common.subscriber"),
          value: `${selectedSubscribers?.[0]?.name} (${selectedSubscribers?.[0]?.accountNumber})`,
        },
      ],
    },
    extractionRights: {
      title: t("approval.forward_draws.approve.select_entitlements"),
      body: [
        {
          key: t("common.extraction_rights"),
          value: selectedExtractionRights.map((er: any) => er?.name).join(", "),
        },
      ],
    },
    effectiveDate: {
      title: t("common.effective_date"),
      body: [
        {
          key: t("common.effective_date"),
          value: effectiveDate ? formatDate(effectiveDate) : "",
        },
      ],
    },
  };

  return (
    <Layout
      permissions={["CreateApprovals"]}
      breadcrumb={[
        {
          label: t("dashboard.dashboard"),
          href: "/polestar",
        },
        {
          label: t("common.administrative_approvals"),
          href: `/polestar/administrative_approvals#tab=2`,
        },
        {
          label: t("approval.forward_draws.approve.title"),
        },
      ]}
      title={t("approval.forward_draws.approve.title")}
    >
      <WorkflowPanels
        selectedStep={currentStep}
        steps={[
          {
            label: (
              <StepButton
                index={0}
                currentStep={currentStep}
                info={<StepInfo data={info.level1Resource.body} />}
                isOnChain={isWorkflowComplete}
              >
                {t("approval.forward_draws.approve.select_level1wrs")}
              </StepButton>
            ),
            panel: (
              <ForwardDrawSelectLevel1WRS
                selectedLevel1Resource={selectedLevel1Resource}
                onSelect={(selected: any) => {
                  setSelectedLevel1Resource(selected);
                  setSelectedSubscribers([]);
                  setSelectedExtractionRights([]);
                }}
                onCancel={handleCancel}
              />
            ),
            infoPanel: (
              <InfoPanel>
                <div className="space-y-6">
                  <p>
                    {t("approval.forward_draws.approve.info.select_level1wrs")}
                  </p>
                </div>
              </InfoPanel>
            ),
          },
          {
            label: (
              <StepButton
                index={1}
                currentStep={currentStep}
                info={<StepInfo data={info.subscribers.body} />}
                isOnChain={isWorkflowComplete}
              >
                {t("approval.forward_draws.approve.select_subscribers")}
              </StepButton>
            ),
            panel: (
              <ForwardDrawSelectSubscribers
                subscribers={subscribers}
                selectedSubscribers={selectedSubscribers}
                onSelect={(selected: any) => {
                  setSelectedSubscribers(selected);
                  setSelectedExtractionRights([]);
                }}
                onCancel={handleCancel}
              />
            ),
            infoPanel: (
              <InfoPanel>
                <div className="space-y-6">
                  <p>
                    {t("approval.forward_draws.approve.info.select_subscriber")}
                  </p>
                </div>
              </InfoPanel>
            ),
          },
          {
            label: (
              <StepButton
                index={2}
                currentStep={currentStep}
                info={<StepInfo data={info.extractionRights.body} />}
                isOnChain={isWorkflowComplete}
              >
                {t("approval.forward_draws.approve.select_entitlements")}
              </StepButton>
            ),
            panel: (
              <ForwardDrawSelectExtractionRights
                extractionRights={extractionRights}
                selectedExtractionRights={selectedExtractionRights}
                onSelect={setSelectedExtractionRights}
                onCancel={handleCancel}
              />
            ),
            infoPanel: (
              <InfoPanel>
                <div className="space-y-6">
                  <p>
                    {t(
                      "approval.forward_draws.approve.info.select_extraction_right"
                    )}
                  </p>
                </div>
              </InfoPanel>
            ),
          },
          {
            label: (
              <StepButton
                index={3}
                currentStep={currentStep}
                info={<StepInfo data={info.effectiveDate.body} />}
                isOnChain={isWorkflowComplete}
              >
                {t("approval.forward_draws.approve.set_effective_date")}
              </StepButton>
            ),
            panel: (
              <ForwardDrawEffectiveDate
                onCancel={handleCancel}
                effectiveDate={effectiveDate}
                setEffectiveDate={setEffectiveDate}
                accountingPeriod={currentAccountingPeriod}
              />
            ),
            infoPanel: (
              <InfoPanel>
                <div className="space-y-6">
                  <p>
                    {t(
                      "approval.forward_draws.approve.info.set_effective_date"
                    )}
                  </p>
                </div>
              </InfoPanel>
            ),
          },
          {
            label: (
              <StepButton
                index={4}
                currentStep={currentStep}
                info={<StepInfo data={getEvidencesInfo().body} />}
                isOnChain={isWorkflowComplete}
              >
                {t("common.enter_evidence")}
              </StepButton>
            ),
            panel: (
              <UploadEvidencesForm
                onNext={stepHelpers.goToNextStep}
                onPrevious={stepHelpers.goToPrevStep}
                onCancel={handleCancel}
              />
            ),
            infoPanel: (
              <InfoPanel>
                <div className="space-y-6">
                  <p>{t("approval.forward_draws.approve.info.evidence")}</p>
                  <p>{t("evidence.supported")}</p>
                </div>
              </InfoPanel>
            ),
          },
          {
            label: (
              <StepButton
                index={5}
                currentStep={currentStep}
                isOnChain={isWorkflowComplete}
              >
                {t("approval.forward_draws.approve.review_application")}
              </StepButton>
            ),
            panel:
              isWorkflowComplete && workflowInstance ? (
                <WorkflowComplete references={[workflowInstance?.id]} />
              ) : (
                <ForwardDrawLedger
                  data={[
                    info.level1Resource,
                    info.subscribers,
                    info.extractionRights,
                    info.effectiveDate,
                    getEvidencesInfo(),
                  ]}
                  onConfirm={handleConfirm}
                  onCancel={handleCancel}
                />
              ),
            infoPanel: (
              <InfoPanel
                successes={[
                  isWorkflowComplete
                    ? t("approval.forward_draws.approve.info.success")
                    : t("approval.forward_draws.approve.info.confirm"),
                ]}
              >
                <div className="space-y-6">
                  {isWorkflowComplete ? (
                    <p>
                      {t("approval.forward_draws.approve.info.end_workflow")}
                    </p>
                  ) : (
                    <>
                      <p>{t("approval.forward_draws.approve.info.approved")}</p>
                      <p>{t("common.info.changes")}</p>
                    </>
                  )}
                </div>
              </InfoPanel>
            ),
          },
        ]}
      />
    </Layout>
  );
};

export default CreateForwardDraw;
