import React from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ConfirmDecommissionMeter from "./ConfirmDecommissionMeter";
import WorkflowPanels from "@components/form/WorkflowPanels";
import InfoPanel from "@components/form/InfoPanel";
import StepInfo from "@components/shared/StepInfo";
import DeclarationForm from "@components/form/declaration/DeclarationForm";
import UploadEvidencesWorkflowForm from "@components/form/UploadEvidenceWorkflowForm";
import ConfirmDeclaration from "@components/form/declaration/ConfirmDeclaration";
import StepButton from "@components/shared/StepButton";
import { StepProvider, useStepContext } from "@context/shared/StepContext";
import {
  DeclarationProvider,
  READING_TYPES,
  useDeclarationContext,
} from "@context/DeclarationContext";
import {
  EvidenceProvider,
  useEvidenceContext,
} from "@context/shared/EvidenceContext";
import { useDecommissionMeter } from "@hooks/mutation/useDecommissionMeter";
import { useCreateDeclaration } from "@hooks/mutation/useCreateDeclaration";
import { convertMLToLiter } from "@utils/convertUnits";

const DecommissionWorkFlow = () => {
  return (
    <StepProvider maxStep={5}>
      <DeclarationProvider>
        <EvidenceProvider>
          <Consumer />
        </EvidenceProvider>
      </DeclarationProvider>
    </StepProvider>
  );
};

const Consumer = () => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [isDecommissionComplete, setIsDecommissionComplete] =
    React.useState(false);
  const [decommissionWorkflowInstance, setDecommissionWorkflowInstance] =
    React.useState<any>();
  const { currentStep, stepHelpers } = useStepContext();
  const {
    declaration,
    getDeclarationInfo,
    isComplete: isDeclarationComplete,
    setIsComplete: setIsDeclarationComplete,
    workflowInstance: declarationWorkflowInstance,
    setWorkflowInstance: setDeclarationWorkflowInstance,
  } = useDeclarationContext();
  const {
    getEvidencesInfo,
    uploadEvidences,
    reset: resetEvidences,
  } = useEvidenceContext();
  const { mutateAsync: createDeclarationMutation } = useCreateDeclaration();
  const { mutateAsync: decommissionMutation } = useDecommissionMeter();

  const handleCancel = () =>
    !!searchParams.get("newWindow")
      ? window.close()
      : navigate(state?.from ?? "/polestar");

  const handleCreateFinalDeclaration = async () => {
    const [res, workflowInstance] = await createDeclarationMutation({
      reading: convertMLToLiter(+declaration.reading),
      readAt: new Date(declaration.readAt),
      isOpening: false,
      meterId: declaration.meter.serialNo,
      extractionPointName: declaration.extractionPoint.name,
      isEstimated: declaration.type === READING_TYPES.ESTIMATED,
      calculation: declaration.calculation,
    });
    await uploadEvidences({
      description: t("declaration.evidence_data.description", {
        serialNo: declaration.meter.serialNo,
        readAt: declaration.readAt,
        extractionPointName: declaration.extractionPoint.name,
      }),
      referenceId: res.id,
      referenceTable: "declarations",
    });

    setIsDeclarationComplete(true);
    stepHelpers.goToNextStep();
    resetEvidences();
    setDeclarationWorkflowInstance(workflowInstance);
  };

  const handleDecommissionMeter = async () => {
    const [res, workflowInstance] = await decommissionMutation({
      subscriberId: declaration.subscriber.id,
      extractionPointId: declaration.extractionPoint.id,
      meterId: declaration.meter.id,
    });
    await uploadEvidences({
      description: t("meter.decommission.evidence_description", {
        serialNo: res.serialNo,
      }),
      referenceId: res.id,
      referenceTable: "meters",
    });

    setDecommissionWorkflowInstance(workflowInstance);
    setIsDecommissionComplete(true);
  };

  const steps = [
    {
      label: (
        <StepButton
          index={0}
          currentStep={currentStep}
          info={<StepInfo data={getDeclarationInfo().body} />}
          isOnChain={isDeclarationComplete}
        >
          {t("meter.decommission.declare_final_read")}
        </StepButton>
      ),
      panel: <DeclarationForm variant="final" onCancel={handleCancel} />,
      infoPanel: (
        <InfoPanel
          actions={
            declaration.meter.id
              ? [
                  {
                    label: t("meter.edit.title"),
                    action: () => {
                      window.open(
                        `/polestar/meters/${declaration.meter.id}/edit`
                      );
                    },
                  },
                ]
              : []
          }
        >
          <div className="space-y-6">
            <p>{t("declaration.info.details")}</p>
            <p>{t("declaration.info.timestamp")}</p>
            <p>{t("declaration.info.is_estimated")}</p>
            <p>{t("declaration.info.enter_reading")}</p>
            <p>{t("declaration.info.usage")}</p>
          </div>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={1}
          currentStep={currentStep}
          info={<StepInfo data={getEvidencesInfo().body} />}
          isOnChain={isDeclarationComplete}
        >
          {t("common.enter_evidence")}
        </StepButton>
      ),
      panel: (
        <UploadEvidencesWorkflowForm
          onNext={stepHelpers.goToNextStep}
          onPrevious={stepHelpers.goToPrevStep}
          onCancel={handleCancel}
        />
      ),
      infoPanel: (
        <InfoPanel>
          <div className="space-y-6">
            <p>{t("declaration.info.evidence")}</p>
            <p>{t("evidence.supported")}</p>
          </div>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={2}
          currentStep={currentStep}
          isOnChain={isDeclarationComplete}
        >
          {t("declaration.form.confirm_final_read")}
        </StepButton>
      ),
      panel: (
        <ConfirmDeclaration
          title={t("declaration.form.confirm_final_read")}
          onCancel={handleCancel}
          onSubmit={handleCreateFinalDeclaration}
          workflowInstanceIds={[]}
        />
      ),
      infoPanel: (
        <InfoPanel successes={[t("declaration.info.confirm")]}>
          <ul className="space-y-4">
            <li>{t("common.info.verify")}</li>
            <li>
              <strong>{t("declaration.meter_read_details")}</strong>{" "}
              {t("declaration.info.verify.details")}
            </li>
            <li>
              <strong>{t("evidence.supporting")}:</strong>{" "}
              {t("evidence.info.verify")}
            </li>
            <li>{t("common.info.changes")}</li>
          </ul>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={3}
          currentStep={currentStep}
          info={<StepInfo data={getEvidencesInfo().body} />}
          isOnChain={isDecommissionComplete}
        >
          {t("meter.decommission.enter_evidence")}
        </StepButton>
      ),
      panel: (
        <UploadEvidencesWorkflowForm
          onNext={stepHelpers.goToNextStep}
          onPrevious={stepHelpers.goToPrevStep}
          onCancel={handleCancel}
        />
      ),
      infoPanel: (
        <InfoPanel>
          <div className="space-y-6">
            <p>{t("meter.decommission.info.evidence")}</p>
            <p>{t("evidence.supported")}</p>
          </div>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={4}
          currentStep={currentStep}
          isOnChain={isDecommissionComplete}
        >
          {t("meter.decommission.unlink_meter")}
        </StepButton>
      ),
      panel: (
        <ConfirmDecommissionMeter
          startAtIndex={3}
          onSubmit={handleDecommissionMeter}
          onCancel={handleCancel}
          isComplete={isDecommissionComplete}
          workflowInstanceIds={[
            declarationWorkflowInstance?.id,
            decommissionWorkflowInstance?.id,
          ]}
        />
      ),
      infoPanel: (
        <InfoPanel
          successes={
            isDecommissionComplete
              ? [
                  t("meter.decommission.info.success", {
                    extractionPointName: declaration.extractionPoint.name,
                    serialNo: declaration.meter.serialNo,
                  }),
                ]
              : [t("meter.decommission.info.confirm")]
          }
          actions={
            isDecommissionComplete
              ? [
                  {
                    label: t("extraction_point.create.link_to_meter"),
                    action: () => {
                      window.location.href = `/polestar/subscribers/${declaration.subscriber.id}/level0_resources/${declaration.extractionPoint.level0ResourceId}/extraction_points/link_meter?extractionPointId=${declaration.extractionPoint.id}&subscriberId=${declaration.subscriber.id}`;
                    },
                  },
                ]
              : []
          }
        >
          {isDecommissionComplete ? (
            t("meter.decommission.info.end_workflow", {
              extractionPointName: declaration.extractionPoint.name,
            })
          ) : (
            <ul className="space-y-4">
              <li>{t("common.info.verify")}</li>
              <li>
                <strong>{t("declaration.meter_read_details")}</strong>:{" "}
                {t("meter.decommission.info.verify.details")}
              </li>
              <li>
                <strong>{t("evidence.supporting")}:</strong>{" "}
                {t("evidence.info.verify")}
              </li>
              <li>{t("common.info.changes")}</li>
            </ul>
          )}
        </InfoPanel>
      ),
    },
  ];

  return <WorkflowPanels selectedStep={currentStep} steps={steps} />;
};

export default DecommissionWorkFlow;
