import ENV from "@config/env";
import { useParams } from "react-router-dom";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { noop } from "lodash";

import Layout from "@components/layout/Layout";
import WorkflowPanels from "@components/form/WorkflowPanels";
import TrialRun from "@components/form/meter/bulk_upload/TrialRun";
import UploadFile from "@components/form/meter/bulk_upload/UploadFile";
import InfoPanel from "@components/form/InfoPanel";
import SelectLevel1WRS from "@components/form/meter/bulk_upload/SelectLevel1WRS";
import StepButton from "@components/shared/StepButton";
import Loading from "@components/shared/Loading";
import StepInfo from "@components/shared/StepInfo";
import { useGetLevel1Resource } from "@hooks/query/useGetLevel1Resource";
import {
  BulkMeterUploadProvider,
  useBulkMeterUploadContext,
} from "@context/BulkMeterUploadContext";
import { StepProvider, useStepContext } from "@context/shared/StepContext";
import {
  EvidenceProvider,
  useEvidenceContext,
} from "@context/shared/EvidenceContext";
import InformationCircleIcon from "@components/icons/InformationCircleIcon";

const convertToCSV = (data: Record<string, any>[]) => {
  const keys = [
    ...(ENV.CLIENT_ID === "seqwater" ? ["group", "seq"] : []),
    "accountNumber",
    "extractionPointName",
    "serialNo",
    "lastMeterRead",
    "lastMeterReadDate",
    "reading",
    "readAt",
    "volumeExtracted",
    "status",
  ];
  const headers = [
    ...(ENV.CLIENT_ID === "seqwater"
      ? [t("bulk_meter_upload.table.group"), t("bulk_meter_upload.table.seq")]
      : []),
    t("bulk_meter_upload.table.account_number"),
    t("bulk_meter_upload.table.extraction_point_name"),
    t("bulk_meter_upload.table.meter_serial_no"),
    t("bulk_meter_upload.table.last_meter_read"),
    t("bulk_meter_upload.table.last_meter_read_date"),
    t("bulk_meter_upload.table.meter_read"),
    t("bulk_meter_upload.table.date"),
    t("bulk_meter_upload.table.volume_extracted"),
    t("bulk_meter_upload.table.status"),
  ];
  const rows = [];
  rows.push(headers.join(","));

  data.forEach((item) => {
    const values = keys.map((key) => {
      const value = item[key];
      return value !== undefined ? JSON.stringify(value) : "";
    });

    rows.push(values.join(","));
  });

  return rows.join("\n");
};

const exportCSV = (csvData: string, fileName: string) => {
  const blob = new Blob([csvData], { type: "text/csv" });
  const downloadUrl = URL.createObjectURL(blob);

  const link = document.createElement("a");
  link.href = downloadUrl;
  link.download = fileName + ".csv";
  link.style.display = "none";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(downloadUrl);
};

const BulkMeterUpload = () => {
  return (
    <StepProvider maxStep={3}>
      <EvidenceProvider
        accept={{
          "application/vnd.ms-excel": [".xls"],
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
            ".xlsx",
          ],
        }}
        multiple={false}
      >
        <BulkMeterUploadProvider>
          <Consumer />
        </BulkMeterUploadProvider>
      </EvidenceProvider>
    </StepProvider>
  );
};

const Consumer = () => {
  const { t } = useTranslation();
  const { id: level1ResourceId = "" } = useParams();
  const { data: level1Resource, isLoading } = useGetLevel1Resource(
    level1ResourceId,
    {
      enabled: Boolean(level1ResourceId),
    }
  );
  const { currentStep } = useStepContext();
  const { getEvidencesInfo, evidences: files = [] } = useEvidenceContext();
  const { level1WRS, isValidFile, getMergedData, isComplete } =
    useBulkMeterUploadContext();

  if (isLoading) {
    return (
      <div className="pt-80">
        <Loading />
      </div>
    );
  }

  const mergedData = getMergedData();

  const templateDownloadUrl = `${process.env.PUBLIC_URL}${
    ENV.CLIENT_ID === "seqwater" ? "/seqwater" : ""
  }/bulk_meter_reading_template.xlsx`;

  const steps = [
    {
      label: (
        <StepButton
          index={0}
          currentStep={currentStep}
          info={
            <p>
              <strong>{t("common.level1wrs")}: </strong>
              {level1WRS?.name}
            </p>
          }
        >
          {t("bulk_meter_upload.select_level1wrs")}
        </StepButton>
      ),
      panel: <SelectLevel1WRS />,
    },
    {
      label: (
        <StepButton
          index={1}
          currentStep={currentStep}
          info={<StepInfo data={getEvidencesInfo().body} />}
        >
          {t("bulk_meter_upload.select_file")}
        </StepButton>
      ),
      panel: <UploadFile />,
      infoPanel: (
        <InfoPanel
          errors={
            isValidFile
              ? []
              : [
                  t("bulk_meter_upload.info.error_invalid_format", {
                    fileName: files?.[0]?.name ?? "",
                  }),
                ]
          }
          actions={[
            {
              label: (
                <a href={templateDownloadUrl} download>
                  {t("bulk_meter_upload.download_template")}
                </a>
              ),
              action: noop,
            },
          ]}
        >
          <div className="space-y-4 leading-relaxed">
            <p>
              {t("bulk_meter_upload.info.level1WRS", {
                level1WRSName: level1WRS?.name,
              })}
            </p>
            <div>
              <p>{t("bulk_meter_upload.info.template_description")}</p>

              <ul className="pl-2 list-inside list-disc">
                {[
                  ...(ENV.CLIENT_ID === "seqwater" ? ["group", "seq"] : []),
                  "account_number",
                  "extraction_point_name",
                  "meter_serial_no",
                  "meter_read",
                  "date",
                ].map((value) => (
                  <li key={value}>
                    <strong>{t(`bulk_meter_upload.table.${value}`)}:</strong>{" "}
                    {t(`bulk_meter_upload.info.template_columns.${value}`)}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton index={2} currentStep={currentStep}>
          {t("bulk_meter_upload.title.trial_run")}
        </StepButton>
      ),
      panel: <TrialRun />,
      infoPanel: (
        <InfoPanel
          actions={
            mergedData?.length
              ? [
                  {
                    label: t("bulk_meter_upload.download_trial_run"),
                    action: () => {
                      const csv = convertToCSV(mergedData);
                      const fileName = `Trial_run_results_${Date.now()}`;
                      exportCSV(csv, fileName);
                    },
                  },
                ]
              : []
          }
          successes={isComplete ? [t("bulk_meter_upload.info.complete")] : []}
        >
          <div className="space-y-6">
            {isComplete ? (
              t("user.info.end_workflow")
            ) : (
              <>
                <p>{t("bulk_meter_upload.info.trial_run")}</p>
                <div className="space-y-2">
                  <p>{t("bulk_meter_upload.info.status")}</p>
                  <ul className="pl-2 list-inside list-disc space-y-2">
                    <li>
                      <strong>
                        {t("bulk_meter_upload.table.mismatch")} -{" "}
                      </strong>
                      {t("bulk_meter_upload.info.any_of")}:
                      <ul className="pl-3 list-inside list-disc">
                        {ENV.CLIENT_ID === "seqwater" ? (
                          <>
                            <li>
                              {t("bulk_meter_upload.info.mismatch.sequence_no")}
                            </li>
                            <li>
                              {t("bulk_meter_upload.info.mismatch.group_name")}
                              <span className="gap-1 flex">
                                <InformationCircleIcon className="h-4 w-4 item-start text-blue-600 shrink-0" />
                                {t(
                                  "bulk_meter_upload.info.mismatch.mismatch_group_seq_warn"
                                )}
                              </span>
                            </li>
                          </>
                        ) : (
                          <></>
                        )}
                        <li>
                          {t("bulk_meter_upload.info.mismatch.account_no")}
                        </li>
                        <li>
                          {t("bulk_meter_upload.info.mismatch.serial_no")}
                        </li>
                      </ul>
                    </li>
                    <li>
                      <strong>{t("common.duplicate")} - </strong>
                      {t("bulk_meter_upload.info.duplicate")}
                    </li>
                    <li>
                      <strong>{t("common.warning")} - </strong>
                      {t("bulk_meter_upload.info.warning")}
                    </li>
                    <li>
                      <strong>
                        {t("bulk_meter_upload.table.extraction_point")} -{" "}
                      </strong>
                      {t("bulk_meter_upload.info.extraction_point_not_found")}
                    </li>
                    <li>
                      <strong>
                        {t("bulk_meter_upload.table.extraction_right")} -{" "}
                      </strong>
                      {t("bulk_meter_upload.info.no_extraction_right")}
                    </li>
                  </ul>
                </div>
                <p>{t("bulk_meter_upload.info.proceed")}</p>
              </>
            )}
          </div>
        </InfoPanel>
      ),
    },
  ];

  return (
    <Layout
      permissions={["BulkUploadMeterReadings"]}
      breadcrumb={[
        {
          label: t("common.home"),
          href: "/polestar",
        },
        {
          label: level1Resource?.name,
          href: `/polestar/level1wrs/${level1ResourceId}`,
        },
        {
          label: t("level1wrs.extraction_points"),
          href: `/polestar/level1wrs/${level1ResourceId}#5`,
        },
        {
          label: t("bulk_meter_upload.title.upload"),
        },
      ]}
      title={t("bulk_meter_upload.title.upload")}
    >
      <WorkflowPanels selectedStep={currentStep} steps={steps} />
    </Layout>
  );
};

export default BulkMeterUpload;
