import React from "react";
import { useTranslation } from "react-i18next";
import Heading from "@components/layout/Heading";
import ConfirmModal from "@components/shared/ConfirmModal";
import WorkflowComplete from "@components/shared/WorkflowComplete";
import ConfirmationDetail from "@components/shared/ConfirmationDetail";
import { useEvidenceContext } from "@context/shared/EvidenceContext";
import { useStepContext } from "@context/shared/StepContext";
import { useSubscriberCreateOrEditContext } from "@context/SubscriberCreateOrEditContext";
import { useCreateUser } from "@hooks/mutation/useCreateUser";
import { toastError, toastSuccess } from "@utils/toast";

const ConfirmPrimaryUser = () => {
  const { t } = useTranslation();
  const { stepHelpers } = useStepContext();
  const {
    subscriber,
    user,
    getUserInfo,
    cancel,
    isUserComplete,
    setIsUserComplete,
    workflowInstance,
  } = useSubscriberCreateOrEditContext();
  const { getEvidencesInfo, uploadEvidences, isSubmitting } =
    useEvidenceContext();
  const [showConfirmModal, setShowConfirmModal] = React.useState(false);
  const [primaryUserWorkflowInstance, setPrimaryUserWorkflowInstance] =
    React.useState<any>();

  const { mutateAsync: createUserMutation, isLoading: isCreateUserLoading } =
    useCreateUser();

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setShowConfirmModal(true);
  };

  const handleConfirm = async () => {
    try {
      const [res, workflowInstance] = await createUserMutation({
        ...user,
        subscriberId: subscriber.id,
        isPrimary: true,
      });
      await uploadEvidences({
        description: t("user.evidence_data.description", { name: res.name }),
        referenceId: res.id,
        referenceTable: "users",
      });
      setShowConfirmModal(false);
      setIsUserComplete(true);
      setPrimaryUserWorkflowInstance(workflowInstance);
      toastSuccess(t("user.toast.success"));
    } catch (error: any) {
      const toastText = t("user.toast.failed");
      if (error?.response?.status === 422) {
        const { errors } = error.response.data;
        const messages = errors.map((i: any) => i.message);
        toastError(
          <>
            <p>{toastText}</p>
            <ul className="list-disc pl-4">
              {messages.map((text: any) => {
                return <li key={text}>{text}</li>;
              })}
            </ul>
          </>
        );
      } else {
        toastError(toastText);
      }
    }
  };

  if (isUserComplete) {
    return (
      <WorkflowComplete
        references={[workflowInstance?.id, primaryUserWorkflowInstance?.id]}
      />
    );
  }

  return (
    <>
      <div className="flex flex-col gap-4 grow">
        <div className="p-6 space-y-4">
          <Heading light>{t("user.confirm_primary_user")}</Heading>
          <ConfirmationDetail
            data={[getUserInfo(), getEvidencesInfo()]}
            onEdit={(index: number) => stepHelpers.setStep(index + 3)}
          />
        </div>

        <div className="grow" />

        <footer className="flex gap-4 p-6 border-t border-gray-200">
          <button
            type="button"
            className="btn-outline-primary"
            onClick={stepHelpers.goToPrevStep}
          >
            {t("common.prev_step")}
          </button>
          <button type="button" className="btn-primary" onClick={handleSubmit}>
            {t("common.ledger")}
          </button>
          <button
            type="button"
            className="btn-outline-primary"
            onClick={cancel}
          >
            {t("common.cancel")}
          </button>
        </footer>
      </div>

      <ConfirmModal
        open={showConfirmModal}
        onClose={() => {
          setShowConfirmModal(false);
        }}
        onConfirm={handleConfirm}
        isSubmitting={isCreateUserLoading || isSubmitting}
      >
        {t("user.create_primary_user.confirm_modal")}
      </ConfirmModal>
    </>
  );
};

export default ConfirmPrimaryUser;
