import ENV from "@config/env";
import React from "react";
import { useParams } from "react-router-dom";
import { t } from "i18next";
import { useTranslation } from "react-i18next";

import Table from "@components/layout/Table";
import Heading from "@components/layout/Heading";
import Tag from "@components/shared/Tag";
import ConfirmModal from "@components/shared/ConfirmModal";
import TrialRunDetailsModal from "./TrialRunDetailsModal";
import WorkflowComplete from "@components/shared/WorkflowComplete";
import { useBulkMeterUploadContext } from "@context/BulkMeterUploadContext";
import { bulkMeterUpload, trialRunBulkMeters } from "@services/bulkMeterUpload";
import { useStepContext } from "@context/shared/StepContext";
import { toastError, toastSuccess } from "@utils/toast";
import { useEvidenceContext } from "@context/shared/EvidenceContext";
import { convertMLToLiter } from "@utils/convertUnits";
import { READING_CALCULATION } from "@services/declarations";

const transformStatus = (status: string) => {
  const mapper: Record<string, any> = {
    mismatch: t("bulk_meter_upload.table.mismatch"),
    allow_mismatch_group_sequence: t("bulk_meter_upload.table.mismatch"),
    duplicate: t("common.duplicate"),
    warning: t("common.warning"),
    success: t("common.success"),
    no_extraction_right: t("bulk_meter_upload.table.extraction_right"),
    extraction_point_not_found: t("bulk_meter_upload.table.extraction_point"),
    click_over: t("bulk_meter_upload.table.click_over"),
    backward: t("bulk_meter_upload.table.back_read"),
  };

  return mapper[status] ?? "";
};

const TrialRun: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const { id: level1ResourceId = "" } = useParams();
  const [submitting, setSubmitting] = React.useState(false);
  const [showConfirmModal, setShowConfirmModal] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [showDetailsModal, setShowDetailsModal] = React.useState<any>();

  const { stepHelpers } = useStepContext();
  const { evidences: files, uploadEvidences } = useEvidenceContext();
  const {
    level1WRS,
    meterReadings,
    getMergedData,
    trialRunResults,
    setTrialRunResults,
    handleCancel,
    isComplete,
    setIsComplete,
    workflowInstance,
    setWorkflowInstance,
    handleStatusChange,
  } = useBulkMeterUploadContext();

  React.useEffect(() => {
    const run = async () => {
      setIsLoading(true);
      const payload = meterReadings.map((i) => {
        return {
          ...i,
          reading: i.reading ? convertMLToLiter(+i.reading) : 0,
        };
      });
      const res = await trialRunBulkMeters(payload);
      setTrialRunResults(res);
      setIsLoading(false);
    };
    run();
  }, [meterReadings, setTrialRunResults]);

  const handleConfirm = async () => {
    setSubmitting(true);
    try {
      const bulkMeters = trialRunResults
        .filter((i) =>
          [
            "success",
            "allow_mismatch_group_sequence",
            READING_CALCULATION.BACKWARD,
            READING_CALCULATION.CLICK_OVER,
          ].includes(i.status)
        )
        .map((i) => ({
          ...i,
          reading: i.reading ?? 0,
          calculation: i.status,
        }));

      const [data, workflowInstance] = await bulkMeterUpload({
        fileName: files?.[0].name,
        level1ResourceId,
        bulkMeters,
      });
      await uploadEvidences({
        description: t("bulk_meter_upload.upload_description", {
          level1WRSName: level1WRS?.name,
        }),
        referenceId: data?.id,
        referenceTable: "meter_uploads",
      });
      setWorkflowInstance(workflowInstance);
      setIsComplete(true);
      toastSuccess(t("bulk_meter_upload.message.success"));
    } catch (error: any) {
      toastError(t("bulk_meter_upload.message.error"));
    }
    setShowConfirmModal(false);
    setSubmitting(false);
  };

  const tableFields = [
    ...(ENV.CLIENT_ID === "seqwater"
      ? [
          {
            title: t("bulk_meter_upload.table.group"),
            name: "group",
          },
          {
            title: t("bulk_meter_upload.table.seq"),
            name: "seq",
          },
        ]
      : []),
    {
      title: t("bulk_meter_upload.table.account_number"),
      name: "accountNumber",
    },
    {
      title: t("bulk_meter_upload.table.extraction_point_name"),
      name: "extractionPointName",
    },
    {
      title: t("bulk_meter_upload.table.meter_serial_no"),
      name: "serialNo",
    },
    {
      title: t("bulk_meter_upload.table.meter_read"),
      name: "reading",
    },
    {
      title: t("bulk_meter_upload.table.date"),
      name: "readAt",
    },
    {
      title: t("bulk_meter_upload.table.volume_extracted"),
      name: "volumeExtracted",
    },
    {
      title: t("bulk_meter_upload.table.status"),
      name: "status",
    },
    {
      title: "",
      name: "view",
    },
  ];

  const tableData = getMergedData().map((row) => {
    const errorClassName = "bg-red-100";
    const warningClassName = "bg-yellow-100";
    const isDuplicate = row.status === "duplicate";

    return {
      ...row,
      status: (
        <div className="flex gap-2">
          <Tag
            status={
              [
                "success",
                READING_CALCULATION.BACKWARD,
                READING_CALCULATION.CLICK_OVER,
              ].includes(row.status)
                ? "success"
                : "warning"
            }
          >
            {transformStatus(row.status)}
          </Tag>
        </div>
      ),
      view: (
        <button
          className="btn-primary text-sm rounded"
          onClick={() => setShowDetailsModal(row)}
        >
          {t("common.view")}
        </button>
      ),
      cellClassName: {
        volumeExtracted: "bg-gray-100",
        accountNumber: row.mismatch.accountNumber ? errorClassName : "",
        extractionPointName: row.mismatch.extractionPointName
          ? errorClassName
          : "",
        serialNo: row.mismatch.serialNo ? errorClassName : "",
        reading: isDuplicate ? errorClassName : "",
        readAt: isDuplicate ? errorClassName : "",
        group: row.mismatch.group ? warningClassName : "",
        seq: row.mismatch.seq ? warningClassName : "",
      },
    };
  });

  if (isComplete) {
    return <WorkflowComplete references={[workflowInstance?.id]} />;
  }

  const selectedDetails = {
    file: meterReadings[showDetailsModal?.row],
    ledger: trialRunResults[showDetailsModal?.row],
  };

  return (
    <>
      <div className="flex flex-col grow gap-6 p-6">
        <Heading>{t("bulk_meter_upload.title.trial_run")}</Heading>

        <Table
          fields={tableFields}
          data={tableData}
          loading={isLoading}
          stickyHeader
        />
      </div>

      <footer className="flex gap-4 border-t border-gray-300 p-6">
        <button
          type="button"
          className="btn-outline-primary"
          onClick={stepHelpers.goToPrevStep}
        >
          {t("common.prev_step")}
        </button>
        <button
          className="btn-primary"
          onClick={() => setShowConfirmModal(true)}
        >
          {t("common.ledger")}
        </button>
        <button className="btn-outline-primary" onClick={handleCancel}>
          {t("common.cancel")}
        </button>
      </footer>

      <ConfirmModal
        open={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
        onConfirm={handleConfirm}
        isSubmitting={submitting}
      >
        {t("bulk_meter_upload.confirm_message")}
      </ConfirmModal>

      {selectedDetails.file && selectedDetails.ledger ? (
        <TrialRunDetailsModal
          onClose={() => setShowDetailsModal(undefined)}
          onClickBackRead={() => {
            handleStatusChange(
              selectedDetails.ledger,
              READING_CALCULATION.BACKWARD
            );
            setShowDetailsModal(undefined);
          }}
          onClickClickOver={() => {
            handleStatusChange(
              selectedDetails.ledger,
              READING_CALCULATION.CLICK_OVER
            );
            setShowDetailsModal(undefined);
          }}
          status={transformStatus(selectedDetails.ledger?.status)}
          data={selectedDetails}
        />
      ) : null}
    </>
  );
};

export default TrialRun;
