import { READING_CALCULATION } from "@services/declarations";
import { convertLiterToML } from "@utils/convertUnits";
import { formatDate } from "@utils/formatDate";
import React from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

type MeterReading = Record<string, any>;

type ReadingStatus =
  (typeof READING_CALCULATION)[keyof typeof READING_CALCULATION];

type ContextValue = {
  level1WRS: any;
  setLevel1WRS: React.Dispatch<React.SetStateAction<any>>;
  meterReadings: MeterReading[];
  setMeterReadings: React.Dispatch<React.SetStateAction<MeterReading[]>>;
  trialRunResults: MeterReading[];
  setTrialRunResults: React.Dispatch<React.SetStateAction<MeterReading[]>>;
  isValidFile: boolean;
  setIsValidFile: React.Dispatch<React.SetStateAction<boolean>>;
  handleCancel: () => void;
  reset: () => void;
  getMergedData: () => Record<string, any>[];
  isComplete: boolean;
  setIsComplete: React.Dispatch<React.SetStateAction<boolean>>;
  workflowInstance: any;
  setWorkflowInstance: React.Dispatch<React.SetStateAction<any>>;
  handleStatusChange: (item: any, status: ReadingStatus) => void;
};

type Props = {
  children: React.ReactNode;
};

const BulkMeterUploadContext = React.createContext<ContextValue | undefined>(
  undefined
);

const BulkMeterUploadProvider = ({ children }: Props) => {
  const { id: level1ResourceId } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [isValidFile, setIsValidFile] = React.useState(true);
  const [level1WRS, setLevel1WRS] = React.useState<any>();
  const [isComplete, setIsComplete] = React.useState(false);
  const [meterReadings, setMeterReadings] = React.useState<MeterReading[]>([]);
  const [trialRunResults, setTrialRunResults] = React.useState<MeterReading[]>(
    []
  );
  const [workflowInstance, setWorkflowInstance] = React.useState<any>();

  const handleStatusChange = (
    itemToUpdate: MeterReading,
    newStatus: ReadingStatus
  ) => {
    setTrialRunResults((prevResults) => {
      return prevResults.map((item) => {
        if (item === itemToUpdate) {
          switch (newStatus) {
            case READING_CALCULATION.BACKWARD:
              return { ...item, status: newStatus, volumeExtracted: "0.000" };
            case READING_CALCULATION.CLICK_OVER:
              return {
                ...item,
                status: newStatus,
                volumeExtracted:
                  item.clickOver - item.lastReading + item.reading,
              };
            default:
              return item;
          }
        }
        return item;
      });
    });
  };

  const handleCancel = () =>
    !!searchParams.get("newWindow")
      ? window.close()
      : navigate(`/polestar/level1wrs/${level1ResourceId}#5`);

  const reset = React.useCallback(() => {
    setIsValidFile(true);
    setMeterReadings([]);
  }, []);

  const getMergedData = () => {
    if (!meterReadings?.length || !trialRunResults?.length) {
      return [];
    }

    return meterReadings.map((i, index) => {
      const {
        status = "",
        volumeExtracted = 0,
        accountNumber,
        extractionPointName,
        serialNo,
        group = "",
        seq,
        row,
        lastReading,
      } = trialRunResults[index];

      return {
        ...i,
        status,
        volumeExtracted: convertLiterToML(+volumeExtracted),
        row,
        readAt: i.readAt ? formatDate(i.readAt) : "",
        mismatch: {
          accountNumber: i.accountNumber !== accountNumber,
          extractionPointName: i.extractionPointName !== extractionPointName,
          serialNo: !i.serialNo && !serialNo ? false : i.serialNo !== serialNo,
          group:
            i.group?.trim()?.toLowerCase() !== group?.trim()?.toLowerCase(),
          seq: !i.seq && !seq ? false : i.seq !== seq,
        },
        lastMeterRead: lastReading ? convertLiterToML(lastReading) : "-",
        lastMeterReadDate: i.readAt ? formatDate(new Date(i.readAt)) : "-",
      };
    });
  };

  const values: ContextValue = {
    level1WRS,
    setLevel1WRS,
    meterReadings,
    setMeterReadings,
    handleCancel,
    trialRunResults,
    setTrialRunResults,
    isValidFile,
    setIsValidFile,
    reset,
    getMergedData,
    isComplete,
    setIsComplete,
    workflowInstance,
    setWorkflowInstance,
    handleStatusChange,
  };

  return (
    <BulkMeterUploadContext.Provider value={values}>
      {children}
    </BulkMeterUploadContext.Provider>
  );
};

const useBulkMeterUploadContext = () => {
  const context = React.useContext(BulkMeterUploadContext);
  if (context === undefined) {
    throw new Error(
      "useBulkMeterUploadContext must be used within a BulkUploadProvider"
    );
  }
  return context;
};

export { BulkMeterUploadProvider, useBulkMeterUploadContext };
