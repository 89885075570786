import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { getMeterById } from "@services/meter";

export function useMeter(id: string, options?: UseQueryOptions) {
  return useQuery<any>({
    queryKey: ["meters", id],
    queryFn: () => getMeterById(id),
    enabled: Boolean(id),
    ...options,
  });
}
