import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import WorkflowPanels from "@components/form/WorkflowPanels";
import InfoPanel from "@components/form/InfoPanel";
import UploadEvidencesWorkflowForm from "@components/form/UploadEvidenceWorkflowForm";
import StepButton from "@components/shared/StepButton";
import StepInfo from "@components/shared/StepInfo";
import ConfirmDeclaration from "./ConfirmDeclaration";
import DeclarationForm, { DECLARATION_VARIANTS } from "./DeclarationForm";
import {
  DeclarationProvider,
  useDeclarationContext,
} from "@context/DeclarationContext";
import {
  EvidenceProvider,
  useEvidenceContext,
} from "@context/shared/EvidenceContext";
import { StepProvider, useStepContext } from "@context/shared/StepContext";
import { useCreateDeclaration } from "@hooks/mutation/useCreateDeclaration";
import { useCreateUnmeteredUsage } from "@hooks/mutation/useCreateUnmeteredUsage";
import { convertMLToLiter } from "@utils/convertUnits";
import { useCreateInitialDeclaration } from "@hooks/mutation/useCreateInitialDeclaration";

const DeclarationWorkflow = () => {
  return (
    <StepProvider maxStep={3}>
      <DeclarationProvider>
        <EvidenceProvider>
          <Consumer />
        </EvidenceProvider>
      </DeclarationProvider>
    </StepProvider>
  );
};

const Consumer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { state } = useLocation();
  const { currentStep, stepHelpers } = useStepContext();
  const { getEvidencesInfo, uploadEvidences } = useEvidenceContext();
  const {
    declaration,
    getDeclarationInfo,
    isComplete,
    setIsComplete,
    isUnmetered,
    isInitialRead,
    isFinalRead,
    isEstimated,
    workflowInstance,
    setWorkflowInstance,
  } = useDeclarationContext();

  const handleCancel = () =>
    !!searchParams.get("newWindow")
      ? window.close()
      : navigate(state?.from ?? "/polestar");

  const { mutateAsync: createDeclarationMutation } = useCreateDeclaration();
  const { mutateAsync: createUnmeteredUsageMutation } =
    useCreateUnmeteredUsage();
  const { mutateAsync: createInitialDeclarationMutation } =
    useCreateInitialDeclaration();

  const newReading = declaration.reading
    ? convertMLToLiter(+declaration?.reading)
    : 0;
  const callAPI = {
    unmetered: () =>
      createUnmeteredUsageMutation({
        subscriberId: declaration.subscriber.id,
        extractionPointId: declaration.extractionPoint.id,
        volume: newReading,
        readAt: new Date(declaration.readAt),
      }),
    initial: () =>
      createInitialDeclarationMutation({
        subscriberId: declaration.subscriber.id,
        extractionPointName: declaration.extractionPoint.name,
        serialNo: declaration.meter.serialNo,
        reading: newReading,
        readAt: new Date(declaration.readAt),
        isEstimated,
      }),
    declaration: () =>
      createDeclarationMutation({
        extractionPointName: declaration.extractionPoint.name,
        meterId: declaration.meter.serialNo,
        reading: newReading,
        readAt: new Date(declaration.readAt),
        isOpening: false,
        calculation: declaration.calculation,
        isEstimated,
      }),
  };

  const handleConfirm = async () => {
    const key = isUnmetered
      ? "unmetered"
      : isInitialRead
      ? "initial"
      : "declaration";
    const [res, workflowInstance] = await callAPI[key]();

    await uploadEvidences({
      description: t("declaration.evidence_data.description", {
        serialNo: declaration.meter.serialNo,
        readAt: declaration.readAt,
        extractionPointName: declaration.extractionPoint.name,
      }),
      referenceId: res.id,
      referenceTable: "declarations",
    });

    setWorkflowInstance(workflowInstance);
    setIsComplete(true);
  };

  const steps = [
    {
      label: (
        <StepButton
          index={0}
          currentStep={currentStep}
          info={<StepInfo data={getDeclarationInfo().body} />}
          isOnChain={isComplete}
        >
          {t("declaration.declare_meter_reading")}
        </StepButton>
      ),
      panel: (
        <DeclarationForm
          variant={
            isInitialRead
              ? DECLARATION_VARIANTS.initial
              : isFinalRead
              ? DECLARATION_VARIANTS.final
              : undefined
          }
          onCancel={handleCancel}
        />
      ),
      infoPanel: (
        <InfoPanel
          actions={
            declaration.meter.id
              ? [
                  {
                    label: t("meter.edit.title"),
                    action: () => {
                      window.open(
                        `/polestar/meters/${declaration.meter.id}/edit`
                      );
                    },
                  },
                ]
              : []
          }
        >
          <div className="space-y-6">
            {isUnmetered ? (
              <>
                <p>
                  {t("declaration.unmetered_usage.info.unmetered_declaration")}
                </p>
                <p>
                  {t("declaration.unmetered_usage.info.enter_estimated_volume")}
                </p>
              </>
            ) : (
              <>
                <p>{t("declaration.info.details")}</p>
                <p>{t("declaration.info.timestamp")}</p>
                <p>{t("declaration.info.is_estimated")}</p>
                <p>{t("declaration.info.enter_reading")}</p>
                <p>
                  {isInitialRead
                    ? t("declaration.info.initial_reading")
                    : t("declaration.info.usage")}
                </p>
              </>
            )}
          </div>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton
          index={1}
          currentStep={currentStep}
          info={<StepInfo data={getEvidencesInfo().body} />}
          isOnChain={isComplete}
        >
          {t("common.enter_evidence")}
        </StepButton>
      ),
      panel: (
        <UploadEvidencesWorkflowForm
          onNext={stepHelpers.goToNextStep}
          onPrevious={stepHelpers.goToPrevStep}
          onCancel={handleCancel}
        />
      ),
      infoPanel: (
        <InfoPanel>
          <div className="space-y-6">
            <p>{t("declaration.info.evidence")}</p>
            <p>{t("evidence.supported")}</p>
          </div>
        </InfoPanel>
      ),
    },
    {
      label: (
        <StepButton index={2} currentStep={currentStep} isOnChain={isComplete}>
          {t("declaration.form.confirm_read")}
        </StepButton>
      ),
      panel: (
        <ConfirmDeclaration
          workflowInstanceIds={[workflowInstance?.id]}
          variant={
            isInitialRead
              ? DECLARATION_VARIANTS.initial
              : isFinalRead
              ? DECLARATION_VARIANTS.final
              : undefined
          }
          onCancel={handleCancel}
          onSubmit={handleConfirm}
        />
      ),
      infoPanel: (
        <InfoPanel
          successes={
            isComplete
              ? isInitialRead
                ? [
                    t("declaration.info.initial_read_success", {
                      serialNo: declaration.meter.serialNo,
                      reading: declaration.reading,
                    }),
                  ]
                : [
                    t("declaration.info.success", {
                      serialNo: declaration.meter.serialNo,
                      reading: declaration.reading,
                    }),
                  ]
              : [t("declaration.info.confirm")]
          }
          actions={
            isComplete && isUnmetered
              ? [
                  {
                    label: t("extraction_point.link_meter.title"),
                    action: () => {
                      navigate(
                        `/polestar/subscribers/${declaration.subscriber.id}/level0_resources/${declaration.extractionPoint.level0ResourceId}/extraction_points/link_meter?extractionPointId=${declaration.extractionPoint.id}`
                      );
                    },
                  },
                ]
              : []
          }
        >
          {isComplete ? (
            t("user.info.end_workflow")
          ) : (
            <ul className="space-y-4">
              <li>{t("common.info.verify")}</li>
              <li>
                <strong>{t("declaration.meter_read_details")}</strong>{" "}
                {t("declaration.info.verify.details")}
              </li>
              <li>
                <strong>{t("evidence.supporting")}:</strong>{" "}
                {t("evidence.info.verify")}
              </li>
              <li>{t("common.info.changes")}</li>
            </ul>
          )}
        </InfoPanel>
      ),
    },
  ];

  return <WorkflowPanels selectedStep={currentStep} steps={steps} />;
};

export default DeclarationWorkflow;
