import React from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import { isValidPhoneNumber } from "react-phone-number-input";
import { t as translate } from "i18next";
import HandleGoBackOrClose from "@components/shared/HandleGoBackOrClose";
import { EvidenceProvider } from "@context/shared/EvidenceContext";
import { useStep, UseStepHelpers } from "@hooks/useStep";
import { useSubscriber } from "@hooks/query/useSubscriber";
import { useUser } from "@hooks/query/useUser";
import type { ConfirmData } from "@components/shared/ConfirmationDetail";
import { getAllUsers } from "@services/user";

export const schema = z
  .object({
    subscriberId: z
      .string()
      .min(1, translate("common.required.text") as string),
    name: z.string().min(1, translate("common.required.text") as string),
    isPrimary: z.coerce.boolean(),
    email: z
      .string()
      .min(1, translate("common.required.text") as string)
      .email(),
    tel: z
      .string()
      .min(1, translate("common.required.text") as string)
      .refine(isValidPhoneNumber, {
        message: translate("subscriber.create.invalid_phone_number") as string,
      }),
    originalEmail: z.string().email().optional(),
  })
  .refine(
    async (values) => {
      if (values.originalEmail === values.email) {
        return true;
      }
      const res = await getAllUsers({
        email: values.email,
      });
      return res.length === 0;
    },
    {
      message: translate("user.validation.email_exists") as string,
      path: ["email"],
    }
  );

export type User = z.infer<typeof schema>;

export const initialValues: User = {
  subscriberId: "",
  name: "",
  isPrimary: false,
  email: "",
  tel: "",
  originalEmail: "",
};

type ContextValue = {
  currentStep: number;
  stepHelpers: UseStepHelpers;
  user: User;
  setUser: React.Dispatch<React.SetStateAction<User>>;
  isComplete: boolean;
  setIsComplete: React.Dispatch<boolean>;
  workflowInstance: any;
  setWorkflowInstance: React.Dispatch<any>;
  getUserInfo: () => ConfirmData;
  cancel: () => void;
  onSubmit: any;
  level1ResourceId?: string;
  userData?: any;
  subscriber: any;
  isPrimary: boolean;
  setIsPrimary: React.Dispatch<boolean>;
};

export type UserProviderProps = {
  children: React.ReactNode;
  onSubmit: any;
  level1ResourceId?: string;
  subscriberId?: string;
};

const Context = React.createContext<ContextValue | undefined>(undefined);

function UserProvider({
  children,
  onSubmit,
  level1ResourceId,
  subscriberId,
}: UserProviderProps) {
  const { t } = useTranslation();
  const { userId = "" } = useParams();
  const handleGoBackOrClose = HandleGoBackOrClose();
  const [currentStep, stepHelpers] = useStep(3);
  const [user, setUser] = React.useState<User>(() => ({
    ...initialValues,
    subscriberId: subscriberId ?? "",
  }));
  const [isComplete, setIsComplete] = React.useState(false);
  const [isPrimary, setIsPrimary] = React.useState(false);
  const [workflowInstance, setWorkflowInstance] = React.useState<any>();

  useUser(userId, {
    onSuccess: (data: any) => {
      setUser({
        name: data.name ?? "",
        email: data.email ?? "",
        tel: data.tel ?? "",
        isPrimary: data.isPrimary ?? false,
        subscriberId: data.subscriberId ?? "",
        originalEmail: data.email,
      });
      setIsPrimary(data.isPrimary);
    },
    refetchOnWindowFocus: false,
    keepPreviousData: false,
  });
  const { data: subscriber } = useSubscriber(user?.subscriberId);

  const cancel = handleGoBackOrClose;

  const getUserInfo = () => {
    return {
      title: t("user.details"),
      body: [
        {
          key: t("subscriber.name"),
          value: subscriber?.name,
        },
        {
          key: t("user.username"),
          value: user?.name,
        },
        {
          key: t("user.email"),
          value: user?.email,
        },
        {
          key: t("user.tel"),
          value: user?.tel,
        },
      ],
    };
  };

  const value = {
    currentStep,
    stepHelpers,
    user,
    setUser,
    isComplete,
    setIsComplete,
    workflowInstance,
    setWorkflowInstance,
    cancel,
    getUserInfo,
    onSubmit,
    subscriber,
    level1ResourceId,
    isPrimary,
    setIsPrimary,
  };

  return (
    <EvidenceProvider>
      <Context.Provider value={value}>{children}</Context.Provider>
    </EvidenceProvider>
  );
}

function useUserContext() {
  const context = React.useContext(Context);
  if (context === undefined) {
    throw new Error("useUserContext must be used within a UserProvider");
  }
  return context;
}

export { UserProvider, useUserContext };
