import { useEffect, useMemo } from "react";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { t } from "i18next";
import { useTranslation } from "react-i18next";

import Heading from "@components/layout/Heading";
import TextInput from "@components/form/TextInput";
import Label from "@components/form/Label";
import SelectLevel0Resource from "@components/form/SelectLevel0Resource";
import { useWaterClassContext } from "@context/WaterClassContext";
import SelectClassCategory from "@components/form/SelectClassCategory";

const schema = z.object({
  name: z
    .string()
    .trim()
    .min(1, t("common.required.text") as string),
  level0ResourceId: z.string().min(1, t("common.required.text") as string),
  classCategoryId: z.string().min(1, t("common.required.text") as string),
});

const WaterClassDetails = () => {
  const { t } = useTranslation();
  const {
    stepHelpers,
    level1Resource,
    details,
    setDetails,
    setClassCategoryName,
    updatingWaterClass,
    navigateForCancel,
  } = useWaterClassContext();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({
    resolver: zodResolver(schema),
    defaultValues: useMemo(() => {
      return details;
    }, [details]),
  });

  useEffect(() => {
    reset(details);
  }, [details, reset]);
  const isUpdating = Boolean(updatingWaterClass);

  return (
    <div className="flex flex-col grow p-6">
      <Heading light className="mb-8">
        {t("water_class.details")}
      </Heading>

      <form
        className="flex flex-col grow space-y-8"
        onSubmit={handleSubmit((data) => {
          setDetails((prevState: any) => ({ ...prevState, ...data }));
          stepHelpers.goToNextStep();
        })}
      >
        <fieldset className="space-y-4">
          <div className="max-w-md">
            <Label htmlFor="name">{t("water_class.name")}</Label>
            <TextInput
              {...register("name")}
              id="name"
              errorMessage={errors?.name?.message as string}
            />
          </div>

          <div className="max-w-md">
            <Label>{t("water_class.select_level0_resource")}</Label>
            <Controller
              name="level0ResourceId"
              control={control}
              render={({ field: { onChange, value, name }, fieldState }) => (
                <SelectLevel0Resource
                  level1ResourceId={level1Resource?.id}
                  name={name}
                  onChange={({ value }) => {
                    onChange(value);
                  }}
                  value={value}
                  isDisabled={isUpdating}
                  errorMessage={fieldState.error?.message}
                />
              )}
            />
          </div>
          <div className="max-w-md">
            <Label>{t("water_class.select_class_category")}</Label>
            <Controller
              name="classCategoryId"
              control={control}
              render={({ field: { onChange, value, name }, fieldState }) => (
                <SelectClassCategory
                  name={name}
                  onChange={({ value, label }) => {
                    onChange(value);
                    setClassCategoryName(label);
                  }}
                  value={value}
                  errorMessage={fieldState.error?.message}
                />
              )}
            />
          </div>
        </fieldset>

        <div className="grow" />

        <footer className="flex gap-4 -mx-6 mt-6 p-6 pb-0 border-t border-gray-200">
          <button type="submit" className="btn-primary">
            {t("common.next_step")}
          </button>
          <button
            type="button"
            className="btn-outline-primary"
            onClick={navigateForCancel}
          >
            {t("common.cancel")}
          </button>
        </footer>
      </form>
    </div>
  );
};

export default WaterClassDetails;
